import { MarginsWrapper, Spacer } from '@agent-zone/ui-library/esm/Margins';
import { Typo } from '@agent-zone/ui-library/esm/Typo';
import React, { useLayoutEffect, useRef } from 'react';
import styled from 'styled-components';
import { DateFormat } from '../../../../../../formats';
import { colors } from '../../../../../../ui/Colors/colors';
import { Fader } from '../../../../../../ui/Fader/Fader';
import { ContentWrapper } from '../../../../../../ui/LayoutElements/LayoutElements.styled';
import { dateFormat } from '../../../../../utils/utils';
import { useProposalsList } from '../../../ProposalsList/hooks/useProposalsList';
import { SingleProposal } from '../../../ProposalsList/SingleProposal';
import { SuccessMark } from './components/SuccessMark';

const Span = styled.span`
  color: ${colors.goldDrop};
  font-weight: 600;
`;

interface Props {
  mainClient: K2Paperless.MainClientWithAccept;
  isSingleProposal?: boolean;
  isDemo?: boolean;
  setDemoType: (_: 'PS' | 'NBU') => void;
}

export const Success = ({
  mainClient, isSingleProposal, isDemo, setDemoType,
}: Props): JSX.Element => {
  const { proposalsList, countProposalsInProgress } = useProposalsList();
  const proposalSent = proposalsList && proposalsList.filter((proposal) => proposal.status === 'CONSENT_SENT');
  const proposalInProgress = proposalsList && proposalsList.filter((proposal) => proposal.status === 'IN_PROGRESS');
  const contentAnchorRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (contentAnchorRef && contentAnchorRef.current) {
      contentAnchorRef.current.scrollIntoView({ block: 'end' });
    }
  }, []);

  if (isSingleProposal) {
    return (
      <Fader>
        <ContentWrapper ref={contentAnchorRef}>
          <Spacer size="LXL" />
          <Typo $type="H1" $color="orangeMedium">
            Wszystko gotowe!
          </Typo>
          <Typo $type="body">
            Dziękujemy za poświęcony czas. Twój wniosek trafił do dalszej obsługi.
            <br />
            Niebawem poinformujemy Cię o kolejnych krokach.
          </Typo>
          <SuccessMark />
        </ContentWrapper>
      </Fader>
    );
  }

  if (countProposalsInProgress && countProposalsInProgress >= 1) {
    return (
      <Fader>
        <ContentWrapper ref={contentAnchorRef}>
          <Spacer size="LXL" />
          <Typo $type="H1" $color="orangeMedium">
            Wszystko gotowe!
          </Typo>
          {mainClient.processType === 'PS'
            ? (
              <Typo $type="body">
                Twój wniosek z dnia
                {' '}
                <Span>{dateFormat(mainClient.signDate)}</Span>
                {' '}
                dotyczący zmian w ubezpieczeniu na życie
                {' '}
                <Span>{mainClient.contractDescription}</Span>
                {' '}
                o numerze
                {' '}
                <Span>{mainClient.contractNumber}</Span>
                ,
                {' '}
                trafił do dalszej obsługi. Niebawem poinformujemy Cię o kolejnych krokach.
              </Typo>
            ) : (
              <Typo $type="body">
                Twój wniosek o numerze
                {' '}
                <Span>{mainClient.contractNumber}</Span>
                {' '}
                z dnia
                {' '}
                <Span><DateFormat>{mainClient.signDate}</DateFormat></Span>
                {' '}
                o zawarcie umowy ubezpieczeniowej
                {' '}
                <Span>{mainClient.contractDescription}</Span>
                ,
                {' '}
                trafił do dalszej obsługi. Niebawem poinformujemy Cię o kolejnych krokach.
              </Typo>
            )}
          <SuccessMark />
          {proposalInProgress && (
            <>
              <Spacer size="LXL" />
              <Typo $type="H3">
                Wnioski, w których czekamy na Twoje zgody
              </Typo>
              {proposalInProgress
                .map((proposal) => (
                  <SingleProposal
                    setDemoType={isDemo ? (): void => setDemoType(proposal.processType) : undefined}
                    key={proposal.id}
                    proposal={proposal}
                  />
                ))}
            </>
          )}
          {(proposalSent && proposalSent.length > 0) && (
            <>
              <MarginsWrapper marginTop="S">
                <Typo $type="H3">
                  Wnioski, którymi już się zajmujemy
                </Typo>
              </MarginsWrapper>
              {proposalSent
                .map((proposal) => (
                  <SingleProposal
                    key={proposal.id}
                    proposal={proposal}
                  />
                ))}
            </>
          )}
        </ContentWrapper>
      </Fader>
    );
  }

  return (
    <Fader>
      <ContentWrapper ref={contentAnchorRef}>
        <Spacer size="LXL" />
        <Typo $type="H1" $color="orangeMedium">
          Wszystko gotowe!
        </Typo>
        <Typo $type="body">
          Dziękujemy za poświęcony czas. Twoje wszystkie wnioski trafiły do dalszej obsługi.
          <br />
          Niebawem poinformujemy Cię o kolejnych krokach.
        </Typo>
        <SuccessMark />
      </ContentWrapper>
    </Fader>
  );
};
