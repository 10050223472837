import React from 'react';
import { Typo } from '@agent-zone/ui-library/esm/Typo';
import { ErrorMessageWrapper } from './ErrorMessageWrapper';

export const AllConsentsSent = (): JSX.Element => (
  <ErrorMessageWrapper
    linkToNNSiteText="Przejdź do nn.pl"
  >
    <Typo $type="H1">
      Wszystkie
      {' '}
      <span>oświadczenia</span>
      {' '}
      <span>zostały wysłane</span>
    </Typo>
    <Typo $type="body">
      Witamy ponownie. Cieszymy się z Twojego powrotu, ale nie mamy dla Ciebie nowych zadań.
    </Typo>
    <Typo $type="body">
      W przypadku pytań skontaktuj się ze swoim przedstawicielem.
    </Typo>
  </ErrorMessageWrapper>
);
