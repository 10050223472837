import React from 'react';
import styled, { css, CSSProp } from 'styled-components';
import { Grid } from '@agent-zone/ui-library/esm/Grid';
import { Typo } from '@agent-zone/ui-library/esm/Typo';
import { usePinAuth } from './hooks/usePinAuth';
import { InputForm } from '../../../../../InputForm/InputForm';
import { MOBILE } from '../../../../../../breakpoints';

interface Props {
  onFinish: (pinNumber: (string | undefined)[],
    socialSecurityNumberIndexes: number[],
    showCaptcha: boolean,
    captchaKey: string | null,
  ) => void;
  onEnter?: () => void;
  isFloating?: boolean;
}

const StyledInputFormWrapper = styled(Grid)<{ isFloating?: boolean }>`
  flex-direction: column;
  ${({ isFloating }): CSSProp => (isFloating
    ? css`
      div:nth-of-type(3) {
        input {
          margin-right: 48px;
          @media screen and (max-width: ${MOBILE}px) {
            margin-right: 32px;
          }
        }
      }
    `
    : css`
      input {
        margin-bottom: 12px;
      }
      div:nth-of-type(3) {
        input {
          margin-right: 48px;
          @media screen and (max-width: ${MOBILE}px) {
            margin-right: 24px;
          }
          @media screen and (max-width: 374px) {
            margin-right: 4px;
          }
        }
      }
    `)}
`;

const AdditionalInfoWraper = styled(Grid)`
  text-align: left;
  width: 100%;
`;

const pinNumberConfig = Array(6).fill(true);

export const PINForm = ({ onFinish, isFloating }: Props): JSX.Element => {
  const {
    processing,
    submit,
    setPinNumber,
  } = usePinAuth(onFinish);

  return (
    <>
      <StyledInputFormWrapper isFloating={isFloating} center align>
        <InputForm
          name="pin"
          config={pinNumberConfig}
          setInputArray={setPinNumber}
          visit={processing}
          onClick={submit}
          processing={processing}
          secure
        >
          <AdditionalInfoWraper>
            <Typo noMargin $type="body">
              W kolejnym kroku poprosimy o uzupełnienie Twojego numeru PESEL.
            </Typo>
          </AdditionalInfoWraper>
        </InputForm>
      </StyledInputFormWrapper>
    </>
  );
};
