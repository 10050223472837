import React, { useContext, Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { Checkbox } from '@agent-zone/ui-library/esm/Checkbox';
import { Typo } from '@agent-zone/ui-library/esm/Typo';
import { Elevation } from '@agent-zone/ui-library/esm/Elevation';
import { Grid } from '@agent-zone/ui-library/esm/Grid';
import { Pictogram } from '@agent-zone/ui-library/esm/Pictogram';
import { Button, StyledButton } from '../../../../../../../ui/Button/Button';
import { Paragraph, HeaderTextH2 } from '../../../../../../../ui/LayoutElements/LayoutElements.styled';
import { Fader } from '../../../../../../../ui/Fader/Fader';
import { ErrorContext } from '../../../../../../Errors/ErrorsContextProvider';
import { ErrorBox } from '../../../../../../Errors/ErrorBox';
import { Dialog } from '../../../../../../../ui/Dialog/Dialog';
import { IdCamera } from './IdCamera/IdCamera';
import { DESKTOP, media } from '../../../../../../../breakpoints';
import { PhotoSection } from './PhotoSection/PhotoSection';
import { useInstantValidation, acceptExtensions } from '../hooks/useInstantValidation';
import { FileWithCanvas, usePhoto } from '../hooks/usePhoto';
import { HiddenFileInput } from './PhotoSection/components/PhotoButtons';

const DOCUMENT_NAME = {
  DO: 'dowodu osobistego',
  PP: 'paszportu',
  KP: 'karty pobytu',
  OTHER: undefined,
};

interface Props {
  documentType: K2Paperless.DocumentType | 'OTHER';
  skipUpload: boolean;
  setSkipUpload?: (newSkipUplaod: boolean) => void;
  uploads: FileWithCanvas[];
  setUploads: Dispatch<SetStateAction<FileWithCanvas[]>>;
  submitting: boolean;
  requiredPhotoCount: 1 | 2;
  isAdditionalPhoto?: boolean;
  documentsByOtherChannelAvailable?: boolean;
}

const ButtonOK = styled(StyledButton)`
  margin-top: 10px !important;
  transform: unset !important;
  min-height: 50px;
  position: relative;
  margin-left: auto;
  margin-right: auto;

  @media ${media.tablet} {
    margin-top: 0 !important;
  }
`;

const DialogTitle = styled(HeaderTextH2)`
  text-align: center;
`;

const DialogParagraph = styled(Paragraph)`
  text-align: center;
`;

const StyledCheckbox = styled(Checkbox)`
  top: 1px;
`;
const ButtonsModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;

  @media ${media.tablet} {
    flex-direction: row;
  }
`;

interface PhotoSectionWrapperProps {
  skipUpload: boolean;
}
const PhotoSectionWrapper = styled.div<PhotoSectionWrapperProps>`
  margin-bottom: 50px;
`;

const CheckboxWrapper = styled.div`
    display: inline-grid;
`;

const DialogButton = styled(Button)`
  margin: 0 auto;
`;

export const Photo = ({
  documentType,
  skipUpload,
  setSkipUpload,
  uploads,
  setUploads,
  submitting,
  requiredPhotoCount,
  isAdditionalPhoto,
  documentsByOtherChannelAvailable,
}: Props): JSX.Element => {
  const {
    isCaptureAvailable,
    onCleanUpload,
    isComplete,
    onSelectFromFile,
    onTryToStartCapture,
    onSetCapturesToUploads,
    isWebcamAccessGranted,
    isStarted,
    stopCapture,
    pictureLoader,
    formRef,
    setCaptureError,
    captureError,
    startedIndex,
  } = usePhoto(requiredPhotoCount, skipUpload, uploads, setUploads);

  const { cleanError } = useContext(ErrorContext);

  const {
    instantValidation,
    onAddPhotoFromFileEvent,
  } = useInstantValidation(onSelectFromFile);

  if (isStarted) {
    return (
      <IdCamera
        documentType={documentType}
        requiredPhotoCount={requiredPhotoCount}
        alreadyCapturesCount={uploads.filter((up) => 'originalFile' in up).length}
        startedIndex={startedIndex}
        onStopCapture={stopCapture}
        onCapturesReady={
          (captures): void => {
            onSetCapturesToUploads(captures);
            stopCapture();
          }
        }
        onCaptureError={(): void => {
          setCaptureError(true);
          stopCapture();
        }}
      />
    );
  }

  const isDesktop = window.innerWidth > DESKTOP;

  const isAnyPhotoAdded = uploads.some((u): boolean => (u ? !!u.originalFile : false));
  const uploadCount = uploads.filter((u): boolean => (u ? !!u.originalFile : false)).length;

  return (
    <form ref={formRef}>
      <Fader>
        <PhotoSectionWrapper skipUpload={skipUpload}>
          {uploads.map((upload, index) => (
            <React.Fragment key={index}>
              <PhotoSection
                index={index}
                scrollInto={uploadCount === 1 && index === 1}
                isCaptureAvailable={isCaptureAvailable}
                onTryToStartCapture={(): void => onTryToStartCapture(index)}
                onAddPhotoFromFileEvent={(event): void => onAddPhotoFromFileEvent(event, index)}
                onEraseUpload={(): void => onCleanUpload(index)}
                upload={upload}
                loading={pictureLoader === index}
                skipUpload={skipUpload}
                disabled={submitting}
              />
            </React.Fragment>
          ))}
        </PhotoSectionWrapper>
        {
          (isCaptureAvailable) && isWebcamAccessGranted === false && (
            <Dialog>
              <DialogTitle color="goldDrop" fontWeight={500}>
                Aby zrobić zdjęcie dokumentu zezwól na dostęp do Twojego aparatu.
              </DialogTitle>
              <DialogParagraph>
                Gdy Twoja przeglądarka poprosi o dostęp do aparatu
                dla strony oswiadczenia.nn.pl kliknij w przycisk &quot;Zezwól&quot;.
              </DialogParagraph>
              <DialogButton
                text="OK"
                onClick={(): void => onTryToStartCapture(startedIndex)}
                type="button"
              />
            </Dialog>
          )
        }
        {
          (isCaptureAvailable && captureError) && (
            <Dialog onClose={(): void => setCaptureError(false)}>
              <DialogTitle color="goldDrop" fontWeight={500}>
                Nie wyrażono zgody na dostęp do Twojego aparatu.
              </DialogTitle>
              {!isDesktop && (
                <DialogParagraph>
                  Jeśli nadal chcesz zrobić zdjęcie dokumentu wybierz &quot;OK&quot;.
                  Zostaniesz przekierowany do zainstalowanej aplikacji w
                  Twoim urządzeniu obsługującej aparat.
                </DialogParagraph>
              )}
              {
                isDesktop && (
                  <DialogParagraph>
                    Dodaj skan lub zdjęcie dokumentu wybierając przycisk:
                    &quot;Dodaj zdjęcie z pliku&quot; i wybierz odpowiedni plik na dysku.
                  </DialogParagraph>
                )
              }
              <ButtonsModalWrapper>
                {isDesktop && (
                  <DialogButton
                    type="button"
                    text="OK"
                    onClick={(): void => setCaptureError(false)}
                  />
                )}
                {!isDesktop && (
                  <ButtonOK type="button">
                    <Typo $type="body" $color="white">OK</Typo>
                    <HiddenFileInput
                      disabled={isComplete || skipUpload}
                      type="file"
                      capture
                      accept={`image/${acceptExtensions.replace(/\.(\w+)/g, 'image/$1')}`}
                      onChange={(event): void => {
                        onAddPhotoFromFileEvent(event, startedIndex);
                        setCaptureError(false);
                      }}
                    />
                  </ButtonOK>
                )}
              </ButtonsModalWrapper>
            </Dialog>
          )
        }
        {instantValidation && <ErrorBox />}
        {isAnyPhotoAdded && (
          <Elevation marginBottom="LXL" paddingsHorizontal="M" paddingsVertical="M" color="snowWhite">
            <Grid>
              <Grid flex={0} paddingRight="M">
                <Pictogram icon="infoRound" />
              </Grid>
              <Grid align block>
                <Typo noMargin $type="body">
                  Jeśli zdjęcia lub skany dokumentu zostały przekazane Przedstawicielowi w
                  innej formie to usuń wszystkie załączone pliki i
                  zaznacz poniższą opcję aby kontynuować.
                </Typo>
              </Grid>
            </Grid>
          </Elevation>
        )}
        {(documentsByOtherChannelAvailable && setSkipUpload) && (
          <CheckboxWrapper>
            <StyledCheckbox
              value={skipUpload}
              onChange={(value: boolean): void => { setSkipUpload(value); cleanError(); }}
              disabled={isAnyPhotoAdded}
              label={(
                <Typo $type="body">
                  Kontynuuję bez dodawania zdjęć lub skanów
                  {' '}
                  {DOCUMENT_NAME[documentType]}
                  {' '}
                  {isAdditionalPhoto ? 'oraz dodatkowego dokumentu potwierdzającego tożsamość ' : ''}
                  ponieważ zostały przekazane Przedstawicielowi w innej formie.
                </Typo>
              )}
            />
          </CheckboxWrapper>
        )}
        {!instantValidation && <ErrorBox />}
      </Fader>
    </form>
  );
};
