import React from 'react';
import { Typo } from '@agent-zone/ui-library/esm/Typo';
import { ErrorMessageWrapper } from './ErrorMessageWrapper';

export const NoProposalsAvailable = (): JSX.Element => (
  <ErrorMessageWrapper
    linkToNNSiteText="Przejdź do nn.pl"
  >
    <Typo $type="H1" $color="orangeMedium">
      Brak
      {' '}
      <span>dostępnych</span>
      {' '}
      <span>wniosków</span>
    </Typo>
    <Typo $type="body">
      Witamy, niestety nie udało się odnaleźć
      odpowiedniego wniosku o zawarcie umowy ubezpieczeniowej.
    </Typo>
  </ErrorMessageWrapper>
);
