import React from 'react';
import { ReactComponent as LightLoader } from '../../img/sun-loader-light.svg';
import { ReactComponent as DarkLoader } from '../../img/sun-loader-dark.svg';


interface Props {
  variant?: 'light' | 'dark';
}
export const SunLoader = ({ variant = 'light' }: Props): JSX.Element => (
  variant === 'dark'
    ? (
      <DarkLoader
        height="34px"
        width="34px"
      />
    )
    : (
      <LightLoader
        height="34px"
        width="34px"
      />
    )
);
