import { Typo } from '@agent-zone/ui-library/esm/Typo';
import React from 'react';
import { ErrorMessageWrapper } from './ErrorMessageWrapper';

export function PinExpired(): JSX.Element {
  return (
    <ErrorMessageWrapper>
      <Typo $type="body" $color="orangeMedium">
        Twój
        {' '}
        <span>PIN</span>
        {' '}
        <span>stracił ważność</span>
      </Typo>
      <Typo $type="body">
        Niestety ale dane wprowadzane w dwóch poprzednich krokach nie są poprawne.
      </Typo>
      <Typo $type="body">
        Wróć do strony &quot;Twój kod PIN&quot; i zacznij od początku.
      </Typo>
    </ErrorMessageWrapper>
  );
}
