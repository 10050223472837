import {
  useEffect,
  useCallback,
  useState,
  useContext,
  useRef,
  RefObject,
  Dispatch,
  SetStateAction,
} from 'react';
import { ErrorContext } from '../../../../../../Errors/ErrorsContextProvider';

function getCanvas(image: CanvasImageSource, width: number, height: number): HTMLCanvasElement {
  const canvas = document.createElement('canvas');
  const scale = 400 / width;
  canvas.width = width * scale;
  canvas.height = height * scale;
  const context = canvas.getContext('2d');
  if (context) {
    context.drawImage(image, 0, 0, width * scale, height * scale);
  }

  return canvas;
}

function isFileImage(file: File): boolean {
  return !!file.name.toLowerCase().match(/(jpg|jpeg|jpe|png|bpm|heic)$/);
}

export interface FileWithCanvas {
  originalFile?: File;
  canvas?: HTMLCanvasElement;
}

async function fileToFileWithCanvas(file: File): Promise<FileWithCanvas> {
  return new Promise((resolve) => {
    if (isFileImage(file)) { // image file
      const reader = new FileReader();
      reader.readAsDataURL(file); // TODO as array buffer?

      reader.addEventListener('loadend', (evt) => {
        const image = new Image();

        if (evt.target && typeof evt.target.result === 'string') {
          image.src = evt.target.result;
        }

        image.onload = (): void => {
          resolve({
            originalFile: file,
            canvas: getCanvas(image, image.width, image.height),
          });
        };
      });
    } else { // other file
      resolve({ originalFile: file });
    }
  });
}

async function canvasToBlob(canvas: HTMLCanvasElement): Promise<Blob> {
  return new Promise((resolve) => canvas.toBlob((blob) => resolve(blob as Blob), 'image/jpeg'));
}

interface Hook {
  isCaptureAvailable: boolean;
  captureError: boolean;
  uploads: FileWithCanvas[];
  onCleanUpload: (index: number) => void;
  onSetCapturesToUploads: (captures: HTMLCanvasElement[]) => void;
  skipUpload: boolean;
  isComplete: boolean;
  isWebcamAccessGranted?: boolean;
  setIsWebcamAccessGranted: (value?: boolean) => void;
  onSelectFromFile: (event: React.ChangeEvent<HTMLInputElement>, index: number) => void;
  onTryToStartCapture: (startedIndex: number) => void;
  isStarted: boolean;
  stopCapture: () => void;
  pictureLoader?: number;
  formRef: RefObject<HTMLFormElement>;
  setCaptureError: (value: boolean) => void;
  startedIndex: number;
}

export function usePhoto(
  requiredPhotoCount: number,
  skipUpload: boolean,
  uploads: FileWithCanvas[],
  setUploads: Dispatch<SetStateAction<FileWithCanvas[]>>,
): Hook {
  const [isCaptureAvailable, setIsCaptureAvailable] = useState(false);
  const [isWebcamAccessGranted, setIsWebcamAccessGranted] = useState<boolean | undefined>();

  const [isComplete, setIsComplete] = useState(false);
  const [isStarted, setIsStarted] = useState(false);
  const [startedIndex, setStartedIndex] = useState<number>(0);
  const [captureError, setCaptureError] = useState(false);

  const { cleanError } = useContext(ErrorContext);
  const formRef = useRef<HTMLFormElement>(null);
  const [pictureLoader, setPictureLoader] = useState<undefined | number>();

  const onSetCapturesToUploads = useCallback(async (canvases: HTMLCanvasElement[]): Promise<void> => { // eslint-disable-line max-len
    const blobs = await Promise.all(canvases.map(canvasToBlob));
    const newUploads: FileWithCanvas[] = canvases.map((canvas, index) => ({
      originalFile: new File(
        [blobs[index]],
        `${index}.${blobs[index].type.split('image/')[1]}`,
        { type: blobs[index].type },
      ),
      canvas,
    }));

    if (newUploads.length === requiredPhotoCount) {
      setUploads(newUploads);
    } else {
      setUploads((prevUploads) => prevUploads.map((upload) => {
        if (!upload.originalFile) {
          return newUploads[0];
        }
        return upload;
      }));
    }
  }, [requiredPhotoCount, setUploads]);

  const onSelectFromFile = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>, insertIndex: number) => {
      if (event.currentTarget.files) {
        setPictureLoader(insertIndex);
        const newUpload = (
          await Promise.all([...event.currentTarget.files].map(fileToFileWithCanvas))
        )[0];

        setUploads((prevUploads) => {
          const newUploads = prevUploads.map((upload, index) => {
            if (index === insertIndex) {
              return newUpload;
            }
            return upload;
          });
          if (newUploads.length < requiredPhotoCount) {
            newUploads.push({});
          }
          return newUploads;
        });
      }
      setPictureLoader(undefined);
      cleanError();
      if (formRef.current) { formRef.current.reset(); }
    }, [cleanError, setUploads, requiredPhotoCount],
  );

  useEffect(() => { // set complete based on uploads.length
    setIsComplete(
      requiredPhotoCount === uploads
        .filter((up) => !!up.originalFile || !!up.canvas).length,
    );
  }, [requiredPhotoCount, uploads]);

  useEffect((): void => { // set upload available based on browser supported api
    const supported = 'mediaDevices' in navigator;

    if (supported) {
      setIsCaptureAvailable(true);
    }
  }, []);

  useEffect(() => {
    if (skipUpload) {
      setTimeout(() => setUploads([{}]), 500);
    }
  }, [setUploads, skipUpload]);

  const onTryToStartCapture = useCallback(async (index: number) => {
    setStartedIndex(index);
    setPictureLoader(index);
    if ('permissions' in navigator) { // if permission api available, if not - skip
      try {
        const result = await navigator.permissions.query({ name: 'camera' });
        if (result.state === 'denied') {
          setCaptureError(true);
          setPictureLoader(undefined);
          try {
            await navigator.mediaDevices.getUserMedia({ video: {} }); // force camera api
          } catch { } // eslint-disable-line
          return;
        }
      } catch {
        console.log('Unable to check Camera permission');
      }
    }

    // check if access to camera has been alredy granted
    navigator.mediaDevices.enumerateDevices()
      .then(
        (devices) => {
          setIsWebcamAccessGranted((prevAccess) => {
            const access = devices.some((device) => device.kind === 'videoinput' && !!device.label);

            if (access || prevAccess === false) {
              setIsStarted(true);
              return true;
            }

            return false;
          });
        },
      );
  }, []);

  const stopCapture = useCallback(() => {
    setIsStarted(false);
    cleanError();
    setPictureLoader(undefined);
  }, [cleanError]);

  const onCleanUpload = useCallback((cleanIndex: number) => {
    setUploads((prevUploads) => prevUploads.map((upload, index) => {
      if (index === cleanIndex) {
        return {};
      }
      return upload;
    }));
  }, [setUploads]);

  return {
    isCaptureAvailable,
    uploads,
    onCleanUpload,
    onSetCapturesToUploads,
    skipUpload,
    isComplete,
    onSelectFromFile,
    isStarted,
    isWebcamAccessGranted,
    setIsWebcamAccessGranted,
    onTryToStartCapture,
    stopCapture,
    pictureLoader,
    formRef,
    captureError,
    setCaptureError,
    startedIndex,
  };
}
