
import React, { useState } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { ProposalDetails } from './components/ProposalDetails/ProposalDetails';
import { ProposalsList } from './components/ProposalsList/ProposalsList';

interface ProposalsProps {
  isDemo: boolean;
  setDemoType: (_: 'PS' | 'NBU') => void;
}

export function Proposals({ isDemo, setDemoType }: ProposalsProps): JSX.Element {
  const [isSingleProposal, setIsSingleProposal] = useState<boolean | undefined>(undefined);

  return (
    <Switch>
      <Route key="proposals" exact path="/proposals">
        <ProposalsList
          isDemo={isDemo}
          setDemoType={setDemoType}
          setIsSingleProposal={setIsSingleProposal}
        />
      </Route>
      <Route key="proposal" path="/proposals/:clientId">
        <ProposalDetails
          isDemo={isDemo}
          setDemoType={setDemoType}
          isSingleProposal={isSingleProposal}
        />
      </Route>
      <Redirect to="/proposals" />
    </Switch>
  );
}
