import { useState, useCallback, createRef } from 'react';

interface Hook {
  list: ListEl[];
  setSingleListValue: ({ value, key }: { value: string; key: number }) => void;
}

interface Init {
  constraints?: {
    disabled: boolean;
  };
}

export interface ListEl {
  value?: string;
  ref: React.RefObject<HTMLInputElement>;
  constraints?: {
    maxLength: number;
    disabled: boolean;
    required?: boolean;
  };
}

function listInit(init: boolean[]): ListEl[] {
  return init.map((isEnabled): ListEl => ({
    value: undefined,
    ref: createRef<HTMLInputElement>(),
    constraints: {
      disabled: !isEnabled,
      maxLength: 1,
      required: isEnabled,
    },
  }));
}

export function useDigitsForm(init: boolean[]): Hook {
  const [list, setList] = useState(listInit(init));

  const setSingleListValue = useCallback(({ value, key }: { value: string; key: number }): void => {
    setList((prevList: ListEl[]): ListEl[] => prevList.map((el, index): ListEl => {
      if (index === key) {
        return {
          ...el,
          value: value[value.length - 1],
        };
      }
      return el;
    }));
  }, []);

  return {
    setSingleListValue,
    list,
  };
}
