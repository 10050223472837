import { useCallback, useContext } from 'react';
import { ErrorContext } from '../../../../../../Errors/ErrorsContextProvider';

interface Hook {
  consents: K2Paperless.ConsentWithAccept[];
  toggleSingleConsent: (consentId: number) => void;
  checkAllConsents: () => void;
  uncheckAllConsents: () => void;
}

export function mapConsentToConsentWithAccept(
  consent: K2Paperless.ConsentWithAccept,
): K2Paperless.ConsentWithAccept {
  if (!consent.accepted) {
    return ({ ...consent, accepted: false });
  }
  return consent;
}

export const useConsents = (
  index: string,
  consents: K2Paperless.ConsentWithAccept[],
  setConsents: (index: string, newConsents: K2Paperless.ConsentWithAccept[]) => void,
): Hook => {
  const { cleanError } = useContext(ErrorContext);

  const toggleSingleConsent = useCallback((consentId: number): void => {
    setConsents(index, consents.map(
      (consent) => (consent.id === consentId ? {
        ...consent,
        accepted: !consent.accepted,
      } : consent),
    ));
    cleanError();
  }, [cleanError, consents, index, setConsents]);

  const checkAllConsents = useCallback((accepted = true): void => {
    setConsents(index, consents.map((consent) => ({ ...consent, accepted })));
    cleanError();
  }, [cleanError, consents, index, setConsents]);

  const uncheckAllConsents = useCallback(() => {
    checkAllConsents(false);
    cleanError();
  }, [checkAllConsents, cleanError]);

  return {
    consents,
    toggleSingleConsent,
    checkAllConsents,
    uncheckAllConsents,
  };
};
