import styled from 'styled-components';
import { P, H2, Props as TypoProps } from '../Typography/Typo';
import { colors } from '../Colors/colors';
import { LANDSCAPE_TABLET, LAPTOP, TABLET } from '../../breakpoints';
import { Button } from '../Button/Button';


// this is main coontainer especially for base content
export const ContentWrapper = styled.article`
  display: flex;
  flex-direction: column;
  text-align: left;
  max-width: calc(100% - 30px);
  width: 760px;
  margin: 0 auto;
  @media screen and (min-width: ${LAPTOP}px) {
    min-height: calc(100vh - 254px);
  }
`;

export const LineSeparator = styled.hr`
  border: 0;
  border-top: 1px solid ${colors.alto};
  display: block;
  width: 100%;
  margin: 24px 0;

  &:last-child {
    margin-bottom: 0;
  }
`;

// this regular text with few margin rules
export const Paragraph = styled(P)`
  margin-bottom: 15px;

  + h1 {
    margin-top: 25px;
  }
  + h2 {
    margin-top: 15px;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

// secondary title
export const HeaderTextH2 = styled(H2)`
  margin: 30px 0 15px;
  font-size: 30px;
  font-weight: 500;
  color: ${({ color = 'goldDrop' }: TypoProps): string => colors[color]};

  ${LineSeparator} + & {
    margin-top: 12px;
  }

  @media (max-width: ${LANDSCAPE_TABLET}px) {
    font-size: 24px;
  }
`;

export const DesktopColumn = styled.div`
  min-width: calc(607px + ((100% - 1216px) / 2));
  max-width: calc(607px + ((100% - 1216px) / 2));
  min-height: 610px;
  background: ${colors.white};
  padding: 20px calc((100% - 1216px) / 2) 0 40px;
`;

export const DesktopRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: stretch;
`;

export const GradientLine = styled.div`
  width: 100%;
  height: 1px;
  margin-bottom: 15px;
  position: relative;
  top: -10px;
  background: linear-gradient(90deg, rgba(238,127,0,1) 0%, rgba(255,255,255,1) 100%);
`;

export const BackButton = styled(Button)`
  @media (max-width: ${TABLET}px) {
    margin-bottom: 15px;
  }
  @media (min-width: ${TABLET}px) {
    position: absolute;
    left: 0;
    top: 0;
  }
`;
