import React, { useCallback, useState, useEffect, useLayoutEffect } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { Icon } from '../../../../../../../../ui/Icon/Icon';
import { IdCameraFrontMask } from './components/IdCameraFrontMask';
import { useCameraStream } from './hooks/useCameraStream';

const VideoPlayer = styled.video`
  ${({ hide }: { hide: boolean }): string => (hide ? 'display: none;' : '')};
  max-height: 100%;
  pointer-events: none;
`;

const IdCameraWrapper = styled.div`
  background: black;
  position: fixed;
  left:0;
  right:0;
  top:0;
  bottom:0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  z-index: 1;
`;

const IdCameraButton = styled.button`
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  outline: none;
`;

const Canvas = styled.canvas`
  ${({ hide }: { hide: boolean }): string => (hide ? 'display: none;' : '')};
  max-height: 100%;
  max-width: 100%;
`;

const AcceptButtonsWrapper = styled.div`
  position: sticky;
  bottom: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 80px;
  max-height: 80px;
  z-index: 1;
`;

const Button = styled.button`
  background: black;
  border: 1px solid white;
  width: 5em;
  color: white;
  border-radius: 5px;
  width: 45%;
  max-width: 300px;
  height: 3em;
  cursor: pointer;
`;

const StreamWrapper = styled.div`
  position: relative;
  max-height: calc(100vh - 80px);
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

const CloseIcon = styled(Icon)`
  position: absolute;
  right: 6px;
  top: 6px;
  font-size: 28px;
  cursor: pointer;
  transition: opacity 250ms;
  z-index: 1;

  &:active {
    opacity: 0.7;
  }
`;

const Space = styled.div`
  min-height: 20px;
`;

interface Props {
  documentType: K2Paperless.DocumentType | 'OTHER';
  onCapturesReady: (captures: HTMLCanvasElement[]) => void;
  onCaptureError: () => void;
  alreadyCapturesCount: number;
  startedIndex: number;
  onStopCapture: () => void;
  requiredPhotoCount: 1 | 2;
}

export function IdCamera({
  documentType, onCapturesReady, onCaptureError, alreadyCapturesCount,
  startedIndex, onStopCapture, requiredPhotoCount,
}: Props): JSX.Element {
  const {
    playerRef,
    canvasRef,
    onCaptureStream,
    onReset,
    isCaptured,
    capture,
  } = useCameraStream(onCaptureError);
  const [el, setElement] = useState<Element | undefined>();

  const [captures, setCaptures] = useState<HTMLCanvasElement[]>([]);

  const onAcceptPhoto = useCallback(() => {
    setCaptures((prevCaptures) => {
      if (capture) {
        return [...prevCaptures, capture];
      }

      return prevCaptures;
    });
    onReset();
  }, [capture, onReset]);

  useEffect(() => {
    if (
      captures.length === (requiredPhotoCount - alreadyCapturesCount)
    ) {
      onCapturesReady(captures);
    }
  }, [alreadyCapturesCount, captures, documentType, onCapturesReady, requiredPhotoCount]);

  useLayoutEffect(() => {
    const newEl = document.createElement('div');
    document.body.appendChild(newEl);
    document.body.style.overflow = 'hidden';
    setElement(newEl);

    return (): void => {
      document.body.style.overflow = 'auto';
      newEl.remove();
    };
  }, []);

  if (!el) {
    return <></>;
  }

  return ReactDOM.createPortal(
    (
      <IdCameraWrapper>
        <Space />
        <CloseIcon
          name="clear"
          color="white"
          onClick={onStopCapture}
        />
        <StreamWrapper>
          <VideoPlayer
            autoPlay
            playsInline
            ref={playerRef}
            hide={isCaptured}
            controls={false}
          />
          <Canvas ref={canvasRef} hide={!isCaptured} />
          <IdCameraFrontMask
            playerRef={playerRef}
            showContent={!isCaptured}
            documentType={documentType}
            index={captures.length}
            startIndex={startedIndex}
            requiredPhotoCount={requiredPhotoCount}
          />
        </StreamWrapper>
        <AcceptButtonsWrapper>
          {!isCaptured && (
            <IdCameraButton onClick={onCaptureStream}>
              <Icon fontSize={48} name="camera_alt" />
            </IdCameraButton>
          )}
          {isCaptured && (
            <>
              <Button onClick={onReset}>Popraw</Button>
              <Button onClick={onAcceptPhoto}>Dodaj</Button>
            </>
          )}
        </AcceptButtonsWrapper>
      </IdCameraWrapper>
    ), el,
  );
}
