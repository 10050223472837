
import React from 'react';
import styled, { keyframes, css, CSSProp } from 'styled-components';
import { colors } from '../Colors/colors';
import logo from '../../img/logo192.png';

const loading = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

interface ResizeSpinProps {
  duration: number;
  color: string;
  size: number;
  background: string;
}

const ResizeSpin = styled.div<ResizeSpinProps>`
  border-radius: 50%;
  box-shadow: inset 0 0 0 1em;
  color: ${(props: ResizeSpinProps): CSSProp => props.color};
  font-size: ${(props: ResizeSpinProps): CSSProp => `${props.size}px`};
  height: 10em;
  position: relative;
  text-indent: -99999em;
  transform: translateZ(0);
  width: 10em;

  &:after {
    animation: ${(props: ResizeSpinProps): CSSProp => css`${loading} ${props.duration}s infinite ease forwards`};
    background: ${(props: ResizeSpinProps): CSSProp => props.background};
    border-radius: 50%;
    border-radius: 0 10.2em 10.2em 0;
    content: '';
    height: 10.2em;
    left: 5.1em;
    position: absolute;
    top: -0.1em;
    transform-origin: 0px 5.1em;
    width: 5.2em;
  }
`;

const Logo = styled.img`
  width: 54px;
  top: 3px;
  left: 2px;
  position: absolute;
  z-index: 1;
`;

interface Props {
  duration?: number;
  color?: string;
  size?: number;
  background?: string;
}

export const LogoLoader = ({
  background = '#fff',
  color = colors.goldDrop,
  duration = 1,
  size = 6,
}: Props): JSX.Element => (
  <ResizeSpin
    background={background}
    color={color}
    duration={duration}
    size={size}
  >
    <Logo src={logo} alt="NN logo" />
  </ResizeSpin>
);
