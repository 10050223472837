import { useLayoutEffect, useState, useCallback } from 'react';

interface Hook {
  maskWidth: number;
  maskHeight: number;
}

function getMaskRect(
  variant: K2Paperless.DocumentType | 'OTHER',
  playerWidth: number,
  playerHeight: number,
): { width: number; height: number } {
  const ratio = playerWidth / playerHeight;

  // DO
  if (variant === 'DO') {
    if (ratio > 1.4) {
      return {
        height: playerHeight * 0.9,
        width: (playerHeight * 0.9) * 1.6,
      };
    }

    return {
      width: playerWidth * 0.9,
      height: (playerWidth * 0.9) / 1.6,
    };
  }

  // PASSPORT / KARTA POBYTU
  if (ratio > 1.4) {
    return {
      height: playerHeight * 0.9,
      width: (playerHeight * 0.9) * 1.42,
    };
  }

  return {
    width: playerWidth * 0.9,
    height: (playerWidth * 0.9) / 1.42,
  };
}

export function useMaskSize(
  playerRef: React.RefObject<HTMLVideoElement>,
  variant: K2Paperless.DocumentType | 'OTHER',
): Hook {
  const [maskWidth, setMaskWidth] = useState<Hook['maskWidth']>(0);
  const [maskHeight, setMaskHeight] = useState<Hook['maskHeight']>(0);

  const recalculateMaskConfig = useCallback(() => {
    if (playerRef.current) {
      const {
        width: playerWidth,
        height: playerHeight,
      } = playerRef.current.getBoundingClientRect();

      const { height, width } = getMaskRect(variant, playerWidth, playerHeight);

      if (height !== 0 && width !== 0) {
        setMaskHeight(height);
        setMaskWidth(width);
      }
    }
  }, [playerRef, variant]);

  useLayoutEffect(
    () => {
      const interval = setInterval(() => recalculateMaskConfig(), 1500);
      setTimeout(() => recalculateMaskConfig(), 300);

      return (): void => clearInterval(interval);
    },
    [recalculateMaskConfig],
  );

  return {
    maskWidth,
    maskHeight,
  };
}
