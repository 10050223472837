import { useCallback, useState, useContext } from 'react';
import { ErrorContext } from '../../../../../../Errors/ErrorsContextProvider';

export const acceptExtensions = '.jpg,.jpeg,.jpe,.png,.bpm,.tiff,.pdf';
export const MAX_ALLOWED_FILE_SIZE = 10 * 1000 * 1024;

type EventFunctionType = (event: React.ChangeEvent<HTMLInputElement>, index: number) => void;

interface Hook {
  onAddPhotoFromFileEvent: (
    event: React.ChangeEvent<HTMLInputElement>, index: number
  ) => void;
  instantValidation?: boolean;
}

export function useInstantValidation(onSelectFromFile: EventFunctionType): Hook {
  const { setValidationError } = useContext(ErrorContext);
  const [instantValidation, setInstantValidation] = useState<boolean | undefined>(undefined);

  const onAddPhotoFromFileEvent = useCallback((
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ): void => {
    const currentFile = event.currentTarget.files;
    if (currentFile && currentFile[0]) {
      if (currentFile[0].name.toLowerCase().includes('.heic')) {
        return setValidationError({ messageKey: 'HEIC-FORMAT' });
      }

      if (!acceptExtensions.includes(currentFile[0].type.split('/')[1])) {
        setInstantValidation(true);
        return setValidationError({ messageKey: 'INVALID-FORMAT' });
      }

      if (currentFile[0].size > MAX_ALLOWED_FILE_SIZE) {
        setInstantValidation(true);
        return setValidationError({ messageKey: 'FILE-TOO-LARGE' });
      }
    }
    setInstantValidation(undefined);
    return onSelectFromFile(event, index);
  }, [onSelectFromFile, setValidationError]);

  return {
    onAddPhotoFromFileEvent,
    instantValidation,
  };
}
