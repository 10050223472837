import React from 'react';
import { RoundedPictogram } from '@agent-zone/ui-library/esm/RoundedPictogram';
import { Grid } from '@agent-zone/ui-library/esm/Grid';
import styled, { keyframes } from 'styled-components';
import { nnColors } from '@agent-zone/ui-library/esm/colors';

const SuccessMarkWrapperAnimation = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

const RoundedPictogramCover = keyframes`
  0% { width: 80px; }
  100% { width: 0; }
`;

const SuccessMarkWrapper = styled.div`
  height: 120px;
  width: 120px;
  border-radius: 100%;
  background: ${nnColors.colourGreen700};
  opacity: 0;
  animation: ${SuccessMarkWrapperAnimation} 1s forwards ease-in-out;
  position: relative;
  margin-top: 97px;
  margin-bottom: 97px;
  &::after {
    position: absolute;
    content: '';
    background: ${nnColors.colourGreen700};
    animation-delay: 1s;
    width: 800;
    height: 80px;
    animation: ${RoundedPictogramCover} 1s forwards ease-in-out;
    border-radius: 100%;
    top: 20px;
    right: 20px;
  }
`;


export const SuccessMark: React.FC = () => (
  <Grid center>
    <SuccessMarkWrapper>
      <RoundedPictogram
        background="colourGreen700"
        icon="ok"
        iconColor="white"
        iconSize="xxl"
      />
    </SuccessMarkWrapper>
  </Grid>
);
