import { Typo } from '@agent-zone/ui-library/esm/Typo';
import React from 'react';
import { ErrorMessageWrapper } from './ErrorMessageWrapper';

export function TooMuchAttempts(): JSX.Element {
  return (
    <ErrorMessageWrapper>
      <Typo $type="H1" $color="orangeMedium">
        Limit prób został wyczerpany
      </Typo>
      <Typo $type="body">
        Przykro nam ale limit prób został wyczerpany. Twój PIN został zablokowany.
      </Typo>
      <Typo $type="body">
        Aby otrzymać nowy kod PIN prosimy o kontakt z przedstawicielem
        z którym został sporządzony wniosek.
      </Typo>
    </ErrorMessageWrapper>
  );
}
