import { useCallback, useContext, useEffect, useState } from 'react';
import Axios from 'axios';
import { ErrorContext } from '../../../../../../Errors/ErrorsContextProvider';
import { mapToRequestConsent, mapToRequestClient } from '../../../../../../../hooks/mapToRequest';
import { FileWithCanvas } from './usePhoto';

interface SubmitRequiredData {
  mainClient: K2Paperless.MainClientWithAccept;
  clients?: K2Paperless.ClientWithAccept[];
  transferFromOwnedBankAccount: boolean | null;
  // base photos
  uploads?: FileWithCanvas[];
  skipUpload?: boolean;
  // additional photos
  additionalDocumentType?: string;
  additionalUploads?: FileWithCanvas[];
  skipAdditionalUpload?: boolean;
}

export function useSubmit(
  setSubmitting: (v: boolean) => void,
  submitting: boolean,
  onFinish: () => void,
): (newSubmitData: SubmitRequiredData) => void {
  const [submitData, setSubmitData] = useState<SubmitRequiredData | undefined>(undefined);
  const { cleanError, setResponseError } = useContext(ErrorContext);

  // we wait for state to update before submit
  useEffect(() => {
    if (submitting && submitData) { // send when submitting is true
      setTimeout(() => {
        const {
          skipUpload, uploads,
          skipAdditionalUpload, additionalUploads, additionalDocumentType,
          transferFromOwnedBankAccount,
          mainClient,
          clients,
        } = submitData;
        const formData = new FormData();

        // base photos
        if (!skipUpload && uploads) {
          uploads.forEach((upload) => {
            if (upload.originalFile) {
              formData.append('files', upload.originalFile, upload.originalFile.name);
            }
          });
        }

        // additional photos
        if (!skipAdditionalUpload && additionalUploads) {
          additionalUploads.forEach((upload) => {
            if (upload.originalFile) {
              formData.append('additionalFiles', upload.originalFile, upload.originalFile.name);
            }
          });
        }

        const additionalDocumentsViaOtherChannel = (): boolean | null | undefined => {
          if (!mainClient.additionalDocumentRequired
             && !mainClient.transferFromOwnedBankAccountRequired) {
            return null;
          }

          if (transferFromOwnedBankAccount) {
            return null;
          }

          return skipAdditionalUpload;
        };

        const postData: K2.Request.EditFormRequest = {
          clientId: mainClient.clientId,
          consents: mainClient.consents.map(mapToRequestConsent),
          clients: clients ? clients.map(mapToRequestClient) : clients,
          // base photos
          documentsViaOtherChannel: skipUpload,
          // additional photos
          additionalDocumentsViaOtherChannel: additionalDocumentsViaOtherChannel(),
          additionalDocumentType,
          transferFromOwnedBankAccount,
        };

        formData.set('request', JSON.stringify(postData));

        Axios.post<K2Response.AuthenticationConfig>(
          '/direct/form',
          formData,
          {
            headers: {
              'content-type': 'multipart/form-data',
            },
          },
        ).then(() => {
          onFinish();
          cleanError();
        }).catch((err) => {
          if (err.response) {
            setResponseError(err.response);
          }
          throw err;
        }).finally(() => {
          setSubmitting(false);
          setSubmitData(undefined);
        });
      }, 0);
    }
  // prevent multiple form submission
  }, [submitData, submitting]); // eslint-disable-line react-hooks/exhaustive-deps

  return useCallback(async (newSubmitData: SubmitRequiredData) => {
    setSubmitting(true);
    setSubmitData(newSubmitData);
    cleanError();
  }, [cleanError, setSubmitting]);
}
