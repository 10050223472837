import React, { useCallback, ReactNode, useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { InputWithReference } from '../../ui/Input/Inputs';
import { useDigitsForm, ListEl } from './hook/useDigitsForm';
import { Typo } from '../../ui/Typography/Typo';
import { useDimensions } from '../../hooks/useDimensions';
import { DESKTOP } from '../../breakpoints';
import { ErrorContext } from '../Errors/ErrorsContextProvider';

const OuterFormWrapper = styled.div`
  display: flex;
`;

interface FormWrapperProps {
  name?: string;
}

const FormWrapper = styled.div<FormWrapperProps>`
  display: inline-flex;
  position: relative;
  flex-wrap: wrap;
`;

const SingleFieldWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
`;
export interface DigitsFormProps {
  config: boolean[];
  setInputArray: (inputArray: (string | undefined)[]) => void;
  numbered?: boolean;
  name?: string;
  visit?: boolean;
  secure?: boolean;
  dense?: boolean;
}

const focusEl = (el?: ListEl): void => { if (el && el.ref.current) { el.ref.current.focus(); } };
const blurEl = (el?: ListEl): void => { if (el && el.ref.current) { el.ref.current.blur(); } };

const findNext = (list: ListEl[], index: number): ListEl | undefined => list
  .find((el, elIndex) => (elIndex > index) && !el.constraints?.disabled);

const findPrev = (list: ListEl[], index: number): ListEl | undefined => {
  const prevElements = list
    .slice(0, index)
    .filter((el) => !el.constraints?.disabled);
  return prevElements[prevElements.length - 1];
};

const findLast = (list: ListEl[], index: number, config: boolean[]): ListEl | undefined => {
  const lastRequiredIndex = config
    .map((el, elIndex) => (el ? elIndex : 0))
    .reduce((a, b) => Math.max(a, b));
  return list.find((el, elIndex) => index === lastRequiredIndex
    && elIndex === lastRequiredIndex
    && !el.constraints?.disabled);
};

const findFirstIndex = (list: ListEl[]): number => list
  .findIndex((el) => !el.constraints?.disabled);

export function DigitsForm({
  config,
  setInputArray,
  numbered = false,
  name,
  visit,
  secure,
  dense,
}: DigitsFormProps): JSX.Element {
  const { list, setSingleListValue } = useDigitsForm(config);
  const { viewportWidth } = useDimensions();
  const [inputsVisited, setInputVisited] = useState(false);
  const { cleanError } = useContext(ErrorContext);

  useEffect(() => { if (visit) { setInputVisited(true); } }, [visit]);

  const focusBehavior = useCallback((value, index): void => {
    const nextEl = findNext(list, index);
    const lastEl = findLast(list, index, config);
    if (value) { focusEl(nextEl); }
    if (lastEl) { blurEl(lastEl); }
  }, [config, list]);

  const onChangeInputValue = useCallback((value: string, index: number): void => {
    focusBehavior(value, index);
    cleanError();
    if (value) { setSingleListValue({ value, key: index }); }
  }, [focusBehavior, cleanError, setSingleListValue]);

  const backwardFocus = useCallback((keyCode: number, index: number): void => {
    const prevEl = findPrev(list, index);
    if (keyCode === 8 || keyCode === 46) { focusEl(prevEl); setSingleListValue({ value: '', key: index }); }
  }, [list, setSingleListValue]);

  useEffect(() => {
    setInputArray(list.map((el) => el.value));
  }, [list, setInputArray]);


  return (
    <OuterFormWrapper>
      <FormWrapper name={name}>
        {list.map((el, index): ReactNode => (
          <SingleFieldWrapper key={`input${index + 1}`}>
            {numbered && <Typo color="silver" fontSize={12}>{index + 1}</Typo>}
            <InputWithReference
              dense={dense}
              value={el.value}
              visit={inputsVisited}
              onChangeInput={(value: string): void => onChangeInputValue(value, index)}
              onKeyDown={(keyCode): void => backwardFocus(keyCode, index)}
              maxLength={el.constraints && el.constraints.maxLength}
              required={el.constraints && el.constraints.required}
              disabled={el.constraints && el.constraints.disabled}
              ref={el.ref}
              type="number"
              max="9"
              min="0"
              step="1"
              secure={secure}
              autoFocus={viewportWidth >= DESKTOP && findFirstIndex(list) === index}
            />
          </SingleFieldWrapper>
        ))}
      </FormWrapper>
    </OuterFormWrapper>
  );
}
