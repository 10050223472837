import { nnColors } from '@agent-zone/ui-library/esm/colors';
import { getTypoStyle } from '@agent-zone/ui-library/esm/Typo';
import React, { forwardRef, ChangeEvent, useState, MutableRefObject, useCallback, KeyboardEvent } from 'react';
import styled, { css, CSSProp, keyframes } from 'styled-components';
import { MOBILE } from '../../breakpoints';
import { colors } from '../Colors/colors';

interface Props {
  onChangeInput: (value: string) => void;
  max?: string;
  min?: string;
  type?: string;
  disabled?: boolean;
  pattern?: string;
  value?: string;
  maxLength?: number;
  required?: boolean;
  step?: string;
  onKeyDown?: (keyCode: number) => void;
  visit?: boolean;
  autoFocus: boolean;
  secure?: boolean;
  dense?: boolean;
}

const allowedButtons = [8, 46, 16, 9];

type RefType =
  ((instance: HTMLInputElement | null) => void)
  | MutableRefObject<HTMLInputElement | null>
  | null;

function Input(props: Props, ref: RefType): JSX.Element {
  const [visited, setVisited] = useState<boolean>(false);
  const {
    onChangeInput,
    onKeyDown,
    type,
    visit,
  } = props;

  const onFocusHandler = useCallback((): void => {
    setVisited(true);
  }, []);

  const keyDown = (e: KeyboardEvent): void => {
    if (!(/^[0-9]*$/g.test(e.key) || allowedButtons.includes(e.keyCode))) {
      e.preventDefault();
    } else if (onKeyDown) { onKeyDown(e.keyCode); }
  };
  return (
    <InputWrapper value={props.value} secure={props.secure} dense={props.dense}>
      <StyledInput
        ref={ref}
        value={props.value || ''}
        visited={visit || visited}
        onChange={(e: ChangeEvent<HTMLInputElement>): void => onChangeInput(e.target.value.replace(`${props.value}`, ''))}
        onFocus={onFocusHandler}
        onKeyDown={keyDown}
        type={type || 'text'}
        disabled={props.disabled}
        max={props.max}
        min={props.min}
        pattern={props.pattern}
        maxLength={props.maxLength}
        required={props.required}
        step={props.step}
        secure={props.secure}
        autoFocus={props.autoFocus}
        dense={props.dense}
      />
    </InputWrapper>
  );
}

export const InputWithReference = forwardRef(Input);

const secureValueAnimation = keyframes`
  0% { }
  100% { color: transparent }
`;

const StyledInput = styled.input <{ value?: string; visited: boolean; secure?: boolean; dense?: boolean }>`
${getTypoStyle({ $type: 'H2' })}
${({ dense }): CSSProp => (
    !dense
      ? css`
    height: 48px;
    width: 48px;
    @media screen and (max-width: ${MOBILE}px) {
      width: 44px;
    }
  `
      : css`
    height: 48px;
    width: 37px;
  `)}
  appearance: none;
  border-radius: 3px;
  border: 1px solid ${nnColors.greyLight};
  box-shadow: none;
  text-align: center;
  margin: 2px 5px 0;

  @media screen and (min-width:360px) and (max-width: 1199px) {
    margin: 2px 4px 0;
  }

  @media screen and (max-width: ${MOBILE}px) {
    font-size: 1.25rem;
  }

  &[type=number] {
    -moz-appearance:textfield;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:focus {
    outline-color: ${colors.flushOrange};
    border-color: ${colors.flushOrange};
    box-shadow: 0px 5px 20px rgba(234, 101, 13, 0.168627450980392);
  }

  &:disabled {
    background-color: ${colors.silver};
  }

  ${({ visited }): CSSProp => (visited && css`
    &:invalid {
      border-color: ${colors.red};
    }
    &:invalid:focus {
      border-color: ${colors.flushOrange};
    }
  `) || ''}
  ${({ secure, value }): CSSProp => ((secure && value)
    ? css`
      animation: ${secureValueAnimation} 275ms forwards ease-in-out;
    ` : '')}
`;

const dotAnimation = keyframes`
  0% { background-color: transparent; }
  100% { background: ${nnColors.greyDark}; }
`;

const InputWrapper = styled.span<{ value?: string; secure?: boolean; dense?: boolean }>`
  position: relative;
  ${({ value, secure, dense }): CSSProp => ((value && secure) ? css`
    &:after {
      content: "";
      position: absolute;
      height: 10px;
      width: 10px;
      z-index: 1;
      top: 20px;
      left: ${dense ? '19.5px' : '25px'};
      border-radius: 100%;
      animation: ${dotAnimation} 275ms forwards ease-in-out;
      animation-delay: 275ms;
      ${!dense && css`
        @media screen and (max-width: ${MOBILE}px) {
          left: 22px;
        }
      `}
    }
  ` : '')}
`;
