import { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useInterval } from '../../hooks/useInterval';

const INACTIVITY_TIME = 900;

interface InactivityHook {
  showInactivityNotif: boolean;
  setShowInactivityNotif: (_: boolean) => void;
}

export const useInactivity = (): InactivityHook => {
  const [timer, setTimer] = useState<number>(0);
  const [showInactivityNotif, setShowInactivityNotif] = useState<boolean>(false);
  const location = useLocation();

  const resetTimer = useCallback(() => setTimer(0), []);

  useInterval((): void => setTimer(timer + 1), 1000);

  useEffect(() => {
    if (timer > INACTIVITY_TIME && location.pathname !== '/auth') {
      setShowInactivityNotif(true);
    } else {
      setShowInactivityNotif(false);
    }
  }, [location.pathname, timer]);

  useEffect(() => {
    window.addEventListener('click', resetTimer);

    return (): void => window.removeEventListener('click', resetTimer);
  }, [resetTimer]);

  useEffect(() => {
    window.addEventListener('keypress', resetTimer);

    return (): void => window.removeEventListener('keypress', resetTimer);
  }, [resetTimer]);

  return {
    showInactivityNotif,
    setShowInactivityNotif,
  };
};
