import React, { ReactNode } from 'react';
import { Typo } from '@agent-zone/ui-library/esm/Typo';
import { MarginsWrapper } from '@agent-zone/ui-library/esm/Margins';
import { dateFormat } from '../../../../../../../utils/utils';
import { ListItem, Unorderedlist } from '../../../../../../../../ui/Unorderedlist/Unorderedlist';

interface ClientNamesProps {
  client: K2Paperless.Client | K2Paperless.MainClient;
}

export const ClientNames = ({ client }: ClientNamesProps): JSX.Element => {
  const clientNames = client.clientName.split(' ');
  return (
    <>
      {clientNames.map((name: string): ReactNode => (
        <span key={name}>{`${name} `}</span>
      ))}
    </>
  );
};

interface Props {
  client: K2Paperless.Client | K2Paperless.MainClient;
  mainClient: K2Paperless.MainClient;
  isMainClientJustGuardian: boolean;
  isClientChild: boolean;
  signDate: string;
  contractNumber: string;
  contractDescription: string;
  isSingleProposal?: boolean;
  processType: 'PS' | 'NBU';
}

export const ConsentPersonalizedMsg = ({
  client,
  processType,
  isClientChild,
  signDate,
  contractNumber,
  contractDescription,
}: Props): JSX.Element => {
  const childClient = client as K2Paperless.Client;
  return (
    <>
      <MarginsWrapper marginTop="LXL" marginBottom="L">
        {processType === 'PS' ? (
          <Typo $type="H1" noMargin $color="orangeMedium">Wniosek o zmianę na polisie</Typo>
        ) : (
          <Typo $type="H1" noMargin $color="orangeMedium">
            Wniosek o zawarcie umowy ubezpieczeniowej
          </Typo>
        )}
      </MarginsWrapper>
      <Unorderedlist marginBottom="LXL">
        <ListItem>
          <MarginsWrapper marginBottom="S">
            <Typo noMargin $type="body">
              Polisa: &nbsp;
              <Typo $type="bodyBold" inline>{contractDescription}</Typo>
            </Typo>
          </MarginsWrapper>
        </ListItem>
        <ListItem>
          <MarginsWrapper marginBottom="S">
            <Typo noMargin $type="body">
              Data zgłoszenia: &nbsp;
              <Typo $type="bodyBold" inline>{dateFormat(signDate)}</Typo>
            </Typo>
          </MarginsWrapper>
        </ListItem>
        <ListItem>
          <MarginsWrapper marginBottom="S">
            <Typo noMargin $type="body">
              {processType === 'PS' ? 'Numer polisy:' : 'Numer wniosku: '}
              &nbsp;
              <Typo $type="bodyBold" inline>{contractNumber}</Typo>
            </Typo>
          </MarginsWrapper>
        </ListItem>
      </Unorderedlist>
      <Typo $type="H3">
        {isClientChild ? 'Uzupełnij zgody i oświadczenia' : 'Uzupełnij swoje zgody i oświadczenia'}
      </Typo>
      {isClientChild && (
        <Unorderedlist marginBottom="L">
          <ListItem>
            <MarginsWrapper marginBottom="S">
              <Typo noMargin $type="body">
                W imieniu: &nbsp;
                <Typo $type="bodyBold" inline>{client.clientName}</Typo>
              </Typo>
            </MarginsWrapper>
          </ListItem>
          <ListItem>
            <MarginsWrapper marginBottom="S">
              <Typo noMargin $type="body">
                Data urodzenia:&nbsp;
                <Typo $type="bodyBold" inline>{dateFormat(childClient.childBirthDate)}</Typo>
              </Typo>
            </MarginsWrapper>
          </ListItem>
        </Unorderedlist>
      )}
      <Typo $type="body">
        Zgody oznaczone
        {' '}
        <Typo $type="body" $color="red" inline>*</Typo>
        {' '}
        są wymagane.
      </Typo>
    </>
  );
};
