import { useEffect, useState } from 'react';

export function useScreenScroll(
  topCondition?: string | number | boolean,
  bottomCondition?: string | number | boolean,
): void {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setPrevTopCondition] = useState<string | number | boolean | undefined>();

  useEffect((): void => {
    setPrevTopCondition((prevTopCondition) => {
      // trick #1 dont put scrolled value to deps of useEffect
      if (topCondition === undefined || prevTopCondition !== topCondition) {
        window.scrollTo({ top: 0 });
      } else if (bottomCondition) {
        window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
      }

      return topCondition === undefined ? true : topCondition;
    });
  }, [bottomCondition, topCondition]);
}
