import React, { useMemo, useContext, useCallback, useState } from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from '@agent-zone/ui-library/esm/Button';
import { Checkbox } from '@agent-zone/ui-library/esm/Checkbox';
import {
  ContentWrapper,
  LineSeparator,
} from '../../../../../../ui/LayoutElements/LayoutElements.styled';
import { Fader } from '../../../../../../ui/Fader/Fader';
import { ConsentsList } from './components/ConsentsList/ConsentsList';
import { ButtonsWrapper } from '../../../../../../ui/Button/ButtonsWrapper';
import { ConsentPersonalizedMsg } from './components/ConstentsPersonalizedMsg/ConsentsPersonalizedMsg';
import { ErrorContext } from '../../../../../Errors/ErrorsContextProvider';
import { ErrorBox } from '../../../../../Errors/ErrorBox';
import { ConsentsBankTransferSelect } from './components/ConstentsPersonalizedMsg/ConsentsBankTransferSelect';
import { animateButtonsWrapper } from '../Photo/components/PhotoSection/components/PhotoButtons';
import { useSubmit } from '../Photo/hooks/useSubmit';
import { useConsents } from './hooks/useConsents';
import { useScreenScroll } from '../../../../../../hooks/useScreenScroll';

const AnimatedWrapper = styled.div`
  width: 100%;
  animation: ${animateButtonsWrapper} 500ms forwards;
`;

interface Props {
  onFinish: (index: string, consents: K2Paperless.ConsentWithAccept[]) => void;
  onFinishAll: () => void;
  mainClient: K2Paperless.MainClientWithAccept;
  clients?: K2Paperless.ClientWithAccept[];
  setConsents: (changedClientIndex: string, consents: K2Paperless.ConsentWithAccept[]) => void;
  onPrevStep: () => void;
  transferFromOwnedBankAccount: boolean | null;
  setTransferFromOwnedBankAccount: (_: boolean) => void;
  authenticationMethod: K2Paperless.AuthenticationMethod;
  isSingleProposal?: boolean;
  documentType: K2Paperless.DocumentType;
  additionalDocumentRequired: boolean;
}

function getClient(
  index: string,
  mainClient: K2Paperless.MainClientWithAccept,
  clients?: K2Paperless.ClientWithAccept[],
): K2Paperless.MainClientWithAccept | K2Paperless.ClientWithAccept {
  if (!!clients && !Number.isNaN(+index)) {
    return clients[+index];
  }

  return mainClient;
}

function isMainClient(index: string): boolean {
  return Number.isNaN(+index);
}

export function doesNextClientExist(
  clients: K2Paperless.Client[] | undefined,
  index: string,
): boolean {
  if (clients === undefined || clients.length === 0) {
    return false;
  }
  return (isMainClient(index) || (!isMainClient(index) && !!(clients[+index + 1])));
}

export function doesPrevClientExist(
  clients: K2Paperless.Client[] | undefined,
  index: string,
  isMainClientJustGuardian: boolean,
): boolean {
  return !(clients === undefined
    || clients.length === 0
    || isMainClient(index)
    || isMainClientJustGuardian);
}

export const Consents = ({
  onFinish,
  mainClient,
  clients,
  setConsents,
  onFinishAll,
  onPrevStep,
  transferFromOwnedBankAccount,
  setTransferFromOwnedBankAccount,
  isSingleProposal,
  documentType,
  additionalDocumentRequired,
}: Props): JSX.Element => {
  const [submitting, setSubmitting] = useState(false);
  const { cleanError, setValidationError, validationError } = useContext(ErrorContext);
  const { index } = useParams<{ index: string }>();
  const history = useHistory();
  const isMainClientJustGuardian = mainClient.consents.length === 0 && !!clients;
  const isClientChild = index !== 'main';
  const client = getClient(index, mainClient, clients);
  const isLastStep = !doesNextClientExist(clients, index) && !mainClient.documentRequired;
  const prevClientExist = doesPrevClientExist(clients, index, isMainClientJustGuardian);
  const isTransferFromOwnedBankAccountChosen = !(mainClient.transferFromOwnedBankAccountRequired
    && transferFromOwnedBankAccount === null);

  const nextClientName = useMemo((): string => {
    if (clients && doesNextClientExist(clients, index)) {
      return isMainClient(index) ? clients[0].clientName : clients[+index + 1].clientName;
    }
    return '';
  }, [clients, index]);

  const {
    toggleSingleConsent,
    checkAllConsents,
    uncheckAllConsents,
  } = useConsents(index, client.consents, setConsents);

  const areAllConsentsChecked = useMemo((): boolean => client.consents
    .every((consent) => consent.accepted), [client.consents]);

  const bottomScrollCondition = [
    areAllConsentsChecked,
    areAllConsentsChecked // prev condition must be true
      && mainClient.transferFromOwnedBankAccountRequired
      && !isClientChild
      && transferFromOwnedBankAccount !== null,
  ]
    .filter((x) => x).length;
  useScreenScroll(index, bottomScrollCondition);

  const onSubmitAndFinish = useSubmit(
    setSubmitting,
    submitting,
    onFinishAll,
  );

  const onFinishHandler = useCallback((): void => {
    if (areAllConsentsChecked && isTransferFromOwnedBankAccountChosen) {
      cleanError();
      if (isLastStep) {
        onSubmitAndFinish({ clients, mainClient, transferFromOwnedBankAccount });
      } else {
        onFinish(index, client.consents);
      }
    } else if (!isTransferFromOwnedBankAccountChosen) {
      setValidationError({ messageKey: 'TRANSFER-BANK-ACCOUNT-NOT-CHOSEN' });
    } else {
      setValidationError({ messageKey: 'CONSENTS-NOT-ACCEPTED' });
    }
  }, [areAllConsentsChecked,
    cleanError,
    client.consents,
    clients,
    index, isLastStep,
    isTransferFromOwnedBankAccountChosen,
    mainClient,
    onFinish,
    onSubmitAndFinish,
    setValidationError,
    transferFromOwnedBankAccount,
  ]);

  const onChangeTransferFromOwnedBankAccount = (value: boolean): void => {
    setTransferFromOwnedBankAccount(value);
    cleanError();
  };

  return (
    <Fader>
      <ContentWrapper>
        <ConsentPersonalizedMsg
          processType={mainClient.processType}
          isClientChild={isClientChild}
          client={client}
          mainClient={mainClient}
          isMainClientJustGuardian={isMainClientJustGuardian}
          contractNumber={mainClient.contractNumber}
          signDate={mainClient.signDate}
          contractDescription={mainClient.contractDescription}
          isSingleProposal={isSingleProposal}
        />
        <Checkbox
          value={areAllConsentsChecked}
          label="Zaznacz wszystkie"
          onChange={
            areAllConsentsChecked
              ? uncheckAllConsents
              : checkAllConsents
          }
        />
        <ConsentsList
          consents={client.consents}
          toggleSingleConsent={toggleSingleConsent}
        />
        <LineSeparator />
        <ConsentsBankTransferSelect
          additionalDocumentRequired={additionalDocumentRequired}
          transferFromOwnedBankAccountRequired={mainClient.transferFromOwnedBankAccountRequired}
          transferFromOwnedBankAccount={transferFromOwnedBankAccount}
          setTransferFromOwnedBankAccount={onChangeTransferFromOwnedBankAccount}
          doesNextClientExist={clients && doesNextClientExist(clients, index)}
          isLastStep={isLastStep}
          nextClientName={nextClientName}
          isClientChild={isClientChild}
          documentType={documentType}
        />
        <ErrorBox />
      </ContentWrapper>
      <AnimatedWrapper>
        <ButtonsWrapper>
          {!isSingleProposal && !prevClientExist && (
            <Button
              $type="secondary"
              onClick={(): void => history.push('/proposals')}
            >
              Wróć
            </Button>
          )}
          {prevClientExist && (
            <Button
              $type="secondary"
              onClick={onPrevStep}
            >
              Wróć
            </Button>
          )}
          {isLastStep
            ? (
              <>
                <span />
                <Button
                  $type="primary"
                  onClick={onFinishHandler}
                  loading={submitting}
                  disabled={!!validationError}
                >
                  Wyślij
                </Button>
              </>
            ) : (
              <Button
                $type="primary"
                onClick={onFinishHandler}
                disabled={!!validationError}
              >
                Przejdź dalej
              </Button>
            )}
        </ButtonsWrapper>
      </AnimatedWrapper>
    </Fader>
  );
};
