import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';


export const RouteChangeListener = (): JSX.Element => {
  const location = useLocation();

  useEffect(() => {
    const dataLayer = (window as any).dataLayer || [];
    dataLayer.push({ event: 'pageviewCustomEvent', pagePath: location.pathname.replace(/\d/g, '') });
  }, [location.pathname]);

  return <></>;
};
