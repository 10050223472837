import React from 'react';
import styled from 'styled-components';
import { useInView } from 'react-intersection-observer';

const Guard = styled.div`
  height: 1px;
  width: 100%;
`;

const StyledGradientBottom = styled.div<StyledGradientBottomProps>`
  height:30px;
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 1;
  opacity: ${({ showGradient }): number => (showGradient ? 1 : 0)};
  background: linear-gradient(180deg, rgba(255,255,255,0), #fff);
  pointer-events: none;
  transition: opacity 150ms;
`;

interface StyledGradientBottomProps {
  showGradient: boolean;
}

export const GradientBottom = (): JSX.Element => {
  const { ref, inView } = useInView();

  return (
    <>
      <StyledGradientBottom showGradient={!inView} />
      <Guard ref={ref} />
    </>
  );
};
