import React from 'react';
import styled, { CSSProp, css } from 'styled-components';
import { Pictogram } from '@agent-zone/ui-library/esm/Pictogram';
import { Elevation } from '@agent-zone/ui-library/esm/Elevation';
import { Typo } from '@agent-zone/ui-library/esm/Typo';
import { Grid } from '@agent-zone/ui-library/esm/Grid';
import { MarginsWrapper } from '@agent-zone/ui-library/esm/Margins';
import { Button } from '@agent-zone/ui-library/esm/Button';
import { nnColors } from '@agent-zone/ui-library/esm/colors';
import { RoundedPictogram } from '@agent-zone/ui-library/esm/RoundedPictogram';
import { IndicatorBadge } from '@agent-zone/ui-library/esm/IndicatorBadge';

const StyledButton = styled.button`
  border: none;
  background: transparent;
  position: relative;
  cursor: pointer;
  width: 100%;
  padding: 0;
`;

const PhotoElevation = styled(Elevation)`
  height: 284px;
  min-width: 300px;
  width: 100%;
  display: flex;
`;

const PhotoContentWrapper = styled(Grid) <{ dropzone: boolean; draged?: boolean }>`
  margin: 20px;
  box-sizing: border-box;
  ${({ dropzone, draged }): CSSProp => (dropzone
    ? css`border: 1px dashed ${draged ? nnColors.orangeMedium : nnColors.greyLight};`
    : '')}
  ${({ draged }): CSSProp => (draged ? css`background: #FFF7EE;` : '')}
`;

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  icon: any;
  draged?: boolean;
}

export const PhotoButton = ({ text, icon, draged, ...props }: Props): JSX.Element => (
  <StyledButton {...props}>
    <PhotoElevation color={props.disabled ? 'offWhite' : undefined} shadowSize="L">
      <PhotoContentWrapper draged={draged} dropzone={!!props.children} align center vertical>
        {props.children ? (
          <IndicatorBadge
            position="bottom"
            badge={(
              <RoundedPictogram
                background={props.disabled ? 'white' : 'orangeMedium'}
                icon="upload"
                iconColor={props.disabled ? 'greyLight' : 'white'}
                iconSize="l"
              />
            )}
          >
            <Pictogram iconColor={draged ? 'orangeMedium' : 'greyLight'} iconSize="xxxl" icon={icon} />
          </IndicatorBadge>
        ) : <Pictogram iconColor={draged ? 'orangeMedium' : 'greyLight'} iconSize="xxxl" icon={icon} />}
        <MarginsWrapper marginTop="M">
          <Typo
            noMargin
            $type={draged ? 'bodyBold' : 'body'}
            $color={draged ? 'orangeMedium' : 'greyDark'}
          >
            {text}
          </Typo>
        </MarginsWrapper>
        <MarginsWrapper marginTop="M">
          <Button disabled={props.disabled} $size="m" $type="primary">
            {props.children ? 'Dodaj plik' : 'Zrób zdjęcie'}
          </Button>
        </MarginsWrapper>
      </PhotoContentWrapper>
    </PhotoElevation>
    <Grid>
      {props.children}
    </Grid>
  </StyledButton>
);
