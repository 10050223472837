import React, { useEffect, ReactNode, useState, useCallback } from 'react';
import Axios from 'axios';
import AxiosMockAdapter from 'axios-mock-adapter';

let mock: AxiosMockAdapter | undefined;

const CONSENTS_MOCK1: K2Paperless.Consent[] = [{
  code: 'E_COMBO_1',
  description: 'Wyrażam zgodę na przeprowadzenie procesu analizy potrzeb oraz sprzedaży ubezpieczenia drogą elektroniczną (telefonicznie, drogą e-mailową, przez Internet).',
  id: 1,
}, {
  code: 'E_COMBO_2',
  description: 'Przed zawarciem umowy ubezpieczenia zostałem/łam poinformowany/a, czy zaproponowany mi produkt podlega specjalnym praktykom sprzedażowym (promocje, konkursy, premie).',
  id: 2,
}, {
  code: 'E_COMBO_3',
  description: 'Potwierdzam, że w dniu 2023-08-01 odbyłem/łam zdalne wideo spotkanie z Przedstawicielem Nationale-Nederlanden, podczas którego Przedstawiciel zweryfikował moją tożsamość oraz uzupełniłem/łam elektroniczne dokumenty niezbędne do zawarcia umowy ubezpieczenia. Potwierdzam, że podałem/łam prawidłowe dane identyfikacyjne zgodnie z dokumentem tożsamości oraz, że wniosek o numerze ID 2333352, który otrzymałem/łam e-mailem zawiera zgody i oświadczenia zgodnie z moją wolą.',
  id: 3,
}, {
  code: 'E_COMBO_6',
  description: 'Potwierdzam, że otrzymałem/łam dokument Podsumowanie spotkania dotyczącego Analizy potrzeb osobistych w zakresie ubezpieczeń oraz Rekomendację produktową w formie elektronicznej.',
  id: 4,
}, {
  code: 'LOP001',
  description: 'Wyrażam zgodę na występowanie przez Nationale-Nederlanden Towarzystwo Ubezpieczeń na Życie S.A., z siedzibą w Warszawie przy ul. Topiel 12, do podmiotów wykonujących działalność leczniczą, które udzielały świadczeń zdrowotnych, o udzielenie informacji o okolicznościach związanych z oceną ryzyka ubezpieczeniowego i weryfikację podanych danych o stanie zdrowia, w celu ustalenia prawa do świadczenia z zawartej umowy ubezpieczenia i wysokości tego świadczenia, zarówno za mojego życia, jak i po mojej śmierci, z wyłączeniem wyników badań genetycznych. Wyrażona zgoda obejmuje także udostępnianie kopii dokumentacji medycznej dotyczącej mojego stanu zdrowia. Z żądaniem przekazania powyższych informacji występuje lekarz upoważniony przez Nationale-Nederlanden Towarzystwo Ubezpieczeń na Życie S.A., z siedzibą w Warszawie przy ul. Topiel 12.',
  id: 5,
}, {
  code: 'LON001',
  description: 'Wyrażam zgodę na występowanie przez Nationale-Nederlanden Towarzystwo Ubezpieczeń na Życie S.A., z siedzibą w Warszawie przy ul. Topiel 12, do Narodowego Funduszu Zdrowia o przekazanie danych o nazwach i adresach świadczeniodawców, którzy udzielili świadczeń opieki zdrowotnej w związku z wypadkiem lub zdarzeniem losowym, będącym podstawą ustalenia odpowiedzialności Nationale-Nederlanden Towarzystwa Ubezpieczeń na Życie S.A., z siedzibą w Warszawie przy ul. Topiel 12, oraz wysokości świadczenia ubezpieczeniowego.',
  id: 6,
}];

const CONSENTS_MOCK2: K2Paperless.Consent[] = [{
  code: 'E_COMBO_2',
  description: 'Przed zawarciem umowy ubezpieczenia zostałem/łam poinformowany/a, czy zaproponowany mi produkt podlega specjalnym praktykom sprzedażowym (promocje, konkursy, premie).',
  id: 2,
}, {
  code: 'E_COMBO_4',
  description: 'Potwierdzam, że w dniu 2023-08-01 odbyłem/łam zdalne wideo spotkanie z Przedstawicielem Nationale-Nederlanden, podczas którego Przedstawiciel zweryfikował moją tożsamość oraz uzupełniłem/łam elektroniczne dokumenty niezbędne do zawarcia umowy ubezpieczenia. Potwierdzam, że  podałem/łam prawidłowe dane identyfikacyjne zgodnie z dokumentem tożsamości oraz, że wniosek o numerze ID 2333352, który Właściciel polisy otrzymał e-mailem zawiera zgody i oświadczenia zgodnie z moją wolą, a w szczególności potwierdzam, że wyrażam zgodę na objęcie mnie ochroną  ubezpieczeniową w ramach Umowy na warunkach w nim określonych.',
  id: 3,
},
{
  code: 'E_COMBO_6',
  description: 'Potwierdzam, że otrzymałem/łam dokument Podsumowanie spotkania dotyczącego Analizy potrzeb osobistych w zakresie ubezpieczeń oraz Rekomendację produktową w formie elektronicznej.',
  id: 4,
}, {
  code: 'E_COMBO_5',
  description: 'Oświadczam, że Właściciel polisy przekazał mi ogólne warunki ubezpieczenia, które mają mieć zastosowanie do zawieranej przez Właściciela polisy umowy Ubezpieczenia.',
  id: 5,
},
{
  code: 'LOP001',
  description: 'Wyrażam zgodę na występowanie przez Nationale-Nederlanden Towarzystwo Ubezpieczeń na Życie S.A., z siedzibą w Warszawie przy ul. Topiel 12, do podmiotów wykonujących działalność leczniczą, które udzielały świadczeń zdrowotnych, o udzielenie informacji o okolicznościach związanych z oceną ryzyka ubezpieczeniowego i weryfikację podanych danych o stanie zdrowia, w celu ustalenia prawa do świadczenia z zawartej umowy ubezpieczenia i wysokości tego świadczenia, zarówno za mojego życia, jak i po mojej śmierci, z wyłączeniem wyników badań genetycznych. Wyrażona zgoda obejmuje także udostępnianie kopii dokumentacji medycznej dotyczącej mojego stanu zdrowia. Z żądaniem przekazania powyższych informacji występuje lekarz upoważniony przez Nationale-Nederlanden Towarzystwo Ubezpieczeń na Życie S.A., z siedzibą w Warszawie przy ul. Topiel 12.',
  id: 6,
}, {
  code: 'LON001',
  description: 'Wyrażam zgodę na występowanie przez Nationale-Nederlanden Towarzystwo Ubezpieczeń na Życie S.A., z siedzibą w Warszawie przy ul. Topiel 12, do Narodowego Funduszu Zdrowia o przekazanie danych o nazwach i adresach świadczeniodawców, którzy udzielili świadczeń opieki zdrowotnej w związku z wypadkiem lub zdarzeniem losowym, będącym podstawą ustalenia odpowiedzialności Nationale-Nederlanden Towarzystwa Ubezpieczeń na Życie S.A., z siedzibą w Warszawie przy ul. Topiel 12, oraz wysokości świadczenia ubezpieczeniowego.',
  id: 7,
}];

const CONSENTS_MOCK3: K2Paperless.Consent[] = [{
  code: 'E_COMBO_2',
  description: 'Przed zawarciem umowy ubezpieczenia zostałem/łam poinformowany/a, czy zaproponowany mi produkt podlega specjalnym praktykom sprzedażowym (promocje, konkursy, premie).',
  id: 1,
}, {
  code: 'E_COMBO_3',
  description: 'Potwierdzam, że w dniu 2023-08-01 odbyłem/łam zdalne wideo spotkanie z Przedstawicielem Nationale-Nederlanden, podczas którego Przedstawiciel zweryfikował moją tożsamość oraz uzupełniłem/łam elektroniczne dokumenty niezbędne do zawarcia umowy ubezpieczenia. Potwierdzam, że podałem/łam prawidłowe dane identyfikacyjne zgodnie z dokumentem tożsamości oraz, że wniosek o numerze ID 2333353, który otrzymałem/łam e-mailem zawiera zgody i oświadczenia zgodnie z moją wolą.',
  id: 3,
}, {
  code: 'LOP001',
  description: 'Wyrażam zgodę na występowanie przez Nationale-Nederlanden Towarzystwo Ubezpieczeń na Życie S.A., z siedzibą w Warszawie przy ul. Topiel 12, do podmiotów wykonujących działalność leczniczą, które udzielały świadczeń zdrowotnych, o udzielenie informacji o okolicznościach związanych z oceną ryzyka ubezpieczeniowego i weryfikację podanych danych o stanie zdrowia, w celu ustalenia prawa do świadczenia z zawartej umowy ubezpieczenia i wysokości tego świadczenia, zarówno za mojego życia, jak i po mojej śmierci, z wyłączeniem wyników badań genetycznych. Wyrażona zgoda obejmuje także udostępnianie kopii dokumentacji medycznej dotyczącej mojego stanu zdrowia. Z żądaniem przekazania powyższych informacji występuje lekarz upoważniony przez Nationale-Nederlanden Towarzystwo Ubezpieczeń na Życie S.A., z siedzibą w Warszawie przy ul. Topiel 12.',
  id: 5,
}, {
  code: 'LON001',
  description: 'Wyrażam zgodę na występowanie przez Nationale-Nederlanden Towarzystwo Ubezpieczeń na Życie S.A., z siedzibą w Warszawie przy ul. Topiel 12, do Narodowego Funduszu Zdrowia o przekazanie danych o nazwach i adresach świadczeniodawców, którzy udzielili świadczeń opieki zdrowotnej w związku z wypadkiem lub zdarzeniem losowym, będącym podstawą ustalenia odpowiedzialności Nationale-Nederlanden Towarzystwa Ubezpieczeń na Życie S.A., z siedzibą w Warszawie przy ul. Topiel 12, oraz wysokości świadczenia ubezpieczeniowego.',
  id: 6,
}];

const CLIENT: K2Paperless.Client = {
  clientName: 'Janina Test',
  clientId: 2,
  clientRoles: ['INSURED'],
  consents: CONSENTS_MOCK2,
  childBirthDate: '1997-10-22',
};

const PROPOSALS_MOCK: K2Response.Proposals = {
  firstName: 'Grzegorz',
  proposals: [
    {
      id: 1,
      clientId: 1,
      contractDescription: 'Ochrona + (TMR4)',
      signDate: '2023-08-01',
      status: 'IN_PROGRESS',
      contractNumber: '02610400',
      productType: '(TMR4)',
      processType: 'NBU',
    },
    {
      id: 2,
      clientId: 2,
      signDate: '2023-08-01',
      status: 'IN_PROGRESS',
      productType: '(TMR5)',
      processType: 'PS',
      contractDescription: 'Ochrona Jutra (TUL1)',
      contractNumber: '02503901',
    },
    {
      id: 2,
      clientId: 3,
      contractDescription: 'Ochrona Jutra (TUL1)',
      signDate: '2023-08-01',
      status: 'CONSENT_SENT',
      contractNumber: '02610382',
      productType: '(TUL1)',
      processType: 'NBU',
    },
  ],
};

const PROPOSAL_AFTER_SEND = (demoType: 'PS' | 'NBU', isFormSubmitted: boolean): K2Response.Proposals => ({
  firstName: 'Grzegorz',
  proposals: [
    {
      ...PROPOSALS_MOCK.proposals[0],
      status: (demoType === 'NBU' && isFormSubmitted) ? 'CONSENT_SENT' : 'IN_PROGRESS',
    },
    {
      ...PROPOSALS_MOCK.proposals[1],
      status: (demoType === 'PS' && isFormSubmitted) ? 'CONSENT_SENT' : 'IN_PROGRESS',
    },
    PROPOSALS_MOCK.proposals[2],
  ],
});

export const ADDITIONAL_DOCUMENTS: K2Paperless.AdditionalDocument[] = [
  {
    id: 'PASZPORT',
    label: 'paszport',
    description: 'Załącz zdjęcie tej strony paszportu, na której znajduje się Twoje zdjęcie oraz dane osobowe. Zwróć uwagę, czy dokument jest ważny.',
    pages: 1,
  },
  {
    id: 'PRAWO_JAZDY',
    label: 'prawo jazdy',
    description: 'Załącz zdjęcia pierwszej i drugiej strony swojego prawa jazdy. Zwróć uwagę, czy dokument jest ważny.',
    pages: 2,
  },
  {
    id: 'BILET_MIESIECZNY',
    label: 'bilet miesięczny',
    description: 'Załącz zdjęcie biletu długookresowego na przejazdy autobusem, pociągiem lub innymi środkami komunikacji. Chodzi np. o bilet miesięczny, kwartalny lub roczny. Prześlij zdjęcie tej strony dokumentu, na której znajduje się Twoja fotografia oraz imię i nazwisko.  Zwróć uwagę, czy dokument jest ważny.',
    pages: 2,
  },
  {
    id: 'LEGITYMACJA_EMERYTA',
    label: 'legitymacja rencisty/emeryta',
    description: 'Załącz zdjęcia pierwszej i drugiej strony dokumentu. Zwróć uwagę, czy dokument jest ważny.',
    pages: 2,
  },
  {
    id: 'RACHUNEK',
    label: 'rachunek za media z ostatnich 3 miesięcy',
    description: 'Załącz zdjęcie swojego rachunku za stałe opłaty - np. za prąd, gaz, internet. Rachunek nie może być starszy niż sprzed 3 miesięcy. Na zdjęciu dokumentu powinny być widoczne Twoje imię i nazwisko oraz Twój adres zamieszkania.',
    pages: 1,
  },
  {
    id: 'KARTA_ZOLNIEZA',
    label: 'żołnierska karta tożsamości',
    description: 'Załącz zdjęcia pierwszej i drugiej strony dokumentu wojskowego, który potwierdza Twoją tożsamość. Chodzi o dokument, który otrzymują czynni żołnierze, kiedy wyjeżdżają służbowo za granicę. Na zdjęciach powinny być widoczne Twoje zdjęcie i dane personalne. Zwróć uwagę, czy dokument jest ważny.',
    pages: 1,
  },
  {
    id: 'KSIAZECZKA_ZEGLARSKA',
    label: 'książeczka żeglarska',
    description: 'Załącz zdjęcia pierwszej i drugiej strony dokumentu osobistego marynarza. Chodzi o dokument, który dokumentuje przebieg zatrudnienia na statkach oraz uprawnia do przekraczania granicy Rzeczypospolitej Polskiej. Na zdjęciach powinny być widoczne Twoje zdjęcie oraz dane personalne. Zwróć uwagę, czy dokument jest ważny.',
    pages: 1,
  },
  {
    id: 'LEGITYMACJA_STUDENCKA',
    label: 'legitymacja studencka/karta biblioteczna',
    description: 'Załącz zdjęcie pierwszej strony dokumentu. Zwróć uwagę, czy dokument jest ważny.',
    pages: 1,
  },
  {
    id: 'KARTA_GRUPY_KRWI',
    label: 'karta identyfikacyjna grupy krwi (KIGK)',
    description: 'Załącz zdjęcia pierwszej i drugiej strony dokumentu. Zwróć uwagę, czy dokument jest ważny.',
    pages: 2,
  },
  {
    id: 'KSIAZECZKA_WOJSKOWA',
    label: 'książeczka wojskowa',
    pages: 2,
    description: 'Załącz zdjęcie książeczki wojskowej (tylko w przypadku, gdy jesteś w czasie czynnej służby wojskowej lub zostałeś przeniesiony do rezerwy). Prześlij zdjęcie tej strony dokumentu, na której znajduje się Twoja fotografia oraz imię i nazwisko oraz stronę dokumentu, na której umieszczony jest stosunek do czynnej służby wojskowej.',
  },
  {
    id: 'LEGITYMACJA_DAWCY_KRWI',
    label: 'legitymacja honorowego dawcy krwi',
    pages: 2,
    description: 'Załącz zdjęcia pierwszej i drugiej strony dokumentu. Zwróć uwagę, czy dokument jest ważny.',
  },
  {
    id: 'LEGITYMACJA_SLUZBOWA',
    label: 'legitymacja służbowa',
    pages: 1,
    description: 'Załącz zdjęcie pierwszej i drugiej strony dokumentu. Zwróć uwagę, czy dokument jest ważny.',
  },
  {
    id: 'LEGITYMACJA_CZLONKOWSKA',
    label: 'legitymacja członkowska',
    pages: 1,
    description: 'Załącz zdjęcie pierwszej i drugiej strony dokumentu. Zwróć uwagę, czy dokument jest ważny.',
  },
  {
    id: 'PRAWO_WYKONYWANIA_ZAWODU',
    label: 'dokument potwierdzający prawo wykonywania zawodu',
    pages: 1,
    description: 'Załącz zdjęcie pierwszej i drugiej strony dokumentu. Zwróć uwagę, czy dokument jest ważny.',
  },
];

export const PROPOSAL_MOCK: K2Response.Proposal = {
  clients: [CLIENT],
  clientName: 'Grzegorz Testowy',
  clientId: 1,
  documentType: 'DO',
  consents: CONSENTS_MOCK1,
  clientRoles: [],
  salesType: 'REMOTE_CHANNEL',
  contractDescription: 'Ochrona + (TMR4)',
  signDate: '2023-08-01',
  contractNumber: '02610400',
  productType: '(TMR4)',
  transferFromOwnedBankAccountRequired: false,
  additionalDocuments: ADDITIONAL_DOCUMENTS,
  authenticationMethod: 'PIN',
  additionalDocumentRequired: true,
  processType: 'NBU',
  documentRequired: true,
  requiredDocumentsByOtherChannelAvailable: true,
};

export const PROPOSAL_MOCK2: K2Response.Proposal = {
  clients: [],
  clientName: 'Adam Testowy',
  clientId: 2,
  documentType: 'DO',
  consents: CONSENTS_MOCK3,
  clientRoles: [],
  salesType: 'REMOTE_CHANNEL',
  contractDescription: 'Ochrona Jutra (TUL1)',
  signDate: '2023-08-01',
  contractNumber: '02503901',
  productType: '(TUL1)',
  transferFromOwnedBankAccountRequired: false,
  additionalDocuments: ADDITIONAL_DOCUMENTS,
  authenticationMethod: 'PIN',
  additionalDocumentRequired: false,
  processType: 'PS',
  documentRequired: true,
  requiredDocumentsByOtherChannelAvailable: true,
};

const AUTH_MOCK: K2Response.Authentication = 'token123';

interface Props {
  children: (
    isDemo: boolean,
    onCancelDemo: () => void,
    setDemoType: (_: 'PS' | 'NBU') => void) => ReactNode;
}

export function DemoInterceptor({ children }: Props): JSX.Element {
  const [isDemo, setIsDemo] = useState(false);
  const [demoType, setDemoType] = useState<'PS' | 'NBU'>('NBU');
  const onCancelDemo = useCallback(() => setIsDemo(false), []);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  useEffect(() => {
    // bind
    const bindId = Axios.interceptors.request.use((request) => {
      if (request.url && request.url?.includes('config')) {
        if (request.data && request.data.pin === '000000') {
          setIsDemo(true);
        } else {
          setIsDemo(false);
        }
      }
      return request;
    });

    // unbind
    return (): void => {
      Axios.interceptors.request.eject(bindId);
    };
  });

  const mocks = useCallback((proposalMock: K2Response.Proposal) => {
    mock = new AxiosMockAdapter(Axios);
    mock.onPost('/public/authentication').reply(200, AUTH_MOCK);
    mock.onGet('/direct/proposals').reply(200, PROPOSAL_AFTER_SEND(demoType, isFormSubmitted));
    mock.onGet(/\/direct\/proposals\/clients\/\d+/).reply(200, proposalMock);
    mock.onPost(/form/).reply(() => {
      setIsFormSubmitted(true);
      return [200, {}];
    });
  }, [demoType, isFormSubmitted]);

  useEffect(() => {
    if (isDemo) {
      if (mock) { mock.restore(); }
      mocks(demoType === 'PS' ? PROPOSAL_MOCK2 : PROPOSAL_MOCK);
    } else {
      if (mock) { mock.restore(); }
      mock = undefined;
      setIsFormSubmitted(false);
    }
  }, [demoType, isDemo, isFormSubmitted, mocks]);

  return <>{children(isDemo, onCancelDemo, setDemoType)}</>;
}
