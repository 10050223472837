import { Typo } from '@agent-zone/ui-library/esm/Typo';
import React, { useEffect, useRef, useState } from 'react';
import styled, { CSSProp } from 'styled-components';
import { useDimensions } from '../../../../../../../../../hooks/useDimensions';


const More = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  top: -8px;
  position: relative;
  width: 50px;
  margin-left: calc(100% - 50px);
  padding: 0;
  border: none;
  background: transparent;
  cursor: pointer;
  outline: none;
`;

const StyledTypo = styled(Typo)`
  font-size: 14px;
  @media screen and (min-width){
    font-size: 16px;
  }
`;
interface ExpandableConsentWrapperProps {
  height?: number;
}

const ExpandableConsentWrapper = styled.div<ExpandableConsentWrapperProps>`
  height: ${({ height }): CSSProp => (`${height}px`)};
  transition: height .5s ease;
  overflow: hidden;
`;

interface Props {
  description: string;
}

export const ExpandableConsent = ({ description }: Props): JSX.Element => {
  const [expand, setExpand] = useState<boolean>(false);
  const [descriptionHeight, setDescriptionHeight] = useState<number>(0);
  const consentRef = useRef<HTMLDivElement>(null);
  const { viewportWidth } = useDimensions();

  useEffect(() => {
    if (consentRef && consentRef.current) {
      setDescriptionHeight(consentRef.current.clientHeight);
    }
  }, [expand, viewportWidth]);

  const scrollToDescription = (): void => {
    if (consentRef && consentRef.current && expand) {
      consentRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <ExpandableConsentWrapper
      height={descriptionHeight}
      onTransitionEnd={(): void => scrollToDescription()}
    >
      <div ref={consentRef}>
        <Typo $type="body">
          <Typo $color="red" $type="body" inline>*</Typo>
          {' '}
          {expand ? description : `${description.substring(0, 315)} ...`}
        </Typo>
        <More
          onClick={(): void => setExpand(!expand)}
          type="button"
        >
          <StyledTypo $type="bodyBold" $color="orangeMedium" noMargin>{expand ? 'Mniej' : 'Więcej'}</StyledTypo>
        </More>
      </div>
    </ExpandableConsentWrapper>
  );
};
