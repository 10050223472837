import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Button } from '@agent-zone/ui-library/esm/Button';
import { IconLinkPlain } from '@agent-zone/ui-library/esm/IconLinkPlain';
import { Fader } from '../../../ui/Fader/Fader';
import funnyHamster from '../../../img/u988.gif';
import { DESKTOP, media } from '../../../breakpoints';

interface Props {
  children: ReactNode;
  buttonVariant?: string;
  buttonText?: string;
  linkToNNSiteText?: string;
}

const ImageWrapper = styled.div`
  width: calc(462 / 1216 * 100%);
  max-width: calc(462 / 1216 * 100%);
  min-width: calc(462 / 1216 * 100%);
`;

const ErrorContentWrapper = styled.div`
  flex-direction: row;
  display: flex;
  max-width: calc(100% - 30px);
  width: 1216px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 144px);

  @media(max-width: ${DESKTOP}px) {
    flex-direction: column;
    align-items: center;

    > ${ImageWrapper} {
      min-width: unset;
      max-width: 100%;
      width: 462px;
    }
  }
`;

const ButtonsWrapperInner = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 40px;
    margin-bottom: 24px;

    @media ${media.tablet} {
      margin-top: 60px;
    }
`;

export function ErrorMessageWrapper({ children, linkToNNSiteText, buttonText = 'Wróć' }: Props): JSX.Element {
  const onClick = (): void => {
    window.location.reload();
  };

  return (
    <Fader>
      <ErrorContentWrapper>
        <ImageWrapper>
          <img src={funnyHamster} alt="smieszny-chomik" />
        </ImageWrapper>
        <div>
          {children}
          <ButtonsWrapperInner>
            <Button $type="secondary" onClick={(): void => onClick()}>
              {buttonText}
            </Button>
            {linkToNNSiteText && (
              <IconLinkPlain
                href="https://www.nn.pl/"
                icon="arrow-right"
                target="_blank"
                text={linkToNNSiteText}
              />
            )}
          </ButtonsWrapperInner>
        </div>
      </ErrorContentWrapper>
    </Fader>
  );
}
