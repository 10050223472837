import { Typo } from '@agent-zone/ui-library/esm/Typo';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { ErrorMessageWrapper } from '../Errors/components/ErrorMessageWrapper';

export const WAFErrorPage = (): JSX.Element => {
  const { state: locationState }: {state: {wafId: string}} = useLocation();

  return (
    <ErrorMessageWrapper
      buttonText="Wróć"
    >
      <Typo $type="H1" $color="orangeMedium">
        Upss...
        {' '}
        coś poszło
        {' '}
        nie tak
      </Typo>
      <Typo $type="H2" $color="orangeMedium">
        Błąd 400
      </Typo>
      <Typo $type="body">
        Wygląda na to, że Twoje działanie nie jest zgodne
        z naszą polityką zasad bezpieczeństwa.
      </Typo>
      <Typo $type="body">Spróbuj ponownie później.</Typo>
      <Typo $type="body">
        Jeśli problem się powtarza skontaktuj się z przedstawicielem i podaj numer zgłoszenia:
        {' '}
        {`WAF${locationState && locationState.wafId}`}
      </Typo>
    </ErrorMessageWrapper>

  );
};
