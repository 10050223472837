import { Typo } from '@agent-zone/ui-library/esm/Typo';
import React from 'react';

const DOCUMENT_NAME = {
  DO: ' Twojego dowodu osobistego',
  PP: ' Twojego paszportu',
  KP: ' Twojej karty pobytu',
};

interface Props {
  doesNextClientExist?: boolean;
  isLastStep: boolean;
  nextClientName: string;
  additionalDocumentRequired: boolean;
  documentType: K2Paperless.DocumentType;
}

export const ConsentsEndInfo = ({
  doesNextClientExist,
  isLastStep,
  nextClientName,
  additionalDocumentRequired,
  documentType,
}: Props): JSX.Element => {
  if (doesNextClientExist) {
    return (
      <Typo $type="body">
        W kolejnym kroku poprosimy Ciebie o
        oznaczenie odpowiednich zgód i oświadczeń w imieniu
        {' '}
        {nextClientName}
        .
      </Typo>
    );
  }

  if (isLastStep) {
    return (
      <Typo $type="body">
        Kliknij  &quot;Wyślij&quot;, aby przesłać do nas powyższy formularz.
      </Typo>
    );
  }

  return (
    additionalDocumentRequired
      ? (
        <Typo $type="body">
          W kolejnym kroku poprosimy o dodanie zdjęć lub skanów
          {`${DOCUMENT_NAME[documentType]} `}
          oraz dodatkowego dokumentu potwierdzającego Twoją tożsamość.
        </Typo>
      )
      : (
        <Typo $type="body">
          W kolejnym kroku poprosimy o dodanie zdjęć lub skanów
          {DOCUMENT_NAME[documentType]}
          .
        </Typo>
      )
  );
};
