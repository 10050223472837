import React from 'react';
import { useParams } from 'react-router-dom';
import { Typo } from '@agent-zone/ui-library/esm/Typo';
import { ErrorMessageWrapper } from './components/ErrorMessageWrapper';

export const HttpErrorPage = (): JSX.Element => {
  const params = useParams<{status: string}>();
  const status = parseInt(params.status, 10);

  return (
    <ErrorMessageWrapper
      buttonText="Wróć"
    >
      <Typo $type="H1" $color="orangeMedium">
        Upss... coś poszło nie tak
      </Typo>
      <Typo $type="H2" $color="orangeMedium">
        Błąd
        {' '}
        {status}
      </Typo>
      {status >= 400 && status < 500
        && (
          <>
            <Typo $type="body">Ten zasób najprawdopodobniej nie istnieje...</Typo>
            <Typo $type="body">Wróć i zacznij od początku.</Typo>
          </>
        )}
      {status >= 500
        && (
          <>
            <Typo $type="body">Wygląda na to, że mamy chwilowo problemy techniczne...</Typo>
            <Typo $type="body">Spróbuj ponownie później.</Typo>
          </>
        )}
    </ErrorMessageWrapper>

  );
};
