import { MarginsWrapper } from '@agent-zone/ui-library/esm/Margins';
import { Typo } from '@agent-zone/ui-library/esm/Typo';
import React, { useCallback, useState } from 'react';
import { Switch, Route, useHistory, Redirect } from 'react-router-dom';
import { PINForm } from './components/PINForm/PINForm';
import { SocialSecurityNumberForm } from './components/SocialSecurityNumberForm/SocialSecurityNumberForm';

interface Props {
  onFinish: (token: string) => void;
  onGoBackToPin: () => void;
  isFloating?: boolean;
}

export function PINAuth({ onFinish, onGoBackToPin, isFloating }: Props): JSX.Element {
  const history = useHistory();

  const [pinNumber, setPinNumber] = useState<(string | undefined)[]>();
  const [
    socialSecurityNumberIndexes, setSocialSecurityNumberIndexes,
  ] = useState<number[] | undefined>();
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [captchaKey, setCaptchaKey] = useState<string | null>(null);
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);

  const onFinishPIN = useCallback(
    (newPinNumber: (string | undefined)[],
      newIndexes: number[],
      newShowCaptcha: boolean,
      newCaptchaKey: string | null) => {
      setPinNumber(newPinNumber);
      setSocialSecurityNumberIndexes(newIndexes);
      setShowCaptcha(newShowCaptcha);
      setCaptchaKey(newCaptchaKey);
      history.push('/auth/social-security-numbers');
    }, [history],
  );

  return (
    <Switch>
      <Route path="/auth" exact>
        <Typo $type="H3">
          Potwierdź tożsamość za pomocą kodu PIN
        </Typo>
        <Typo $type="body">
          Kod PIN został wysłany na Twój numer telefonu.
          Kod nie dotarł?
          Sprawdź, czy jesteś w zasięgu sieci komórkowej
          lub skontaktuj się ze swoim doradcą.
        </Typo>
        <MarginsWrapper marginTop="LXL">
          <PINForm isFloating={isFloating} onFinish={onFinishPIN} />
        </MarginsWrapper>
      </Route>
      {
        socialSecurityNumberIndexes && pinNumber && (
          <Route exact path="/auth/social-security-numbers">
            <Typo $type="H1" $color="orangeMedium">
              Uzupełnij swój PESEL
            </Typo>
            <Typo $type="body">
              Aby przejść dalej uzupełnij swój PESEL.
            </Typo>
            <SocialSecurityNumberForm
              socialSecurityNumberIndexes={socialSecurityNumberIndexes}
              pinNumber={pinNumber}
              captchaKey={captchaKey}
              showCaptcha={showCaptcha}
              captchaToken={captchaToken}
              setCaptchaToken={setCaptchaToken}
              onFinish={onFinish}
              onGoBackToPin={onGoBackToPin}
            />
          </Route>
        )
      }
      <Redirect to="/auth" />
    </Switch>
  );
}
