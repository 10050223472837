import { Spacer } from '@agent-zone/ui-library/esm/Margins';
import React from 'react';
import styled, { keyframes, Keyframes } from 'styled-components';
import { TABLET } from '../../../../../../../../../breakpoints';
import { PhotoButton } from '../../../../../../../../../ui/Button/PhotoButton';
import { acceptExtensions } from '../../../hooks/useInstantValidation';

export const animateButtonsWrapper = (): Keyframes => keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const PhotoButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  animation: ${animateButtonsWrapper} 500ms;
  @media screen and (max-width: ${TABLET}px) {
    flex-direction: column;
  }
`;

export const HiddenFileInput = styled.input`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
`;

interface Props {
  isCaptureAvailable: boolean;
  onTryToStartCapture: () => void;
  onAddPhotoFromFileEvent: (event: React.ChangeEvent<HTMLInputElement>) => void;
  skipUpload: boolean;
  disabled: boolean;
  onDrag: boolean;
}

export function PhotoButtons({
  isCaptureAvailable,
  onTryToStartCapture,
  onAddPhotoFromFileEvent,
  skipUpload,
  disabled,
  onDrag,
}: Props): JSX.Element {
  return (
    <PhotoButtonsWrapper>
      {(!onDrag || skipUpload) && (
        <>
          {isCaptureAvailable ? (
            <>
              <PhotoButton
                disabled={skipUpload || disabled}
                text="Zrób zdjęcie dokumentu"
                onClick={onTryToStartCapture}
                icon="room-pic-damages"
                type="button"
              />
              <Spacer size="L" />
            </>
          ) : (
            <>
              <PhotoButton
                disabled={skipUpload || disabled}
                text="Zrób zwykłe zdjęcie dokumentu"
                icon="id-document-front"
                type="button"
              >
                <HiddenFileInput
                  disabled={skipUpload || disabled}
                  type="file"
                  accept={`image/${acceptExtensions.replace(/\.(\w+)/g, 'image/$1')}`}
                  capture
                  onChange={onAddPhotoFromFileEvent}
                />
              </PhotoButton>
              <Spacer size="L" />
            </>
          )}
        </>
      )}
      <PhotoButton
        disabled={skipUpload || disabled}
        draged={!skipUpload && onDrag}
        text="Dodaj zdjęcie z pliku"
        icon="id-document-front"
        type="button"
      >
        <HiddenFileInput
          disabled={skipUpload || disabled}
          type="file"
          accept={`image/${acceptExtensions.replace(/\.(\w+)/g, 'image/$1')}`}
          onChange={onAddPhotoFromFileEvent}
        />
      </PhotoButton>
    </PhotoButtonsWrapper>
  );
}
