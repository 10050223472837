import { useCallback, useEffect, useContext, Dispatch, SetStateAction } from 'react';
import Axios from 'axios';
import { useCurrentProposalStore } from './useCurrentProposalStore';
import { useSteps } from './useSteps';
import { ErrorContext } from '../../../../Errors/ErrorsContextProvider';
import { FileWithCanvas } from '../components/Photo/hooks/usePhoto';

interface Hook {
  onPrevStep: () => void;
  onNextStep: () => void;
  stepProgress: number;
  mainClient?: K2Paperless.MainClientWithAccept;
  clients?: K2Paperless.ClientWithAccept[];
  documentType?: K2Paperless.DocumentType;
  onFinishOneOfConsentsPage: (
    changedIndex: string, consents: K2Paperless.ConsentWithAccept[]
  ) => void;
  setConsents: (
    changedClientIndex: string, consents: K2Paperless.ConsentWithAccept[]
  ) => void;
  skipUpload: boolean;
  uploads: FileWithCanvas[];
  skipAdditionalUpload: boolean;
  additionalUploads: FileWithCanvas[];
  transferFromOwnedBankAccount: boolean | null;
  setTransferFromOwnedBankAccount: (_: boolean) => void;
  setSkipUpload: (_: boolean) => void;
  setSkipAdditionalUpload: (_: boolean) => void;
  setUploads: Dispatch<SetStateAction<FileWithCanvas[]>>;
  setAdditionalUploads: Dispatch<SetStateAction<FileWithCanvas[]>>;
  additionalDocuments: K2Paperless.AdditionalDocument[];
  authenticationMethod?: K2Paperless.AuthenticationMethod;
  additionalDocumentRequired: boolean;
}

function mapConsentToConsentWithAccept(consent: K2Paperless.Consent): K2Paperless.ConsentWithAccept { // eslint-disable-line max-len
  return {
    ...consent,
    accepted: false,
  };
}

function mapClientToClientWithAccept(client: K2Paperless.Client): K2Paperless.ClientWithAccept {
  return {
    clientId: client.clientId,
    clientName: client.clientName,
    clientRoles: client.clientRoles,
    consents: client.consents.map(mapConsentToConsentWithAccept),
    childBirthDate: client.childBirthDate,
  };
}

export function mapProposalToMainClientWithAccept(proposal: K2Response.Proposal): K2Paperless.MainClientWithAccept { // eslint-disable-line max-len
  return {
    clientId: proposal.clientId,
    clientName: proposal.clientName,
    clientRoles: proposal.clientRoles,
    consents: proposal.consents.map(mapConsentToConsentWithAccept),
    documentType: proposal.documentType,
    salesType: proposal.salesType,
    clients: proposal.clients.map(mapClientToClientWithAccept),
    signDate: proposal.signDate,
    contractDescription: proposal.contractDescription,
    contractNumber: proposal.contractNumber,
    transferFromOwnedBankAccountRequired: proposal.transferFromOwnedBankAccountRequired,
    productType: proposal.productType,
    additionalDocumentRequired: proposal.additionalDocumentRequired,
    processType: proposal.processType,
    documentRequired: proposal.documentRequired,
    requiredDocumentsByOtherChannelAvailable: proposal.requiredDocumentsByOtherChannelAvailable,
  };
}

export function useProposal(clientId: number): Hook {
  const { onNextStep, onPrevStep, stepProgress, updateSteps } = useSteps(clientId);
  const { setResponseError } = useContext(ErrorContext);

  const {
    mainClient,
    setMainClient,
    setClients,
    clients,
    documentType,
    setDocumentType,
    setConsents,
    transferFromOwnedBankAccount,
    setTransferFromOwnedBankAccount,
    setSkipUpload,
    setSkipAdditionalUpload,
    setUploads,
    setAdditionalUploads,
    uploads,
    additionalUploads,
    skipAdditionalUpload,
    skipUpload,
    additionalDocuments,
    setAdditionalDocuments,
    authenticationMethod,
    setAuthenticationMethod,
    resetStore,
  } = useCurrentProposalStore();

  useEffect(() => {
    resetStore();
    Axios
      .get<K2Response.Proposal>(`/direct/proposals/clients/${clientId}`)
      .then(({ data }): void => {
        setDocumentType(data.documentType);
        setMainClient(mapProposalToMainClientWithAccept(data));
        if (data.clients) {
          setClients(data.clients.map(mapClientToClientWithAccept));
        }
        setAdditionalDocuments(data.additionalDocuments);
        setAuthenticationMethod(data.authenticationMethod);
      }).catch((err) => {
        if (err.response) {
          setResponseError(err.response);
        }
        throw err;
      });
  }, [
    clientId, resetStore, setAdditionalDocuments, setAuthenticationMethod,
    setClients, setDocumentType, setMainClient, setResponseError]);

  const onFinishOneOfConsentsPage = useCallback(
    (changedIndex: string, consents: K2Paperless.ConsentWithAccept[]) => {
      setConsents(changedIndex, consents);
      onNextStep();
    }, [onNextStep, setConsents],
  );

  const setSkipUploadAll = useCallback((value: boolean) => {
    setSkipUpload(value);
    setSkipAdditionalUpload(value);
  }, [setSkipAdditionalUpload, setSkipUpload]);

  const additionalDocumentRequired = (mainClient && mainClient.additionalDocumentRequired)
    || transferFromOwnedBankAccount === false;

  useEffect(() => {
    if (mainClient && authenticationMethod) {
      updateSteps(
        mainClient,
        authenticationMethod,
        skipUpload,
        additionalDocumentRequired,
        clients,
      );
    }
  }, [authenticationMethod, clients, mainClient,
    additionalDocumentRequired, skipUpload, updateSteps]);

  return {
    onPrevStep,
    onNextStep,
    stepProgress,
    mainClient,
    clients,
    documentType,
    onFinishOneOfConsentsPage,
    setConsents,
    transferFromOwnedBankAccount,
    setTransferFromOwnedBankAccount,
    setSkipUpload: setSkipUploadAll,
    setSkipAdditionalUpload,
    setUploads,
    setAdditionalUploads,
    uploads,
    additionalUploads,
    skipAdditionalUpload,
    skipUpload,
    additionalDocuments,
    authenticationMethod,
    additionalDocumentRequired,
  };
}
