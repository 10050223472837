import React from 'react';
import { Switch, Route, useParams, Redirect } from 'react-router-dom';
import styled, { CSSProp } from 'styled-components';
import { nnColors } from '@agent-zone/ui-library/esm/colors';
import { Footer } from '@agent-zone/ui-library/esm/Footer';
import { Consents } from './components/Consents/Consents';
import { AdditionalPhoto } from './components/Photo/AdditionalPhoto';
import { BasePhoto } from './components/Photo/BasePhoto';
import { useProposal } from './hooks/useProposal';
import { Success } from './components/Success/Success';
import { colors } from '../../../../ui/Colors/colors';
import { media, MOBILE } from '../../../../breakpoints';

export const ProgressBarWrapper = styled.div`
  background: ${nnColors.greyLight};

  @media ${media.desktop} {
    margin: 0 auto;
    overflow: hidden;
  }
`;

export const FooterWrapper = styled.div`
  border-top: 1px solid ${nnColors.orangeLight};
  margin-top: 40px;

  @media ${media.desktop} {
    margin: 0 auto;
    margin-top: 40px;
    overflow: hidden;
  }
`;

interface ProgressBarProps {
  percent: number;
}

export const ProgressBar = styled.div<ProgressBarProps>`
  width: ${({ percent = 0 }: ProgressBarProps): CSSProp => `${percent}%`};
  height: 2px;
  background-color: ${colors.goldDrop};
  transition: width 450ms ease-in;

  @media ${media.desktop} {
    height: 2px;
  }

  @media screen and (max-width: ${MOBILE}px) {
    height: 1px;
  }
`;

interface Props {
  isSingleProposal?: boolean;
  isDemo?: boolean;
  setDemoType: (_: 'PS' | 'NBU') => void;
}

export function ProposalDetails({
  isSingleProposal,
  isDemo,
  setDemoType,
}: Props): JSX.Element {
  const { clientId } = useParams<{ clientId: string }>();
  const {
    onNextStep,
    onPrevStep,
    onFinishOneOfConsentsPage,
    setConsents,
    setTransferFromOwnedBankAccount,
    documentType,
    clients,
    mainClient,
    transferFromOwnedBankAccount,
    uploads,
    setUploads,
    skipUpload,
    setSkipUpload,
    additionalUploads,
    setAdditionalUploads,
    skipAdditionalUpload,
    setSkipAdditionalUpload,
    additionalDocuments,
    authenticationMethod,
    stepProgress,
    additionalDocumentRequired,
  } = useProposal(+clientId);
  return (
    <>
      <ProgressBarWrapper>
        <ProgressBar percent={stepProgress} />
      </ProgressBarWrapper>
      <Switch>
        {documentType && mainClient && authenticationMethod && [
          <Route key="consents" path="/proposals/:clientId/consents/:index">
            <Consents
              key={clientId}
              onFinish={onFinishOneOfConsentsPage}
              onFinishAll={onNextStep}
              mainClient={mainClient}
              clients={clients}
              setConsents={setConsents}
              onPrevStep={onPrevStep}
              transferFromOwnedBankAccount={transferFromOwnedBankAccount}
              setTransferFromOwnedBankAccount={setTransferFromOwnedBankAccount}
              authenticationMethod={authenticationMethod}
              isSingleProposal={isSingleProposal}
              documentType={documentType}
              additionalDocumentRequired={additionalDocumentRequired}
            />
          </Route>,
          <Route key="photo" path="/proposals/:clientId/photo">
            <BasePhoto
              onFinish={onNextStep}
              onGoBack={onPrevStep}
              documentType={documentType}
              mainClient={mainClient}
              clients={clients}
              uploads={uploads}
              setUploads={setUploads}
              skipUpload={skipUpload}
              skipAdditionalUpload={skipAdditionalUpload}
              setSkipUpload={setSkipUpload}
              transferFromOwnedBankAccount={transferFromOwnedBankAccount}
              additionalDocumentRequired={additionalDocumentRequired}
            />
          </Route>,
          <Route key="additional-photo" path="/proposals/:clientId/additional-photo">
            <AdditionalPhoto
              onFinish={onNextStep}
              onGoBack={onPrevStep}
              mainClient={mainClient}
              clients={clients}
              uploads={uploads}
              skipUpload={skipUpload}
              additionalUploads={additionalUploads}
              setAdditionalUploads={setAdditionalUploads}
              skipAdditionalUpload={skipAdditionalUpload}
              setSkipAdditionalUpload={setSkipAdditionalUpload}
              additionalDocuments={additionalDocuments}
              transferFromOwnedBankAccount={transferFromOwnedBankAccount}
            />
          </Route>,
          <Route key="success" exact path="/proposals/:clientId/success">
            <Success
              isDemo={isDemo}
              setDemoType={setDemoType}
              mainClient={mainClient}
              isSingleProposal={isSingleProposal}
            />
          </Route>,
          <Redirect key="redirect" to={`/proposals/:clientId/consents/${!(mainClient.consents.length === 0 && !!clients) ? 'main' : '0'}`} />,
        ]}
      </Switch>

      <FooterWrapper>
        <Footer
          regulationsUrl="/paperless/public/terms-of-use"
          privacyPolicyUrl="https://www.nn.pl/polityka-cookies.html"
          securityUrl="https://www.nn.pl/bezpieczenstwo.html"
        />
      </FooterWrapper>
    </>
  );
}
