import { useState, useCallback, Dispatch, SetStateAction } from 'react';
import { FileWithCanvas } from '../components/Photo/hooks/usePhoto';

interface AppStateHook {
  mainClient?: K2Paperless.MainClientWithAccept;
  setMainClient: (mainClient: K2Paperless.MainClientWithAccept) => void;
  clients?: K2Paperless.ClientWithAccept[];
  setClients: (clients: K2Paperless.ClientWithAccept[]) => void;
  documentType?: K2Paperless.DocumentType;
  setDocumentType: (documentType: K2Paperless.DocumentType) => void;
  setConsents: (
    changedClientIndex: string, consents: K2Paperless.ConsentWithAccept[]
  ) => void;
  resetStore: () => void;
  skipUpload: boolean;
  uploads: FileWithCanvas[];
  skipAdditionalUpload: boolean;
  additionalUploads: FileWithCanvas[];
  transferFromOwnedBankAccount: boolean | null;
  setTransferFromOwnedBankAccount: (_: boolean) => void;
  setSkipUpload: (_: boolean) => void;
  setSkipAdditionalUpload: (_: boolean) => void;
  setUploads: Dispatch<SetStateAction<FileWithCanvas[]>>;
  setAdditionalUploads: Dispatch<SetStateAction<FileWithCanvas[]>>;
  additionalDocuments: K2Paperless.AdditionalDocument[];
  setAdditionalDocuments: Dispatch<SetStateAction<K2Paperless.AdditionalDocument[]>>;
  authenticationMethod?: K2Paperless.AuthenticationMethod;
  setAuthenticationMethod: Dispatch<SetStateAction<K2Paperless.AuthenticationMethod | undefined>>;
}

export function useCurrentProposalStore(): AppStateHook {
  const [documentType, setDocumentType] = useState<K2Paperless.DocumentType | undefined>(undefined);
  const [mainClient, setMainClient] = useState<K2Paperless.MainClientWithAccept | undefined>(undefined); // eslint-disable-line max-len
  const [clients, setClients] = useState<K2Paperless.ClientWithAccept[] | undefined>(undefined);
  const [skipUpload, setSkipUpload] = useState<boolean>(false);
  const [uploads, setUploads] = useState<FileWithCanvas[]>([{}]);
  const [skipAdditionalUpload, setSkipAdditionalUpload] = useState<boolean>(false);
  const [additionalUploads, setAdditionalUploads] = useState<FileWithCanvas[]>([{}]);
  const [
    transferFromOwnedBankAccount, setTransferFromOwnedBankAccount,
  ] = useState<boolean | null>(null);
  const [
    additionalDocuments, setAdditionalDocuments,
  ] = useState<K2Paperless.AdditionalDocument[]>([]);
  const [
    authenticationMethod, setAuthenticationMethod,
  ] = useState<K2Paperless.AuthenticationMethod | undefined>(undefined);

  const setConsents = useCallback((
    changedClientIndex: string, consents: K2Paperless.ConsentWithAccept[],
  ) => {
    if (Number.isNaN(+changedClientIndex)) {
      setMainClient((prevMainClient) => {
        if (prevMainClient) {
          return {
            ...prevMainClient,
            consents,
          };
        }
        return prevMainClient;
      });
    } else {
      setClients((prevClients) => {
        if (prevClients) {
          return prevClients.map((client, index) => {
            if (+changedClientIndex === index) {
              return {
                ...client,
                consents,
              };
            }
            return client;
          });
        }

        return [];
      });
    }
  }, []);

  const resetStore = useCallback((): void => {
    setDocumentType(undefined);
    setMainClient(undefined);
    setClients(undefined);
    setSkipUpload(false);
    setSkipAdditionalUpload(false);
    setUploads([{}]);
    setAdditionalUploads([{}]);
    setAdditionalDocuments([]);
    setTransferFromOwnedBankAccount(null);
    setAuthenticationMethod(undefined);
  }, []);

  return {
    mainClient,
    setMainClient,
    clients,
    setClients,
    documentType,
    setDocumentType,
    setConsents,
    resetStore,
    skipUpload,
    uploads,
    additionalUploads,
    skipAdditionalUpload,
    transferFromOwnedBankAccount,
    setTransferFromOwnedBankAccount,
    setSkipAdditionalUpload,
    setSkipUpload,
    setUploads,
    setAdditionalUploads,
    additionalDocuments,
    setAdditionalDocuments,
    authenticationMethod,
    setAuthenticationMethod,
  };
}
