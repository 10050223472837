import React, { useCallback } from 'react';
import styled, { CSSProp, css } from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Elevation } from '@agent-zone/ui-library/esm/Elevation';
import { MarginsWrapper } from '@agent-zone/ui-library/esm/Margins';
import { Grid } from '@agent-zone/ui-library/esm/Grid';
import { Button } from '@agent-zone/ui-library/esm/Button';
import { Typo } from '@agent-zone/ui-library/esm/Typo';
import { Chip } from '@agent-zone/ui-library/esm/Chip';
import { nnColors } from '@agent-zone/ui-library/esm/colors';
import { dateFormat } from '../../../utils/utils';
import { LAPTOP, TABLET } from '../../../../breakpoints';
import { useDimensions } from '../../../../hooks/useDimensions';

interface SingleProposalProps {
  status: K2Paperless.Proposal['status'];
  vertical: boolean;
}

const PolicyDescCaption = styled(Typo)``;
const PolicyDesc = styled(Typo)``;

const SingleProposalWrapper = styled(Elevation)<SingleProposalProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: box-shadow ease 0.275s;
  flex-wrap: wrap;
  position: relative;
  box-sizing: border-box;
  ${({ vertical }): CSSProp => (vertical ? css`display: block;` : '')}
  ${({ status }): CSSProp => (status !== 'IN_PROGRESS'
    ? css`
          cursor: default;
        `
    : css`
      transition: border 175ms ease-in-out;
      border: 1px solid transparent;
      cursor: pointer;
      &:hover {
        button {
          background: ${nnColors.orangeMedium};
          color: white;
        }
        border: 1px solid ${nnColors.orangeMedium};
        ${PolicyDesc} { color: ${nnColors.orangeMedium}; }
        ${PolicyDescCaption} { color: ${nnColors.orangeMedium}; }
      }
    `)}
`;

interface Props {
  proposal: K2Paperless.Proposal;
  setDemoType?: () => void;
}

const StatusDetailsWrapper = styled(Grid) <{ vertical: boolean }>`
  justify-content: ${({ vertical }): CSSProp => (vertical ? 'end' : 'flex-end')};
  align-items: ${({ vertical }): CSSProp => (vertical ? 'flex-end' : 'flex-start')};
  button {
    width: ${({ vertical }): CSSProp => (vertical ? '171px' : '')};
  }
  @media screen and (max-width: ${TABLET}px) {
    align-items: flex-start;
  }
`;
const ProposalDetailsWrapper = styled(Grid)<{ vertical: boolean }>`
  max-width: ${({ vertical }): CSSProp => (vertical ? '100%' : '230px')};
  flex-direction: ${({ vertical }): CSSProp => (vertical ? 'row' : 'column')};
`;

const CaptionWrapper = styled(MarginsWrapper)`
  min-width: 78px;
`;

export const SingleProposal = ({ proposal, setDemoType }: Props): JSX.Element => {
  const {
    status,
    contractDescription,
    contractNumber,
    signDate,
    clientId,
    processType,
  } = proposal;
  const history = useHistory();
  const { viewportWidth } = useDimensions();
  const onClickHandler = useCallback(async () => {
    if (setDemoType) {
      await setDemoType();
      history.push(`/proposals/${clientId}/consents`);
    } else if (status === 'IN_PROGRESS') { history.push(`/proposals/${clientId}/consents`); }
  }, [clientId, history, setDemoType, status]);

  return (
    <SingleProposalWrapper
      marginBottom="L"
      paddingsHorizontal="M"
      paddingsVertical="L"
      shadowSize={status === 'IN_PROGRESS' ? 'L' : undefined}
      status={status}
      color={status !== 'IN_PROGRESS' ? 'offWhite' : undefined}
      onClick={onClickHandler}
      vertical={viewportWidth < LAPTOP}
    >
      <Grid block paddingBottom={viewportWidth < LAPTOP ? 'M' : undefined}>
        <>
          <CaptionWrapper marginBottom="S">
            <PolicyDescCaption noMargin $type="captionBold">
              {processType === 'PS'
                ? 'Zmiany na Twojej polisie'
                : 'Twoja nowa polisa'}
            </PolicyDescCaption>
          </CaptionWrapper>
          <PolicyDesc noMargin $type="bodyBold">
            {contractDescription}
          </PolicyDesc>
        </>
      </Grid>
      <ProposalDetailsWrapper vertical={viewportWidth < LAPTOP}>
        <Grid>
          <MarginsWrapper marginRight="M">
            <CaptionWrapper marginBottom="S">
              <Typo noMargin $type="caption" $color="greyMedium">
                {processType === 'PS'
                  ? 'Numer polisy'
                  : 'Numer wniosku'}
              </Typo>
            </CaptionWrapper>
            <Typo noMargin $type="body">
              {contractNumber}
            </Typo>
          </MarginsWrapper>
          <MarginsWrapper>
            <CaptionWrapper marginBottom="S">
              <Typo noMargin $type="caption" $color="greyMedium">
                Data zgłoszenia
              </Typo>
            </CaptionWrapper>
            <Typo noMargin $type="body">
              {dateFormat(signDate)}
            </Typo>
          </MarginsWrapper>
        </Grid>
        {(viewportWidth < LAPTOP && viewportWidth > TABLET) && (
          <StatusDetailsWrapper vertical>
            {status === 'IN_PROGRESS' && (
              <Button $size="m" $type="secondary">
                Uzupełnij zgody
              </Button>
            )}
            {status === 'CONSENT_SENT' && (
              <Chip bColor="colourGreen700">
                Zgody uzupełnione
              </Chip>
            )}
          </StatusDetailsWrapper>
        )}
      </ProposalDetailsWrapper>
      {(viewportWidth < TABLET || viewportWidth >= LAPTOP) && (
        <StatusDetailsWrapper paddingTop={viewportWidth < TABLET ? 'M' : undefined} vertical={viewportWidth < TABLET}>
          {status === 'IN_PROGRESS' && (
            <Button $size="m" $type="secondary">
              Uzupełnij zgody
            </Button>
          )}
          {status === 'CONSENT_SENT' && (
            <Chip bColor="colourGreen700">
              Zgody uzupełnione
            </Chip>
          )}
        </StatusDetailsWrapper>
      )}
    </SingleProposalWrapper>
  );
};
