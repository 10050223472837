import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Typo } from '@agent-zone/ui-library/esm/Typo';
import { MarginsWrapper } from '@agent-zone/ui-library/esm/Margins';
import { PhotoButtons } from './components/PhotoButtons';
import { SinglePhoto } from './components/SinglePhoto';
import { SunLoader } from '../../../../../../../../ui/Loader/SunLoader';
import { FileWithCanvas } from '../../hooks/usePhoto';


const LoaderWrapper = styled.div`
  width: 100%;
  height: 165px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface Props {
  isCaptureAvailable: boolean;
  onTryToStartCapture: () => void;
  onAddPhotoFromFileEvent: (event: React.ChangeEvent<HTMLInputElement>) => void;
  upload: FileWithCanvas;
  onEraseUpload: () => void;
  loading: boolean;
  skipUpload: boolean;
  disabled: boolean;
  scrollInto?: boolean;
  index: number;
}

export function PhotoSection({
  isCaptureAvailable,
  onTryToStartCapture,
  onAddPhotoFromFileEvent,
  upload,
  onEraseUpload,
  loading,
  skipUpload,
  disabled,
  scrollInto,
  index,
}: Props): JSX.Element {
  const scrollToRef = useRef<HTMLDivElement>(null);
  const [onDrag, setDrag] = useState(false);

  useEffect(() => {
    if (scrollInto && scrollToRef.current) {
      setTimeout(() => scrollToRef.current && scrollToRef.current.scrollIntoView({ block: 'start', behavior: 'smooth' }), 1000);
    }
  }, [index, scrollInto]);

  if (loading) {
    return (
      <>
        <MarginsWrapper marginTop="M">
          <Typo $type="H4">{`${index + 1 === 1 ? 'Przód dokumentu' : 'Tył dokumentu'}`}</Typo>
        </MarginsWrapper>
        <LoaderWrapper>
          <SunLoader variant="dark" />
        </LoaderWrapper>
      </>
    );
  }

  return (
    <div
      ref={scrollToRef}
      onDragEnter={(): void => setDrag(true)}
      onDragLeave={(): void => setDrag(false)}
      onDragOver={(): false | void => navigator.userAgent.includes('Firefox') && setDrag(true)}
    >
      <MarginsWrapper marginTop="M">
        <Typo $type="H4">{`${index + 1 === 1 ? 'Przód dokumentu' : 'Tył dokumentu'}`}</Typo>
      </MarginsWrapper>
      {upload.originalFile ? (
        <SinglePhoto
          upload={upload}
          onEraseUpload={onEraseUpload}
          disabled={disabled}
        />
      ) : (
        <PhotoButtons
          onDrag={onDrag}
          isCaptureAvailable={isCaptureAvailable}
          onTryToStartCapture={onTryToStartCapture}
          onAddPhotoFromFileEvent={(event): void => {
            onAddPhotoFromFileEvent(event);
            setDrag(false);
          }}
          skipUpload={skipUpload}
          disabled={disabled}
        />
      )}
    </div>
  );
}
