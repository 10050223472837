import React, { useState, useEffect, useCallback } from 'react';
import styled, { css, CSSProp } from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Typo } from '../../ui/Typography/Typo';
import { colors } from '../../ui/Colors/colors';
import { useInterval } from '../../hooks/useInterval';
import { useInactivity } from './useInactivity';

interface WrapperProps {
  show: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 0;
  overflow: hidden;
  background: #d9edf7;
  padding: 0 20px;
  transition: height 150ms ease-in;

  ${({ show }: WrapperProps): CSSProp => (show && css`
    height: 50px;
    @media screen and (max-width: 660px) {
      height: 100px;
    }
  `) || ''};

  @media screen and (min-width: 1200px) {
    max-width: 1216px;
    margin: 0 auto;
  }

  @media screen and (max-width: 660px) {
    flex-direction: column;
    padding: 0 30px;
    justify-content: center;
    span {
      text-align: center;
    }
    button {
      margin-top: 10px;
    }
  }
`;


const StyledButton = styled.button`
  display: flex;
  position: relative;
  min-width: 140px;
  align-items: center;
  margin-left: 10px;
  padding: 5px 8px;
  background-color: ${colors.white};
  border: 1px solid ${colors.goldDrop};
  border-radius: 4px;
  cursor: pointer;
  transition: opacity 300ms;

  &:active {
    opacity: 0.7;
  }

  &:focus {
    outline-color: ${colors.regentStBlue};
  }
`;

const StyledTypo = styled(Typo)`
  margin: 0 10px 0 auto;
  white-space: nowrap;
`;

const Timer = styled(Typo)`
  position: absolute;
`;

interface Props {
  resetGlobalStore: () => void;
}

export const InactivityNotification = ({
  resetGlobalStore,
}: Props): JSX.Element => {
  const { showInactivityNotif, setShowInactivityNotif } = useInactivity();
  const history = useHistory();
  const [countDown, setCountDown] = useState<number>(30);
  const [countDownStopped, setStop] = useState<boolean>(false);

  const onClickHandler = useCallback(
    (): void => {
      setStop(true);
      setShowInactivityNotif(false);
    },
    [setShowInactivityNotif],
  );

  const runCountDownTimer = useCallback((): void => {
    if (showInactivityNotif && countDown > 0 && !countDownStopped) {
      setCountDown(countDown - 1);
    }
  }, [countDown, countDownStopped, showInactivityNotif]);

  useEffect((): void => {
    if (showInactivityNotif) {
      setStop(false);
      setCountDown(30);
    }
  }, [showInactivityNotif]);

  useInterval(runCountDownTimer, 1000);

  useEffect((): void => {
    if (countDown === 0) {
      resetGlobalStore();
      setShowInactivityNotif(false);
      history.push('/');
    }
  }, [countDown, history, resetGlobalStore, setShowInactivityNotif]);

  return (
    <Wrapper show={showInactivityNotif}>
      <Typo color="lochmara" fontWeight={700}>Z powodu bezczynności zostaniesz wylogowany za</Typo>
      <StyledButton
        type="button"
        onClick={onClickHandler}
      >
        <Timer
          color="pomegranate"
          fontWeight={700}
        >
          00:
          {countDown < 10 ? `0${countDown}` : countDown}
        </Timer>
        <StyledTypo
          color="goldDrop"
          fontWeight={700}
          fontSize={14}
        >
          Jestem tu
        </StyledTypo>
      </StyledButton>
    </Wrapper>
  );
};
