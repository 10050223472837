import React, { ReactText, ReactNode } from 'react';
import styled, { CSSObject, CSSProp, css } from 'styled-components';
import { colors } from '../Colors/colors';
import './fonts/fonts.css';

export interface Props extends Pick<CSSObject, 'padding' | 'fontWeight'> {
  color?: keyof typeof colors;
  fontSize?: 10 | 12 | 13 | 14 | 16 | 18 | 20 | 22 | 24 | 26 | 28 | 30 | 40 | 53;
  children?: ReactNode;
  lineThrough?: boolean;
}

export const Typo = styled.span<Props>`
  color: ${({ color = 'mineShaft' }: Props): string => colors[color]};
  font-size: ${({ fontSize = 18 }: Props): ReactText => `${fontSize}px`};
  font-weight: ${({ fontWeight }: Props): ReactText => fontWeight || 'normal'};
  padding: ${({ padding }: Props): ReactText => padding || '0 0 0 0'};
  font-family: 'NNDagnyText', Sans-Serif;
  text-align: left;
  margin: 0;
  line-height: 1.2;

  ${({ lineThrough = false }: Props): CSSProp => (lineThrough && css`
    text-decoration: line-through;
  `) || ''};
`;

const Header = styled(Typo)`
  font-family: 'NNDagnyDisplay', sans-serif;
`;

export const H1 = (props: Props): JSX.Element => <Header as="h1" {...props} />;
export const H2 = (props: Props): JSX.Element => <Header as="h2" {...props} />;
export const H3 = (props: Props): JSX.Element => <Header as="h3" {...props} />;
export const H4 = (props: Props): JSX.Element => <Header as="h4" {...props} />;
export const P = (props: Props): JSX.Element => <Typo as="p" {...props} />;
export const Link = (props: Props & { href: string }): JSX.Element => <Typo as="a" {...props} />;
