import React, { useState, TransitionEvent } from 'react';
import styled, { CSSProp, css } from 'styled-components';
import { colors } from '../../ui/Colors/colors';
import { Paragraph } from '../../ui/LayoutElements/LayoutElements.styled';
import cookie from '../../img/cookie.png';
import { Button } from '../../ui/Button/Button';

interface CookieBarProps {
  slideDown: boolean;
}

const CookieBar = styled.div<CookieBarProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  z-index: 3;
  bottom: 0;
  width: 100%;
  justify-items: center;
  background-color: ${colors.white};
  border-top: 1px solid ${colors.silver};
  transition: transform 350ms ease;
  left: 0;

  ${({ slideDown }: CookieBarProps): CSSProp => (slideDown
      && css`
        transform: translateY(100%);
      `)
    || ''};
`;

const CookieBarContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px;
  max-width: 1216px;
  margin: 0 auto;

  @media screen and (min-width: 1200px) {
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 0;
  }
`;

const CookieImage = styled.img`
  width: 50px;
  margin-bottom: 10px;
  @media screen and (min-width: 1200px) {
    width: 100px;
    margin: 0 20px;
  }
`;

const Text = styled(Paragraph)`
  text-align: center;
  line-height: 1.4;
  margin: 0;
  a {
    display: block;
    color: ${colors.goldDrop};
    text-decoration: none;
  }

  @media screen and (min-width: 1200px) {
    text-align: left;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 20px 20px;

  @media screen and (min-width: 1200px) {
    width: unset;
  }
`;

const StyledButton = styled(Button)`
  margin: auto;
`;

interface DummySpaceProps {
  height: number;
}

const getCookieValue = (cookieName: string): string | undefined => {
  const cookies = document.cookie.match(
    `(^|;)\\s*${cookieName}\\s*=\\s*([^;]+)`,
  );
  return cookies ? cookies.pop() : '';
};

export const CookiesPolicy = (): JSX.Element => {
  const [showCoookieBar, setShowCookieBar] = useState<boolean>(
    !getCookieValue('cookiePolicy'),
  );
  const [slideDown, setSlideDown] = useState<boolean>(false);
  const ref = React.createRef<HTMLDivElement>();

  const acceptCookiePolicy = (): void => {
    document.cookie = 'cookiePolicy=true; expires=Fri, 05 Jun 2028 20:47:11 UTC; path=/';
    setSlideDown(true);
  };

  const onTransitionEnd = (e: TransitionEvent<HTMLDivElement>): void => {
    if (e.propertyName === 'transform') {
      setShowCookieBar(false);
    }
  };

  if (!showCoookieBar) {
    return <></>;
  }

  return (
    <>
      <CookieBar
        ref={ref}
        slideDown={slideDown}
        onTransitionEnd={onTransitionEnd}
      >
        <CookieBarContent>
          <CookieImage src={cookie} alt="cookie policy" />
          <Text color="dustyGray">
            Nie da się ukryć – lubimy ciasteczka. Dlatego używamy plików
            cookies, m.in. by ułatwić korzystanie z naszej strony oraz
            personalizować wyświetlane reklamy. Zasady korzystania z plików
            cookies możesz zmienić w ustawieniach przeglądarki.
            <a
              href="https://www.nn.pl/polityka-cookies.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Więcej informacji o plikach cookies oraz o przetwarzaniu danych.
            </a>
          </Text>
          <ButtonWrapper>
            <StyledButton
              text="Akceptuję"
              onClick={acceptCookiePolicy}
              white
              alignLeft
              icon="done"
              textMarginLeft="8px"
            />
          </ButtonWrapper>
        </CookieBarContent>
      </CookieBar>
    </>
  );
};
