import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import Axios, { AxiosResponse } from 'axios';

interface Props {
  children: JSX.Element;
}

interface ValidationError {
  messageKey: K2Paperless.ErrorCode;
  additionalData?: any;
}

interface Context {
  cleanError: () => void;
  setValidationError: (error?: ValidationError) => void;
  setResponseError: (response: AxiosResponse, wafInline?: boolean) => void;
  validationError?: ValidationError;
}

export const ErrorContext = React.createContext<Context>({
  cleanError: () => undefined,
  setValidationError: () => undefined,
  setResponseError: () => undefined,
  validationError: undefined,
});

const typesToRedirect = ['NOT-FOUND', 'INVALID-PASSWORD', 'PIN-IS-USED', 'PIN-IS-BLOCKED', 'PIN-IS-EXPIRED', 'MAX-ATTEMPTS-REACHED', 'CLIENT-NOT-FOUND', 'LACK-OF-INDEXES'];

export function ErrorsContextProvider(props: Props): JSX.Element {
  const [
    validationError,
    setValidationError,
  ] = useState<ValidationError | undefined>(undefined);

  const history = useHistory();

  const setResponseError = useCallback((response: AxiosResponse, wafInline?: boolean): void => {
    if (response.status === 400 && response.data && response.data.errors) {
      const responseData = response.data as K2Paperless.ErrorResponse;
      const responseErrors = responseData.errors || [];
      const errorPageError = responseErrors
        .find((responseError) => typesToRedirect.includes(responseError.messageKey));

      // json waf error
      if (responseData.errors && responseData.errors[0] && responseData.errors[0].messageKey === 'REQUEST-REJECTED') {
        Axios.post('public/error', { wafId: response.data.correlationId });
        if (wafInline) {
          setValidationError({
            messageKey: responseErrors[0].messageKey,
            additionalData: {
              correlationId: responseData.correlationId,
            },
          });
        } else {
          history.push('/error/waf', { wafId: response.data.correlationId });
        }
        return;
      }

      if (errorPageError) {
        history.push(`/error/response/${errorPageError.messageKey.toLowerCase()}`);
        return;
      }

      // according to task https://jira.inspol.biz/browse/KU-3238 we show only one error
      setValidationError({ messageKey: responseErrors[0].messageKey });
      return; // end now
    }

    if (response.data && response.data.waf) {
      Axios.post('public/error', { wafId: response.data.waf });
      history.push(`/error/waf/${response.data.waf}`);
      return;
    }
    // http error
    history.push(`/error/http/${response.status}`);
  }, [history]);

  const cleanError = useCallback(() => {
    setValidationError(undefined);
  }, []);

  return (
    <ErrorContext.Provider value={{
      cleanError,
      setValidationError,
      setResponseError,
      validationError,
    }}
    >
      {props.children}
    </ErrorContext.Provider>
  );
}
