import { useCallback, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

interface Hook {
  onNextStep: () => void;
  onPrevStep: () => void;
  stepProgress: number;
  updateSteps: (
    mainClient: K2Paperless.MainClient,
    authenticationMethod: K2Paperless.AuthenticationMethod,
    skipUpload: boolean,
    additionalDocumentRequired: boolean,
    clients?: K2Paperless.Client[],
  ) => void;
}

export function useSteps(clientId: number): Hook {
  const history = useHistory();
  const location = useLocation();
  const [steps, setSteps] = useState<string[]>([]);

  const currentStepIndex = steps.indexOf(location.pathname);

  const onPrevStep = useCallback(() => {
    history.push(steps[currentStepIndex - 1]);
  }, [currentStepIndex, history, steps]);

  const onNextStep = useCallback(() => {
    history.push(steps[currentStepIndex + 1]);
  }, [currentStepIndex, history, steps]);

  const updateSteps = useCallback((
    mainClient: K2Paperless.MainClient,
    authenticationMethod: K2Paperless.AuthenticationMethod,
    skipUpload: boolean,
    additionalDocumentRequired: boolean,
    clients?: K2Paperless.Client[],
  ) => {
    setSteps((): string[] => {
      const newSteps: string[] = [];
      const isNoPhotoFlow = !mainClient.documentRequired;
      const isMainClientJustGuardian = mainClient.consents.length === 0 && !!clients;
      const hasChildren = clients && clients.length > 0;

      if (!isMainClientJustGuardian) {
        newSteps.push(`/proposals/${clientId}/consents/main`);
      }

      if (hasChildren && clients) {
        const childrenSteps = clients.map((_, index) => `/proposals/${clientId}/consents/${index}`);
        newSteps.push(...childrenSteps);
      }

      if (!isNoPhotoFlow) {
        newSteps.push(`/proposals/${clientId}/photo`);

        if (additionalDocumentRequired && !skipUpload) {
          newSteps.push(`/proposals/${clientId}/additional-photo`);
        }
      }

      newSteps.push(`/proposals/${clientId}/success`);

      return newSteps;
    });
  }, [clientId]);

  return {
    onNextStep,
    onPrevStep,
    stepProgress: ((currentStepIndex + 1) / steps.length) * 100,
    updateSteps,
  };
}
