import React, { useContext, useRef, useLayoutEffect, useState } from 'react';
import styled, { css, CSSProp } from 'styled-components';
import { ErrorContext } from './ErrorsContextProvider';
import { colors } from '../../ui/Colors/colors';
import { Paragraph } from '../../ui/LayoutElements/LayoutElements.styled';
import { Icon } from '../../ui/Icon/Icon';
import { errorTranslate } from './errorTranslate';

interface BoxWrapperProps {
  height: number;
}

const BoxWrapper = styled.div<BoxWrapperProps>`
  margin: 24px auto 0;
  overflow: hidden;
  width: 100%;
  transition: height 500ms ease;
  ${({ height }): CSSProp => css`height: ${`${height}px`};`};

  p + & {
    margin-top: 9px;
  }
`;

const ErrorParagraph = styled(Paragraph)`
  margin: 0;
  padding: 0;
`;

const Box = styled.div`
  background-color: ${colors.pomegranate}33;
  padding: 15px 30px 15px 10px;
  border-radius: 5px;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  ul {
    margin-left: 15px;
  }
`;

const ErrorBoxIcon = styled(Icon)`
  padding-right: 10px;
`;

export function ErrorBox({ ...props }): JSX.Element {
  const [boxWrapperHeight, setBoxWrapperHeight] = useState<number>(0);
  const boxWrapperRef = useRef<HTMLDivElement | null>(null);

  const { validationError } = useContext(ErrorContext);

  useLayoutEffect(() => {
    if (boxWrapperRef.current) {
      setBoxWrapperHeight(boxWrapperRef.current.scrollHeight);
    } else {
      setBoxWrapperHeight(0);
    }
  }, [validationError]);

  if (!validationError) {
    return <></>;
  }

  return (
    <BoxWrapper
      {...props}
      height={boxWrapperHeight}
    >
      <Box key={validationError.messageKey} ref={boxWrapperRef}>
        <ErrorBoxIcon color="pomegranate" name="info_outline" />
        &nbsp;
        <ErrorParagraph
          color="pomegranate"
        >
          <span dangerouslySetInnerHTML={{
            __html: errorTranslate(validationError.messageKey, validationError.additionalData),
          }}
          />
        </ErrorParagraph>
      </Box>
    </BoxWrapper>
  );
}
