import React, { useState } from 'react';
import { Elevation } from '@agent-zone/ui-library/esm/Elevation';
import { Typo } from '@agent-zone/ui-library/esm/Typo';
import { Grid } from '@agent-zone/ui-library/esm/Grid';
import { Pictogram } from '@agent-zone/ui-library/esm/Pictogram';
import { MarginsWrapper } from '@agent-zone/ui-library/esm/Margins';
import { useScreenScroll } from '../../../../../../hooks/useScreenScroll';
import { useSocialSecurityNumber } from './hooks/useSocialSecurityNumber';
import { InputForm } from '../../../../../InputForm/InputForm';
import { RecaptchaForm } from './components/Recaptcha/RecaptchaForm';

interface Props {
  showCaptcha: boolean;
  captchaKey: string | null;
  setCaptchaToken: (token: string | null) => void;
  onFinish: (token: string) => void;
  pinNumber: (string | undefined)[];
  socialSecurityNumberIndexes: number[];
  captchaToken: string | null;
  onGoBackToPin: () => void;
}

export const SocialSecurityNumberForm = ({
  showCaptcha,
  captchaKey,
  setCaptchaToken,
  onFinish,
  pinNumber,
  socialSecurityNumberIndexes,
  captchaToken,
  onGoBackToPin,
}: Props): JSX.Element => {
  useScreenScroll();

  const {
    setSocialSecurityNumber,
    submit,
    processing,
    socialSecurityNumberConfig,
  } = useSocialSecurityNumber(pinNumber, socialSecurityNumberIndexes, captchaToken, onFinish);


  const [renderCaptcha, setRenderCaptcha] = useState<boolean>(!!showCaptcha);

  if (renderCaptcha && captchaKey) {
    return (
      <RecaptchaForm
        setRenderCaptcha={setRenderCaptcha}
        captchaKey={captchaKey}
        setCaptchaToken={setCaptchaToken}
      />
    );
  }

  return (
    <InputForm
      name="socialSecurityNumber"
      config={socialSecurityNumberConfig}
      setInputArray={setSocialSecurityNumber}
      numbered
      onClick={submit}
      processing={processing}
      onGoBack={onGoBackToPin}
      dense
      secure
    >
      <Elevation paddingsHorizontal="M" paddingsVertical="M" marginBottom="LXL" color="snowWhite">
        <Grid align>
          <MarginsWrapper marginRight="M">
            <Pictogram icon="infoRound" />
          </MarginsWrapper>
          <Typo noMargin $type="body">
            Numer PESEL służy nam do potwierdzenia Twojej tożsamości.
          </Typo>
        </Grid>
      </Elevation>
    </InputForm>
  );
};
