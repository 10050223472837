import styled from 'styled-components';
import { Grid } from '@agent-zone/ui-library/esm/Grid';

const ButtonsWrapperStyled = styled(Grid)<{ content?: string }>`
  justify-content: ${({ content }): string => (content || 'space-between')};
  width: 760px;
  max-width: 100%;
  margin: 24px auto;
  min-height: 40px;
  position: relative;
`;

export const ButtonsWrapper = ButtonsWrapperStyled;
