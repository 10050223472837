import React, { useCallback, useState, useContext, SetStateAction, Dispatch } from 'react';
import { Button } from '@agent-zone/ui-library/esm/Button';
import { Typo } from '@agent-zone/ui-library/esm/Typo';
import { MarginsWrapper, Spacer } from '@agent-zone/ui-library/esm/Margins';
import { Elevation } from '@agent-zone/ui-library/esm/Elevation';
import { Pictogram } from '@agent-zone/ui-library/esm/Pictogram';
import { Grid } from '@agent-zone/ui-library/esm/Grid';
import { ContentWrapper, LineSeparator } from '../../../../../../ui/LayoutElements/LayoutElements.styled';
import { Fader } from '../../../../../../ui/Fader/Fader';
import { ErrorContext } from '../../../../../Errors/ErrorsContextProvider';
import { ButtonsWrapper } from '../../../../../../ui/Button/ButtonsWrapper';
import { useSubmit } from './hooks/useSubmit';
import { Photo } from './components/Photo';
import { FileWithCanvas } from './hooks/usePhoto';
import { useScreenScroll } from '../../../../../../hooks/useScreenScroll';
import { ListItem, Unorderedlist } from '../../../../../../ui/Unorderedlist/Unorderedlist';

interface Props {
  onGoBack: () => void;
  onFinish: () => void;
  documentType: K2Paperless.DocumentType;
  mainClient: K2Paperless.MainClientWithAccept;
  clients?: K2Paperless.ClientWithAccept[];
  skipUpload: boolean;
  skipAdditionalUpload: boolean;
  setSkipUpload: (value: boolean) => void;
  uploads: FileWithCanvas[];
  setUploads: Dispatch<SetStateAction<FileWithCanvas[]>>;
  transferFromOwnedBankAccount: boolean | null;
  additionalDocumentRequired: boolean;
}

const REQUIRED_UPLOADS_COUNT: Record<K2Paperless.DocumentType, 1 | 2> = {
  DO: 2,
  PP: 1,
  KP: 2,
};

const DOCUMENT_TITLE = {
  DO: 'Dodaj zdjęcie lub skan Twojego dowodu osobistego',
  PP: 'Dodaj zdjęcie lub skan Twojego paszportu',
  KP: 'Dodaj zdjęcie lub skan dokumentu potwierdzającego Twoją tożsamość',
};

const DOCUMENT_DESCRIPTION = {
  DO: `Załącz zdjęcia pierwszej i drugiej strony swojego dowodu osobistego, którego numer i seria
  zostały podane na wniosku. Poniżej możesz zrobić zdjęcia dokumentów lub dodać skany
  z pliku.`,
  PP: `Załącz zdjęcia swojego paszportu, którego numer i seria zostały podane na wniosku. Poniżej
  możesz zrobić zdjęcia dokumentu lub dodać skany z pliku.`,
  KP: `Załącz zdjęcie pierwszej i drugiej strony dokumentu, który potwierdza Twoją tożsamość
  podczas pobytu na terytorium RP oraz uprawnia, wraz z dokumentem podróży, do
  wielokrotnego przekraczania granicy bez konieczności uzyskania wizy. Upewnij się, że
  dokument jest ważny. Poniżej możesz zrobić zdjęcia dokumentu lub dodać skany z pliku.`,
};

export const BasePhoto = ({
  onGoBack, onFinish,
  documentType, mainClient, clients,
  skipUpload, setSkipUpload,
  skipAdditionalUpload,
  uploads, setUploads,
  transferFromOwnedBankAccount,
  additionalDocumentRequired,
}: Props): JSX.Element => {
  const [submitting, setSubmitting] = useState(false);
  const { cleanError, setValidationError } = useContext(ErrorContext);

  const onSubmitAndFinish = useSubmit(
    setSubmitting,
    submitting,
    onFinish,
  );

  const onGoBackHandler = useCallback((): void => {
    cleanError();
    onGoBack();
  }, [onGoBack, cleanError]);

  const onInvalidCount = useCallback(() => {
    setValidationError({ messageKey: 'INVALID-DOCUMENT' });
  }, [setValidationError]);

  const uploadsCountCorrect = skipUpload || uploads
    .filter((u) => !!u.originalFile).length === REQUIRED_UPLOADS_COUNT[documentType];

  const onPressSubmit = useCallback(() => {
    if (uploadsCountCorrect) {
      onSubmitAndFinish(
        {
          uploads,
          clients,
          mainClient,
          skipUpload,
          transferFromOwnedBankAccount,
          skipAdditionalUpload,
        },
      );
    } else {
      setValidationError({ messageKey: 'INVALID-DOCUMENT' });
    }
  }, [clients, mainClient, onSubmitAndFinish, setValidationError,
    skipAdditionalUpload, skipUpload, transferFromOwnedBankAccount, uploads, uploadsCountCorrect]);

  useScreenScroll(true, uploadsCountCorrect);

  return (
    <>
      <Fader>
        <ContentWrapper>
          <Spacer size="LXL" />
          <Typo $type="H2" $color="orangeMedium">
            {DOCUMENT_TITLE[documentType]}
          </Typo>
          <Typo $type="body">
            {DOCUMENT_DESCRIPTION[documentType]}
          </Typo>
          <Elevation paddingsHorizontal="M" paddingsVertical="M" color="snowWhite">
            <Grid>
              <Grid flex={0} paddingRight="M">
                <Pictogram icon="infoRound" />
              </Grid>
              <Grid align block>
                <MarginsWrapper marginBottom="S">
                  <Typo noMargin $type="body">Zwróć uwagę:</Typo>
                </MarginsWrapper>
                <Unorderedlist>
                  <ListItem>
                    <MarginsWrapper marginBottom="S">
                      <Typo noMargin inline $type="bodyBold">informacje</Typo>
                      <Typo noMargin inline $type="body"> na dokumencie muszą być </Typo>
                      <Typo noMargin inline $type="bodyBold">w pełni widoczne i czytelne</Typo>
                    </MarginsWrapper>
                  </ListItem>
                  <ListItem>
                    <MarginsWrapper marginBottom="S">
                      <Typo noMargin inline $type="body">na zdjęciu muszą być widoczne</Typo>
                      <Typo noMargin inline $type="bodyBold"> 4 rogi dokumentu</Typo>
                    </MarginsWrapper>
                  </ListItem>
                  <ListItem>
                    <MarginsWrapper marginBottom="S">
                      <Typo noMargin inline $type="body">właściwy format wgrywanego pliku </Typo>
                      <Typo noMargin inline $type="bodyBold">JPG, PNG, TIFF lub PDF, mniejszy niż 6 MB</Typo>
                    </MarginsWrapper>
                  </ListItem>
                </Unorderedlist>
              </Grid>
            </Grid>
          </Elevation>
          <Photo
            documentType={documentType}
            skipUpload={skipUpload}
            setSkipUpload={setSkipUpload}
            uploads={uploads}
            setUploads={setUploads}
            submitting={submitting}
            requiredPhotoCount={REQUIRED_UPLOADS_COUNT[documentType]}
            isAdditionalPhoto={additionalDocumentRequired}
            documentsByOtherChannelAvailable={mainClient.requiredDocumentsByOtherChannelAvailable}
          />
          <LineSeparator />
          {additionalDocumentRequired && !skipUpload ? (
            <Typo $type="body">
              W ostatnim kroku poprosimy Ciebie o dodanie
              dodatkowego dokumentu potwierdzającego Twoją tożsamość.
            </Typo>
          ) : (
            <Typo $type="body">
              Kliknij &quot;Wyślij&quot;, aby przesłać do nas cały formularz.
            </Typo>
          )}
          <ButtonsWrapper>
            <Button
              onClick={onGoBackHandler}
              loading={submitting}
              $type="secondary"
            >
              Wróć
            </Button>
            {additionalDocumentRequired && !skipUpload ? (
              <Button
                onClick={uploadsCountCorrect ? onFinish : onInvalidCount}
                loading={submitting}
                $type="primary"
              >
                Dalej
              </Button>
            ) : (
              <Button
                onClick={onPressSubmit}
                loading={submitting}
                $type="primary"
              >
                Wyślij
              </Button>
            )}
          </ButtonsWrapper>
        </ContentWrapper>
      </Fader>
    </>
  );
};
