import { useRef, useState, useCallback, useEffect, RefObject } from 'react';

const VideoConstraints = {
  video: {
    facingMode: 'environment',
    width: { min: 640, ideal: 3264, max: window.innerWidth <= 320 ? 2248 : 3264 },
    height: { min: 360, ideal: 2448, max: 2448 },
  },
};

interface Hook {
  playerRef: RefObject<HTMLVideoElement>;
  canvasRef: RefObject<HTMLCanvasElement>;
  onCaptureStream: () => void;
  onReset: () => void;
  capture?: HTMLCanvasElement;
  isCaptured: boolean;
}

function cloneCanvas(oldCanvas: HTMLCanvasElement): HTMLCanvasElement {
  // create a new canvas
  const newCanvas = document.createElement('canvas');
  const context = newCanvas.getContext('2d');

  // set dimensions
  newCanvas.width = oldCanvas.width;
  newCanvas.height = oldCanvas.height;

  // apply the old canvas to the new one
  if (context) {
    context.drawImage(oldCanvas, 0, 0);
  }

  // return the new canvas
  return newCanvas;
}

export function useCameraStream(onCaptureError: () => void): Hook {
  const playerRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [capture, setCapture] = useState<HTMLCanvasElement | undefined>();

  const onCaptureStream = useCallback((): void => {
    if (playerRef.current && canvasRef.current) {
      const context = canvasRef.current.getContext('2d');
      if (context) {
        canvasRef.current.width = playerRef.current.videoWidth;
        canvasRef.current.height = playerRef.current.videoHeight;
        context.drawImage(playerRef.current, 0, 0);
        setCapture(cloneCanvas(canvasRef.current));
      }
    }
  }, []);

  const onReset = useCallback(async () => {
    setCapture(undefined);
  }, []);

  useEffect(() => {
    let currentStream: MediaStream | undefined;

    navigator.mediaDevices.getUserMedia(VideoConstraints)
      .then((stream) => {
        if (playerRef.current) {
          playerRef.current.srcObject = stream;
          currentStream = stream;
        }
      })
      .catch(() => {
        onCaptureError();
      });

    return (): void => {
      if (currentStream) {
        currentStream.getTracks().forEach((track) => track.stop());
      }
    };
  }, [onCaptureError]);

  return {
    playerRef,
    canvasRef,
    onCaptureStream,
    onReset,
    isCaptured: !!capture,
    capture,
  };
}
