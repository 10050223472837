import React, { useState, useRef } from 'react';
import styled, { css, CSSProp } from 'styled-components';
import { nnColors } from '@agent-zone/ui-library/esm/colors';
import { IconLinkButton } from '@agent-zone/ui-library/esm/IconLinkButton';
import { MarginsWrapper } from '@agent-zone/ui-library/esm/Margins';
import pdfImage from './pdf.png';
import { colors } from '../../../../../../../../../ui/Colors/colors';
import { FileWithCanvas } from '../../../hooks/usePhoto';
import { media } from '../../../../../../../../../breakpoints';

const PhotoItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  overflow: hidden;
  height: 284px;
  background: ${nnColors.offWhite};
`;
interface PhotoImgProps {
  isLoaded: boolean;
}
const PhotoImg = styled.img<PhotoImgProps>`
  max-width: 80%;
  ${({ isLoaded }): CSSProp => (!isLoaded ? css`height: 0;` : css`height: unset;`)}

@media ${media.tablet}{
    height: 250px;
    max-width: calc(100% - 20px);
  }
`;

const ButtonLink = styled.button`
  background: transparent;
  border: 0;
  cursor: pointer;
  min-width: 50%;
  max-width: 50%;
  color: ${colors.goldDrop};
  display: flex;
  align-items: center;
  font-weight: bold;
  outline: none;
  padding: 0 20px 0 0;

  &:hover {
    text-decoration: underline;
  }
`;

function openFileInWindow(file: File): void {
  const pdfWindow = window.open('');
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.addEventListener('loadend', (evt) => {
    if (pdfWindow && evt.target && typeof evt.target.result === 'string') {
      pdfWindow.document.write(
        `<iframe width='100%' height='100%' style="border:none;" src='${encodeURI(evt.target.result)}'></iframe>`,
      );
    }
  });
}

interface Props {
  upload: FileWithCanvas;
  onEraseUpload: () => void;
  disabled: boolean;
}

export function SinglePhoto({ upload, onEraseUpload, disabled }: Props): JSX.Element {
  const photoImgRef = useRef<HTMLImageElement | null>(null);
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <>
      <PhotoItem>
        {upload.canvas && (
          <PhotoImg
            onLoad={(): void => setIsLoaded(true)}
            ref={photoImgRef}
            isLoaded={isLoaded}
            alt="id scan"
            src={upload.canvas.toDataURL('image/png', 1)}
          />
        )}
        {!upload.canvas && upload.originalFile && (
          <ButtonLink
            disabled={disabled}
            type="button"
            onClick={(): void => openFileInWindow(upload.originalFile as File)}
            onLoad={(): void => setIsLoaded(true)}
          >
            <img src={pdfImage} alt="pdf" />
            &nbsp;
            {upload.originalFile.name}
          </ButtonLink>
        )}
      </PhotoItem>
      <MarginsWrapper marginBottom="M">
        <IconLinkButton
          icon="trash"
          onClick={(): void => { setTimeout(() => onEraseUpload(), 500); }}
          text="Usuń"
          disabled={disabled}
        />
      </MarginsWrapper>
    </>
  );
}
