import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { MarginsWrapper, Spacer } from '@agent-zone/ui-library/esm/Margins';
import { Footer } from '@agent-zone/ui-library/esm/Footer';
import { Typo } from '@agent-zone/ui-library/esm/Typo';
import { ContentWrapper } from '../../../../ui/LayoutElements/LayoutElements.styled';
import { useProposalsList } from './hooks/useProposalsList';
import { SingleProposal } from './SingleProposal';
import { CenteredLoader } from '../../../../ui/Loader/CenteredLoader';
import { ProgressBar, ProgressBarWrapper, FooterWrapper } from '../ProposalDetails/ProposalDetails';

interface Props {
  setIsSingleProposal: (_: boolean) => void;
  setDemoType: (_: 'PS' | 'NBU') => void;
  isDemo: boolean;
}

export const ProposalsList = ({ setIsSingleProposal, setDemoType, isDemo }: Props): JSX.Element => {
  const history = useHistory();
  const { proposalsList, firstName, countProposalsInProgress } = useProposalsList();

  useEffect(() => {
    if (proposalsList) {
      // there is only one proposal and has IN_PROGRESS status
      if (proposalsList.length === 1 && countProposalsInProgress === 1) {
        history.push(`/proposals/${proposalsList[0].clientId}/consents`);
        setIsSingleProposal(true);
      }
      // all proposals has CONSENTS_SENT status
      if (proposalsList.length > 0 && countProposalsInProgress === 0) {
        history.push('/error/response/all-consents-sent');
      }
      // there are no proposals
      if (proposalsList.length === 0) {
        history.push('/error/response/no-proposals-available');
      }
    }
  }, [countProposalsInProgress, history, proposalsList, setIsSingleProposal]);
  if (!proposalsList) return <CenteredLoader />;
  const proposalSent = proposalsList.filter((proposal) => proposal.status === 'CONSENT_SENT');
  const proposalInProgress = proposalsList.filter((proposal) => proposal.status === 'IN_PROGRESS');

  return (
    <>
      <ProgressBarWrapper>
        <ProgressBar percent={0} />
      </ProgressBarWrapper>
      <ContentWrapper>
        <Spacer size="LXL" />
        <Typo $type="H1" $color="orangeMedium">
          Witaj
          {' '}
          <span>{firstName}</span>
        </Typo>
        <MarginsWrapper marginBottom="LXL">
          <Typo $type="body" noMargin>
            Otrzymaliśmy od Ciebie (lub od właściciela polisy) poniższe wnioski.
          </Typo>
          <Typo $type="body" noMargin>
            Aby się nimi zająć, potrzebujemy Twoich zgód.
          </Typo>
        </MarginsWrapper>
        {proposalInProgress.length > 0 && (
          <Typo $type="H3">
            Wnioski, w których czekamy na Twoje zgody
          </Typo>
        )}
        {proposalInProgress
          .map((proposal) => (
            <SingleProposal
              setDemoType={isDemo ? (): void => setDemoType(proposal.processType) : undefined}
              key={proposal.id}
              proposal={proposal}
            />
          ))}
        {proposalSent.length > 0 && (
          <MarginsWrapper marginTop="S">
            <Typo $type="H3">
              Wnioski, którymi już się zajmujemy
            </Typo>
          </MarginsWrapper>
        )}
        {proposalSent
          .map((proposal) => (
            <SingleProposal
              key={proposal.id}
              proposal={proposal}
            />
          ))}
      </ContentWrapper>
      <FooterWrapper>
        <Footer
          regulationsUrl="/paperless/public/terms-of-use"
          privacyPolicyUrl="https://www.nn.pl/polityka-cookies.html"
          securityUrl="https://www.nn.pl/bezpieczenstwo.html"
        />
      </FooterWrapper>
    </>
  );
};
