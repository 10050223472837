export const translated = {
  'VALUE-IS-TOO-SMALL': 'Uzupełnij wszystkie pola, aby przejść dalej.',
  'FIELD-IS-REQUIRED': 'Uzupełnij wszystkie pola, aby przejść dalej.',
  'NOT-FOUND': 'Błędny numer PIN.',
  'INVALID-VALUE': 'Błędny numer PESEL.',
  'CONSENTS-NOT-ACCEPTED': 'Zaznaczenie wszystkich zgód i oświadczeń jest niezbędne do dalszego procedowania wniosku.',
  'TRANSFER-BANK-ACCOUNT-NOT-CHOSEN': 'Zaznaczenie sposobu przelewu jest niezbędne do dalszego procedowania wniosku.',
  'INVALID-DOCUMENT': 'Dodanie zdjęć lub skanów dokumentu jest wymagane. Jeśli zdjęcia zostały przekazane przedstawicielowi w innej formie np. emailem zaznacz powyższą opcję.',
  'INVALID-ADDITIONAL-DOCUMENT': 'Dodanie zdjęć lub skanów dodatkowego dokumentu potwierdzającego Twoją tożsamość jest wymagane.',
  'INVALID-PRIMARY-DOCUMENT': 'Nie został wybrany typ dokumentu dodatkowego',
  'PIN-IS-EXPIRED': 'Numer PIN wygasł',
  'PIN-IS-USED': 'Numer PIN został już wykorzystany',
  'PIN-IS-BLOCKED': 'Przykro nam ale limit prób został wyczerpany. Twój PIN został zablokowany.',
  'MAX-ATTEMPTS-REACHED': 'Osiągnięto maksymalną pule prób logowania',
  'FILE-TOO-LARGE': 'Pojedyncze zdjęcie nie może przekraczać rozmiaru 10MB.',
  'INVALID-STATUS': 'Wniosek nieaktywny. Skontaktuj się z przedstawicielem Nationale Nederlanden.',
  'INVALID-CAPTCHA': 'Błędny token reCAPTCHA.',
  'INVALID-FORMAT': 'Niestety dodawanie pliku nie powiodło się. Sprawdź czy dodawany plik jest w jednym z obsługiwanych formatów: jpg, jpe, gif, png, tiff, pdf.',
  'HEIC-FORMAT': 'Format Live Photo jest nieobsługiwany... Dodaj plik w obsługiwanym formacie.',
  'REQUEST-REJECTED': (additionalData?: any): string => `
    Podczas przesyłania zdjęć dokumentu tożsamości pojawił się problem (WAF${additionalData.correlationId}). Aby kontynuować wykorzystaj jedno z poniższych rozwiązań:
    <ul style="margin-top: 30px; list-style: disc;">
      <li>zapisz plik lub pliki w innym formacie np. jpg i dodaj ponownie do formularza,</li>
      <li>wykorzystaj kamerę klikając w przycisk „Zrób zdjęcie dokumentu”, wykonaj nowe zdjęcia,</li>
      <li>przekaż plik lub pliki zdjęć dokumentu Przedstawicielowi w innej formie i zaznacz opcję:” Zdjęcia lub skan dokumentu zostały przekazane Przedstawicielowi w innej formie.”</li>
      <li>Po wykonaniu jednej z powyższych czynności spróbuj wysłać cały formularz klikając w przycisk Wyślij.</li>
  </ul>
`,
};

export const errorTranslate = (
  messageKey: K2Paperless.ErrorCode,
  additionalData?: any,
): string => {
  const translateInstance = translated[messageKey];
  if (!translateInstance) {
    return '';
  }

  if (typeof translateInstance === 'function') {
    return translateInstance(additionalData);
  }

  return translateInstance;
};
