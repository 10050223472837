import React, { useState, useLayoutEffect } from 'react';
import styled, { css, CSSProp } from 'styled-components';
import { Icon } from '../../../../../../../../../ui/Icon/Icon';
import { Paragraph } from '../../../../../../../../../ui/LayoutElements/LayoutElements.styled';
import { useMaskSize } from '../hooks/useMaskSize';
import { media } from '../../../../../../../../../breakpoints';

const DOCUMENT_FLIP_NAME = {
  DO: 'dowód osobisty',
  PP: 'paszport',
  KP: 'kartę pobytu',
  OTHER: undefined,
};

const IdCameraFrontMaskContainer = styled.div`
  position: absolute;
  pointer-events: none;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  perspective: 1000px;
  transition: transform 1500ms;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden; /* Safari */
  transform: rotateY(0deg);
  ${({ flipped = false }: {flipped: boolean}): string => (flipped ? 'transform: rotateY(-180deg);' : '')}
`;

const IdCameraFrontMaskRatio = styled.div`
  border: 3px dashed white;
  border-radius: 15px;
  display: inline-block;
  position: relative;
  ${({ maskWidth, maskHeight }: {maskWidth: number; maskHeight: number}): CSSProp => css`
    width: ${maskWidth}px;
    height: ${maskHeight}px;
  `}
`;

const IdCameraFrontMaskContent = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 0 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const IdCameraIconWrapper = styled.div`
  border: 5px solid white;
  border-radius: 5px;
  padding-top: 1em;
  padding-right: 5em;
  margin-top: 1em;
  font-size: 10px;
  overflow: hidden;
  display: inline-block;

  @media ${media.desktop} {
    font-size: 24px;
  }
`;

const IdCameraIconWrapperBackward = styled(IdCameraIconWrapper)`
  padding-top: 0;
`;

const IdCameraIcon = styled(Icon)`
  font-size: 40px;
  margin-left: -0.25em;
  margin-bottom: -0.2em;

  @media ${media.desktop} {
    font-size: 80px;
  }
`;

const Text = styled(Paragraph)`
    text-align: center;

    @media ${media.desktop} {
      font-size: 24px;
    }
`;

interface Props {
  showContent: boolean;
  playerRef: React.RefObject<HTMLVideoElement>;
  documentType: K2Paperless.DocumentType | 'OTHER';
  index: number;
  startIndex: number;
  requiredPhotoCount: 1 | 2;
}

export function IdCameraFrontMask({
  showContent = true, documentType, playerRef, index, startIndex, requiredPhotoCount,
}: Props): JSX.Element {
  const { maskWidth, maskHeight } = useMaskSize(playerRef, documentType);

  const [flippedDO, setFlippedDO] = useState(false);

  useLayoutEffect(() => {
    if (requiredPhotoCount === 2 && index > 0) {
      setTimeout(() => setFlippedDO(true), 500);
      // TODO weird: on Chrome transition not starting without JS timeout
    }
  }, [index, requiredPhotoCount]);

  return (
    <>
      {requiredPhotoCount === 1 && (
        <IdCameraFrontMaskContainer flipped={false}>
          <IdCameraFrontMaskRatio
            maskWidth={maskWidth}
            maskHeight={maskHeight}
          >
            {showContent && (
              <IdCameraFrontMaskContent>
                <Text color="white">
                  Przód dokumentu
                  {' '}
                  {documentType === 'PP' && 'paszportu'}
                </Text>
                <Icon fontSize={60} color="white" name="contact_mail" />
              </IdCameraFrontMaskContent>
            )}
          </IdCameraFrontMaskRatio>
        </IdCameraFrontMaskContainer>
      )}
      {requiredPhotoCount === 2 && (
        <>
          <IdCameraFrontMaskContainer flipped={flippedDO}>
            <IdCameraFrontMaskRatio
              maskWidth={maskWidth}
              maskHeight={maskHeight}
            >
              {showContent && (
                <IdCameraFrontMaskContent>
                  <Text color="white">
                    {
                      startIndex === 0
                        ? `Przód ${documentType === 'DO' ? 'dowodu osobistego' : ''}`
                        : `Tył ${documentType === 'DO' ? 'dowodu osobistego' : ''}`
                    }
                  </Text>
                  <IdCameraIconWrapper>
                    <IdCameraIcon color="white" name="portrait" />
                  </IdCameraIconWrapper>
                </IdCameraFrontMaskContent>
              )}
            </IdCameraFrontMaskRatio>
          </IdCameraFrontMaskContainer>
          <IdCameraFrontMaskContainer flipped={!flippedDO}>
            <IdCameraFrontMaskRatio
              maskWidth={maskWidth}
              maskHeight={maskHeight}
            >
              {showContent && (
                <IdCameraFrontMaskContent>
                  <Text color="white">
                    Odwróć
                    {' '}
                    {DOCUMENT_FLIP_NAME[documentType]}
                    {' '}
                    na drugą stronę i zrób zdjęcie
                  </Text>
                  <IdCameraIconWrapperBackward>
                    <IdCameraIcon color="white" name="short_text" />
                  </IdCameraIconWrapperBackward>
                </IdCameraFrontMaskContent>
              )}
            </IdCameraFrontMaskRatio>
          </IdCameraFrontMaskContainer>
        </>
      )}
    </>
  );
}
