import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { IncorrectAuth } from './components/IncorrectAuth';
import { TooMuchAttempts } from './components/TooMuchAttempts';
import { PinExpired } from './components/PinExpired';
import { PinIsUsed } from './components/PinIsUsed';
import { AllConsentsSent } from './components/AllConsentsSent';
import { NoProposalsAvailable } from './components/NoProposalsAvailable';
import { UnknownError } from './components/UnknownError';
import { LacksOfIndexes } from './components/LacksOfIndexes';

export function ResponseErrorPage(): JSX.Element {
  return (
    <Switch>
      <Route path="/error/response/pin-is-used">
        <PinIsUsed />
      </Route>
      <Route path="/error/response/pin-is-expired">
        <PinExpired />
      </Route>
      <Route path="/error/response/pin-is-blocked">
        <TooMuchAttempts />
      </Route>
      <Route path="/error/response/max-attempts-reached">
        <TooMuchAttempts />
      </Route>
      <Route path="/error/response/not-found">
        <IncorrectAuth />
      </Route>
      <Route path="/error/response/invalid-password">
        <IncorrectAuth />
      </Route>
      <Route path="/error/response/all-consents-sent">
        <AllConsentsSent />
      </Route>
      <Route path="/error/response/no-proposals-available">
        <NoProposalsAvailable />
      </Route>
      <Route path="/error/response/client-not-found">
        <NoProposalsAvailable />
      </Route>
      <Route path="/error/response/lack-of-indexes">
        <LacksOfIndexes />
      </Route>
      <Route path="/error/response/unknown-error">
        <UnknownError />
      </Route>
    </Switch>
  );
}
