import Axios from 'axios';
import { useState, useEffect, useContext } from 'react';
import { ErrorContext } from '../../../../Errors/ErrorsContextProvider';

interface Hook {
  proposalsList?: K2Paperless.Proposal[];
  firstName?: string;
  countProposalsInProgress?: number;
}

export const useProposalsList = (): Hook => {
  const [proposalsList, setProposalsList] = useState<K2Paperless.Proposal[] | undefined>(undefined);
  const [firstName, setFirstName] = useState<string | undefined>(undefined);
  const { setResponseError } = useContext(ErrorContext);

  useEffect((): void => {
    Axios.get<K2Response.Proposals>('/direct/proposals')
      .then(({ data }): void => {
        setProposalsList(data.proposals);
        setFirstName(data.firstName);
      })
      .catch((err) => {
        if (err.response) {
          setResponseError(err.response);
        }
        throw err;
      });
  }, [setResponseError]);

  return {
    proposalsList,
    firstName,
    countProposalsInProgress: proposalsList?.filter((proposal): boolean => proposal.status === 'IN_PROGRESS').length,
  };
};
