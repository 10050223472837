/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
import React from 'react';
import styled from 'styled-components';
import { MOBILE } from '../../../../breakpoints';

const BanksLogosWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-self: center;
  margin-bottom: 20px;
  @media screen and (max-width: ${MOBILE}px) {
    justify-content: center;
  }
`;

const Logo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width:  64px;
  height: 64px;
  margin: 12px;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

// The list of banks from backend must be equal to this list to prevent potential error
const banksListValidator = [
  'PKO',
  'BPS',
  'GETIN',
  'ING',
  'INTELIGO',
  'PEKAO',
  'GETIN',
  'VELOBANK',
  'MBANK',
  'BOSBANK',
  'BNPPARIBAS',
  'BSBRODNICA',
  'CREDITAGRICOLE',
  'SANTANDER',
  'KASASTEFCZYKA',
  'ALIORBANK',
  'BANKMILLENNIUM',
  'BANKPOCZTOWY',
];

interface Props {
  banksList?: string[];
}

export const BanksLogos = ({ banksList }: Props): JSX.Element => (
  <BanksLogosWrapper>
    {banksList && banksList.map((logo) => (
      banksListValidator.includes(logo) && (
        <Logo
          key={logo}
        >
          <img src={require(`./img/bank-logo-${logo.toLocaleLowerCase()}.png`)} alt={`mojeID logo ${logo}`} />
        </Logo>
      )
    ))}
  </BanksLogosWrapper>
);
