import { useState, useCallback, useMemo, useContext, useEffect } from 'react';
import axios from 'axios';
import { ErrorContext } from '../../../../../../Errors/ErrorsContextProvider';

const checkIfSocialNumberFilled = (socialSecurityNumber: (string | undefined)[],
  socialSecurityNumberIndexes: number[]): boolean => {
  const neededIndexes = socialSecurityNumberIndexes
    .map((index: number) => socialSecurityNumber[index]);
  return (neededIndexes.every((digit: string | undefined): boolean => digit !== undefined));
};

interface SocialSecurityNumberHook {
  processing: boolean;
  submit: () => void;
  setSocialSecurityNumber: (socialSecurityNumber: (string | undefined)[]) => void;
  socialSecurityNumberConfig: boolean[];
  isSocialSecurityNumberFilled: boolean;
}

const socialSecurityNumberEmptyArr = new Array(11).fill(undefined);

export function useSocialSecurityNumber(
  pinNumber: (string | undefined)[],
  socialSecurityNumberIndexes: number[],
  captchaToken: string | null,
  onFinish: (token: string) => void,
): SocialSecurityNumberHook {
  const { cleanError, setValidationError, setResponseError } = useContext(ErrorContext);
  const [processing, setProcessing] = useState<boolean>(false);
  const [socialSecurityNumber, setSocialSecurityNumber] = useState<(string | undefined)[]>([]);
  const [isSocialSecurityNumberFilled, setSocialSecurityNumberFilled] = useState<boolean>(false);
  const socialSecurityNumberConfig = useMemo(
    () => socialSecurityNumberEmptyArr
      .map((_, index) => socialSecurityNumberIndexes.includes(index)),
    [socialSecurityNumberIndexes],
  );

  const submit = useCallback(
    (): void => {
      if (isSocialSecurityNumberFilled) {
        setProcessing(true);
        cleanError();
        const postData: K2.Request.Authentication = {
          pin: pinNumber.join(''),
          digits: socialSecurityNumber,
          captchaResponse: captchaToken,
        };

        axios.post<K2Response.Authentication>('public/authentication', postData)
          .then(({ data }) => {
            cleanError();
            onFinish(data);
          })
          .catch((err) => {
            if (err.response) {
              setResponseError(err.response);
            }
            throw err;
          })
          .finally(() => {
            setProcessing(false);
          });
      } else {
        setValidationError({ messageKey: 'FIELD-IS-REQUIRED' });
      }
    }, [
      captchaToken, isSocialSecurityNumberFilled, onFinish, pinNumber,
      cleanError, setValidationError, setResponseError, socialSecurityNumber,
    ],
  );

  useEffect((): void => {
    setSocialSecurityNumberFilled(checkIfSocialNumberFilled(
      socialSecurityNumber, socialSecurityNumberIndexes,
    ));
  }, [socialSecurityNumberIndexes, socialSecurityNumber]);

  return {
    processing,
    submit,
    setSocialSecurityNumber,
    socialSecurityNumberConfig,
    isSocialSecurityNumberFilled,
  };
}
