import React from 'react';
import { useLocation } from 'react-router-dom';
import { Typo } from '@agent-zone/ui-library/esm/Typo';
import { ErrorMessageWrapper } from './ErrorMessageWrapper';

interface LocationState {
  state: {
    correlationId: string;
  };
}

export const UnknownError = (): JSX.Element => {
  const { state }: LocationState = useLocation();
  return (
    <ErrorMessageWrapper
      buttonText="Wróć"
    >
      <Typo $type="H1" $color="orangeMedium">
        Upss... coś poszło nie tak
      </Typo>
      <Typo $type="body">
        Wystąpił nieoczekiwany błąd.
        Prawdopodobnie jest to chwilowy problem, dlatego ponów próbę za kilka minut.
      </Typo>
      <Typo $type="body">
        Jeśli problem będzie się powtarzał prosimy o kontakt z Przedstawicielem,
        z którym wniosek został sporządzony i przekaż następujący kod błędu:
        {' '}
        <Typo $type="bodyBold">{(state && state.correlationId) || 'brak kodu błędu'}</Typo>
      </Typo>
    </ErrorMessageWrapper>
  );
};
