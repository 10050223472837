import React from 'react';
import styled from 'styled-components';
import { Checkbox } from '@agent-zone/ui-library/esm/Checkbox';
import { Typo } from '@agent-zone/ui-library/esm/Typo';
import { ExpandableConsent } from './components/ExpandableConsent';

const SingleConsentWrapper = styled.div`
  padding: 5px 0 0;
`;

interface Props {
  consents: K2Paperless.ConsentWithAccept[];
  toggleSingleConsent: (consentId: number) => void;
}

export const ConsentsList = ({ consents, toggleSingleConsent }: Props): JSX.Element => (
  <>
    {consents.map((consent) => (
      <SingleConsentWrapper key={consent.id}>
        <Checkbox
          value={consent.accepted}
          onChange={(): void => toggleSingleConsent(consent.id)}
          label={consent.description.length > 315
            ? <ExpandableConsent description={consent.description} />
            : (
              <>
                <Typo $color="red" $type="body" inline>*</Typo>
                {' '}
                {consent.description}
              </>
            )}
          required
        />
      </SingleConsentWrapper>
    ))}
  </>
);
