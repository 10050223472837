import { nnColors } from '@agent-zone/ui-library/esm/colors';
import { MarginsWrapper } from '@agent-zone/ui-library/esm/Margins';
import styled from 'styled-components';

export const Unorderedlist = styled(MarginsWrapper.withComponent('ul'))``;

export const ListItem = styled.li`
  padding-left: 16px;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 4px;
    height: 8px;
    width: 8px;
    border-radius: 8px;
    background: ${nnColors.orangeMedium};
  }
`;
