import React from 'react';

function padTo2Number(numb: number): string {
  return `0${numb}`.slice(-2);
}

export function DateFormat({ children }: { children: string }): JSX.Element {
  const date = new Date(children);

  return (
    <>
      {padTo2Number(date.getDate())}
      -
      {padTo2Number(date.getMonth() + 1)}
      -
      {date.getFullYear()}
    </>
  );
}
