import React from 'react';
import styled, { css, CSSProp } from 'styled-components';
import { colors } from '../Colors/colors';

export interface Props extends React.HTMLAttributes<HTMLElement> {
  name: string;
  color?: keyof typeof colors;
  fontSize?: number;
  crossed?: boolean;
  outlined?: boolean;
}

const IconBase = styled.i<Omit<Props, 'name'>>`
  vertical-align: middle;
  color: ${({ color }: Omit<Props, 'name'>): string => (color ? colors[color] : '')};
  font-size:  ${({ fontSize }: Omit<Props, 'name'>): string => (fontSize ? `${fontSize}px` : '')};
  ${({ crossed }: Omit<Props, 'name'>): CSSProp => (crossed && css`
    position: relative;

    &::after {
      content: '';
      display: block;
      position: absolute;
      height: 140%;
      border-right: 0.16em solid ${colors.white};
      top: -20%;
      left: 45%;
      transform: rotate(45deg);
    }
  `) || ''};
`;

export function Icon({ name, className = '', style, outlined, color, fontSize, ...props }: Props): JSX.Element {
  return (
    <IconBase
      {...props}
      color={color}
      fontSize={fontSize}
      className={`${outlined ? 'material-icons-outlined' : 'material-icons'} ${className}`}
      style={{ ...style, verticalAlign: 'middle' }}
    >
      {name}
    </IconBase>
  );
}
