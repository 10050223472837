import React from 'react';
import styled, { CSSProp, css } from 'styled-components';
import { colors } from '../Colors/colors';
import { Fader } from '../Fader/Fader';
import { Typo } from '../Typography/Typo';
import { Icon } from '../Icon/Icon';
import { SunLoader } from '../Loader/SunLoader';
import { media } from '../../breakpoints';

interface StyledButtonProps {
  white?: boolean;
  icon?: string;
  processing?: boolean;
  alignLeft?: boolean;
  disabled?: boolean;
}

const textColor = (isWhite?: boolean, isDisabled?: boolean): keyof typeof colors => {
  if (isDisabled) return 'doveGray';
  if (isWhite) return 'christine';
  return 'white';
};

interface ButtonTypoProps {
  icon?: boolean;
}

const ButtonTypo = styled(Typo)<ButtonTypoProps>`
  display: block;
  text-align: center;
  font-family: NNDagnyDisplay;
  font-weight: 400;

  @media ${media.tablet} {
    margin-left: ${({ icon }: ButtonTypoProps): CSSProp => (icon ? '10px' : '')};
  }
 

`;

export const StyledButton = styled.button<StyledButtonProps>`
  position: relative;
  width: 100%;
  min-height: 40px;
  min-width: 130px;
  padding: ${({ icon }: StyledButtonProps): CSSProp => (icon ? '6px 30px 6px 30px' : '10px')};
  border: ${({ white }: StyledButtonProps): CSSProp => (white ? `1px solid ${colors.christine}` : 'none')};
  border-radius: 3px;
  background-color: ${({ white }: StyledButtonProps): CSSProp => (white ? colors.white : colors.christine)};
  color: ${({ white }: StyledButtonProps): CSSProp => (white ? colors.christine : colors.white)};
  font-size: 12px;
  cursor: pointer;
  opacity: 1;
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.24);
  transition: background-color 250ms ease, color 250ms ease, opacity 250ms ease, box-shadow 250ms ease;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ disabled }: StyledButtonProps): CSSProp => (disabled && css`
    background-color: ${colors.silver};
    box-shadow: none;
  `) || ''};

  &:focus:not(:disabled) {
    outline-color: ${colors.regentStBlue};
  }

  a {
    text-decoration: none;
  }

  @media screen and (min-width: 768px) {
    width: unset;
  }

  &:hover:not(:disabled) {
    background-color: ${({ white }: StyledButtonProps): CSSProp => (white ? colors.white : colors.pomegranate)};
    box-shadow:0px 5px 20px rgba(230, 68, 21, 0.31)
  }

  &:active:not(:disabled) {
    box-shadow: 0px 5px 20px rgba(230, 68, 21, 0.317),
    0px 5px 20px 0px rgba(230, 68, 21, 0.317) inset;
  }
`;

const LoaderFrame = styled.div`
  position: absolute;
  top: 1px;
  bottom: 1px;
  left: 1px;
  right: 1px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;

  background: ${({ variant }: { variant: 'dark' | 'light' }): CSSProp => (
    variant === 'dark' ? colors.white : colors.pomegranate
  )};
`;

const StyledIcon = styled(Icon)`
  position: absolute;
  left: 10px;
`;

const ButtonLink = styled.a`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  processing?: boolean;
  white?: boolean;
  icon?: string;
  alignLeft?: boolean;
  textMarginLeft?: string;
  to?: string;
  disabled?: boolean;
}

export const Button = ({
  text,
  icon,
  processing,
  alignLeft,
  to,
  ...props
}: Props): JSX.Element => (
  <StyledButton
    icon={icon}
    alignLeft={alignLeft}
    disabled={processing}
    {...props}
  >
    {icon && <StyledIcon name={icon} />}
    {to ? (
      <ButtonLink href={to}>
        <ButtonTypo
          color={textColor(props.white, props.disabled)}
          fontWeight={600}
          fontSize={16}
          icon={!!icon}
        >
          {text}
        </ButtonTypo>
      </ButtonLink>
    ) : (
      <ButtonTypo
        color={textColor(props.white, props.disabled)}
        fontWeight={600}
        fontSize={16}
        icon={!!icon}
      >
        {text}
      </ButtonTypo>
    )}
    {processing && (
      <Fader>
        <LoaderFrame variant={props.white ? 'dark' : 'light'}>
          <SunLoader variant={props.white ? 'dark' : 'light'} />
        </LoaderFrame>
      </Fader>
    )}
  </StyledButton>
);
