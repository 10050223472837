import React, { useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { Button } from '@agent-zone/ui-library/esm/Button';
import { MarginsWrapper } from '@agent-zone/ui-library/esm/Margins';
import { DigitsForm, DigitsFormProps } from '../NumberForm/DigitsForm';
import { ErrorBox } from '../Errors/ErrorBox';
import { ButtonsWrapper } from '../../ui/Button/ButtonsWrapper';
import { MOBILE } from '../../breakpoints';

const InputFormButtonsWrapper = styled(ButtonsWrapper)`
  max-width: 100%;
  padding: 0;

  @media screen and (max-width: ${MOBILE}px) {
    display: flex;
    flex-direction: column-reverse;
    button {
      width: 100%;
      justify-content: center;
      margin-bottom: 24px;
    }
  }
`;

const ChildrenWrapper = styled(MarginsWrapper)`width: 100%;`;

interface Props extends DigitsFormProps {
  scrollCondition?: boolean;
  onClick: () => void;
  processing: boolean;
  onGoBack?: () => void;
  children?: React.ReactNode;
  secure?: boolean;
  dense?: boolean;
}

export const InputForm = ({
  config,
  setInputArray,
  visit,
  numbered,
  name,
  onClick,
  processing,
  onGoBack,
  children,
  secure,
  dense,
}: Props): JSX.Element => {
  const onPressEnterSubmit = useCallback((e: KeyboardEvent): void | false => e.code === 'Enter'
    && !processing && onClick(), [onClick, processing]);

  useEffect((): void | (() => void) => {
    document.addEventListener('keydown', onPressEnterSubmit);
    return (): void => {
      document.removeEventListener('keydown', onPressEnterSubmit);
    };
  }, [onPressEnterSubmit]);

  return (
    <>
      <DigitsForm
        config={config}
        setInputArray={setInputArray}
        visit={visit}
        numbered={numbered}
        name={name}
        secure={secure}
        dense={dense}
      />
      <ErrorBox />
      {children && (
        <ChildrenWrapper marginTop="LXL" marginBottom="M">
          {children}
        </ChildrenWrapper>
      )}
      <InputFormButtonsWrapper>
        {onGoBack && (
          <Button
            $type="secondary"
            onClick={onGoBack}
          >
            Wróć
          </Button>
        )}
        <Button
          $type="primary"
          onClick={onClick}
          loading={processing}
        >
          {onGoBack ? 'Potwierdź' : 'Dalej'}
        </Button>
      </InputFormButtonsWrapper>
    </>
  );
};
