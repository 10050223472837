import { useState, useCallback, useContext, useEffect } from 'react';
import axios from 'axios';
import { ErrorContext } from '../../../../../../Errors/ErrorsContextProvider';

const checkIfPinFilled = (pinNumber: (string | undefined)[]): boolean => (pinNumber
  .every((digit: string | undefined): boolean => digit !== undefined) && pinNumber.length > 0);

interface PinAuthHook {
  processing: boolean;
  submit: () => void;
  setPinNumber: (pinNumber: (string | undefined)[]) => void;
  isPinFilled: boolean;
}

export const usePinAuth = (
  onFinish: (pinNumber: (string | undefined)[],
    socialSecurityNumberIndexes: number[],
    showCaptcha: boolean,
    captchaKey: string | null,
  ) => void,
): PinAuthHook => {
  const { cleanError, setResponseError } = useContext(ErrorContext);
  const [processing, setProcessing] = useState<boolean>(false);
  const [pinNumber, setPinNumber] = useState<(string | undefined)[]>([]);
  const [isPinFilled, setIsPinFilled] = useState<boolean>(false);

  const submit = useCallback(
    (): void => {
      setProcessing(true);
      cleanError();
      const postData: K2.Request.AuthConfig = {
        pin: pinNumber.join(''),
      };
      axios.post<K2Response.AuthenticationConfig>('public/authentication/config', postData)
        .then(({ data }) => {
          cleanError();
          onFinish(pinNumber, data.indexes, data.showCaptcha, data.captchaKey);
        })
        .catch((err) => {
          if (err.response) {
            setResponseError(err.response);
          }
          throw err;
        })
        .finally(() => {
          setProcessing(false);
        });
    }, [onFinish, pinNumber, cleanError, setResponseError],
  );

  useEffect((): void => {
    setIsPinFilled(checkIfPinFilled(pinNumber));
  }, [pinNumber]);

  return {
    processing,
    submit,
    setPinNumber,
    isPinFilled,
  };
};
