import React from 'react';
import styled, { CSSProp, css, keyframes } from 'styled-components';

interface Props {
  children: React.ReactNode;
  duration?: string;
}

interface FaderProps {
  duration: string;
}

const fadeInAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const FaderContainer = styled.div<FaderProps>`
  z-index: 1;
  ${({ duration }: FaderProps): CSSProp => (duration
    && css`
    animation: ${fadeInAnimation} ${duration} ease-in forwards;
  `)
    || css`
      opacity: 0;
    `};
`;

export const Fader = ({ children, duration = '375ms' }: Props): JSX.Element => (
  <FaderContainer duration={duration}>
    {children}
  </FaderContainer>
);
