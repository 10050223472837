import React from 'react';
import styled from 'styled-components';
import { Typo } from '@agent-zone/ui-library/esm/Typo';
import { colors } from '../../../ui/Colors/colors';
import { ErrorMessageWrapper } from './ErrorMessageWrapper';

const List = styled.ul`
  list-style-type: none;
  padding: 0 20px;
`;

const ListTitle = styled(Typo)`
  margin-top: 2em;
`;

const ListElement = styled.li`
  margin-bottom: 5px;
  position: relative;

  &::before {
    content:"•";
    position: absolute;
    color: ${colors.flushOrange};
    left: -20px;
    font-size: 35px;
    top: -10px;
  }
`;

export function IncorrectAuth(): JSX.Element {
  return (
    <ErrorMessageWrapper>
      <Typo $type="H1" $color="orangeMedium">
        Upss... coś poszło nie tak
      </Typo>
      <Typo $type="body">
        Niestety ale dane wprowadzane w dwóch poprzednich krokach nie są poprawne.
      </Typo>
      <ListTitle $type="body">
        Wróć do strony &quot;Twój kod PIN&quot; i zacznij od początku.
      </ListTitle>
      <List>
        <ListElement>
          <Typo $type="body">
            upewnij się, że wykorzystany PIN otrzymany SMSem jest najnowszy i
            prawidłowo przepisany,
          </Typo>
        </ListElement>
        <ListElement>
          <Typo $type="body">
            uzupełniając pola numeru PESEL zwróć
            szczególną uwagę na kolejność wprowadzanych cyfr,
          </Typo>
        </ListElement>
        <ListElement>
          <Typo $type="body">
            jeśli po kilku próbach w dalszym ciągu masz problem
            skontaktuj się ze swoim przedstawicielem
          </Typo>
        </ListElement>
      </List>
    </ErrorMessageWrapper>
  );
}
