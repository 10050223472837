import React, { useCallback, useRef } from 'react';
import styled, { CSSProp, css } from 'styled-components';
import { Radio } from '@agent-zone/ui-library/esm/Radio';
import { Typo } from '@agent-zone/ui-library/esm/Typo';
import { Elevation } from '@agent-zone/ui-library/esm/Elevation';
import { Grid } from '@agent-zone/ui-library/esm/Grid';
import { Pictogram } from '@agent-zone/ui-library/esm/Pictogram';
import { MarginsWrapper } from '@agent-zone/ui-library/esm/Margins';
import { ConsentsEndInfo } from './ConsentsEndInfo';

interface TogglingButtonProps {
  toggler?: boolean;
}

interface CollapseProps {
  isOpen?: boolean;
}

const Collapse = styled.div<CollapseProps>`
  overflow: hidden;

  ${({ isOpen }: CollapseProps): CSSProp => (isOpen && css`
    max-height: 500px;
  `) || css`
    max-height: 0;
  `};
`;

interface Props {
  transferFromOwnedBankAccountRequired: boolean;
  transferFromOwnedBankAccount: boolean | null;
  setTransferFromOwnedBankAccount: (_: boolean) => void;
  doesNextClientExist?: boolean;
  isLastStep: boolean;
  nextClientName: string;
  isClientChild: boolean;
  documentType: K2Paperless.DocumentType;
  additionalDocumentRequired: boolean;
}

export const ConsentsBankTransferSelect = ({
  transferFromOwnedBankAccountRequired,
  transferFromOwnedBankAccount,
  setTransferFromOwnedBankAccount,
  doesNextClientExist,
  isLastStep,
  nextClientName,
  isClientChild,
  documentType,
  additionalDocumentRequired,
}: Props): JSX.Element => {
  const togglerRef = useRef<HTMLDivElement>(null);

  const scrollToCollapse = useCallback((): void => {
    if (togglerRef && togglerRef.current) {
      togglerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, []);

  if (!transferFromOwnedBankAccountRequired || isClientChild) {
    return (
      <ConsentsEndInfo
        doesNextClientExist={doesNextClientExist}
        isLastStep={isLastStep}
        nextClientName={nextClientName}
        additionalDocumentRequired={additionalDocumentRequired}
        documentType={documentType}
      />
    );
  }

  return (
    <>
      <Typo $type="H4">
        Czy pierwszą składkę opłacisz za pomocą przelewu z Twojego własnego rachunku bankowego?
      </Typo>
      <Radio
        label="Tak"
        checked={transferFromOwnedBankAccount === null ? undefined : transferFromOwnedBankAccount}
        onChange={(): void => setTransferFromOwnedBankAccount(true)}
      />
      <Radio
        label="Nie"
        checked={transferFromOwnedBankAccount === null ? undefined : !transferFromOwnedBankAccount}
        onChange={(): void => setTransferFromOwnedBankAccount(false)}
      />
      <Collapse
        isOpen={transferFromOwnedBankAccount !== null}
        onTransitionEnd={(): void => scrollToCollapse()}
      >
        {transferFromOwnedBankAccount && (
          <Elevation marginBottom="M" color="snowWhite" paddingsHorizontal="M" paddingsVertical="M">
            <Grid>
              <MarginsWrapper marginRight="M">
                <Pictogram icon="infoRound" />
              </MarginsWrapper>
              <Typo $type="body" noMargin>
                Musisz być właścicielem lub współwłaścicielem rachunku bankowego
                z którego zostanie wykonany przelew opłacający pierwszą składkę.
              </Typo>
            </Grid>
          </Elevation>
        )}
        {!transferFromOwnedBankAccount && doesNextClientExist && (
          <Elevation marginBottom="M" color="snowWhite" paddingsHorizontal="M" paddingsVertical="M">
            <Grid>
              <MarginsWrapper marginRight="M">
                <Pictogram icon="infoRound" />
              </MarginsWrapper>
              <Typo $type="body" noMargin>
                Na ostatnim etapie uzupełniania zgód i oświadczeń poprosimy
                o dodanie zdjęć lub skanów dowodu osobistego oraz dodatkowego
                dokumentu potwierdzającego Twoją tożsamość.
              </Typo>
            </Grid>
          </Elevation>
        )}
        <ConsentsEndInfo
          doesNextClientExist={doesNextClientExist}
          isLastStep={isLastStep}
          nextClientName={nextClientName}
          additionalDocumentRequired={additionalDocumentRequired}
          documentType={documentType}
        />
      </Collapse>
    </>
  );
};
