import React, { useEffect, useState, useCallback } from 'react';
import styled, { css, CSSProp } from 'styled-components';
import { Route, useHistory } from 'react-router-dom';
import { Typo } from '@agent-zone/ui-library/esm/Typo';
import { Footer } from '@agent-zone/ui-library/esm/Footer';
import { MarginsWrapper, Spacer } from '@agent-zone/ui-library/esm/Margins';
import { Button } from '@agent-zone/ui-library/esm/Button';
import { Grid } from '@agent-zone/ui-library/esm/Grid';
import { TickCard } from '@agent-zone/ui-library/esm/TickCard';
import { Elevation } from '@agent-zone/ui-library/esm/Elevation';
import { ButtonWrapper } from '@agent-zone/ui-library/esm/ButtonWrapper';
import { nnColors } from '@agent-zone/ui-library/esm/colors';
import { useInView } from 'react-intersection-observer';
import { PINAuth } from './components/PINAuth/PINAuth';
import { BanksLogos } from './components/MojeIDAuth/BanksLogos';
import HeroImage2 from './components/HeroImage/img/heroImage-watercolor.png';
import MojeId from './components/MojeIDAuth/img/mojeId.jpg';
import { MOBILE } from '../../breakpoints';

const AuthContentWrapper = styled.div`
  max-width: 545px;
  min-height: 1054px;
  width: 100%;
  margin: 0 auto;
  @media screen and (max-width: ${MOBILE}px) {
    min-height: auto;
  }
`;

const FloatingFooterWrapper = styled.div<{ inView: boolean; isFloating: boolean }>`
  ${({ isFloating, inView }): CSSProp => (isFloating
    ? css`
      border-top: 1px solid ${nnColors.orangeMedium};
      height: 164px;
      width: 100%;
      & > div {
        position: fixed;
        z-index: ${inView ? '0' : '-1'};
      }
      @media screen and (max-width: 1038px) {
        height: 192px;
      }
    `
    : css`
      border-top: 1px solid ${nnColors.orangeMedium};
      width: 100%;
      & > div {
        position: sticky;
        bottom: 0;
      }
    `)}
`;

const AuthTickCardWrapper = styled.span`
  ${Elevation} {
    width: 261px;
    height: 198px;
    justify-content: center;
    display: flex;
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;
  }
  @media screen and (max-width: ${MOBILE}px) {
    ${Elevation} {
      width: 100%;
      padding-left: 16px;
      padding-right: 16px;
      height: auto;
    }
    path {
      stroke-width: 1!important;
    }
    display: flex;
    flex: 1;
  }
`;

const HeroSideImage = styled.div`
  width: 30%;
  background: ${nnColors.snowWhite};
  position: relative;
`;

const HeroImageBackground = styled.div`
  position: absolute;
  background: ${nnColors.snowWhite};
  top: -144px;
  height: 144px;
  width: 100%;
  z-index: -1;
`;

const HeroImageWrapper = styled.div<{ fullPage?: boolean }>`
  overflow: hidden;
  position: fixed;
  width: 30%;
  margin-top: 50px;
  bottom: 166px;
  z-index: 1;
  img {
    margin-left: calc(100% - 281px);
  }
  @media screen and (min-height: 715px) {
    bottom: auto;
    top: 136px;
  }
`;

const AuthMethodWrapper = styled(MarginsWrapper)`
  background: white;
  @media screen and (min-height: ${MOBILE}px) {
    margin-top: 30px;
  }
`;

const MyIdImage = styled.img`
  height: 24px;
`;

const MyIdImageWrapper = styled(Grid)`
  align-items: flex-end;
  @media screen and (max-width: ${MOBILE}px) {
    margin-bottom: 32px;
  }
`;

const LoginInfoText = styled(Typo)`
  @media screen and (max-width: ${MOBILE}px) {
    display: none;
  }
`;

const LoginWrapper = styled(Grid)`
  @media screen and (max-width: ${MOBILE}px) {
    display: block;
    button {
      width: 100%;
      text-align: center;
      justify-content: center;
      margin-bottom: 32px;
    }
  }
`;

const AuthMethodInfo = styled(Grid)`
  @media screen and (max-width: ${MOBILE}px) {
    display: block;
    ${ButtonWrapper} {
      margin-top: 8px;
    }
  }
`;

interface Props {
  onFinish: (token: string) => void;
  onEnter?: () => void;
  myIdLoggingEnabled?: boolean;
  myIdUrl?: string;
  authenticationProviders?: string[];
  isFloating: boolean;
}

export const AuthMethod = ({
  onFinish,
  onEnter,
  myIdLoggingEnabled,
  myIdUrl,
  authenticationProviders,
  isFloating,
}: Props): JSX.Element => {
  const history = useHistory();
  const [authMethod, setAuthMethod] = useState<'MOJE_ID' | 'PIN'>('MOJE_ID');
  const { ref, inView } = useInView();

  useEffect(() => onEnter && onEnter(), [onEnter]);
  useEffect(() => {
    setAuthMethod(myIdLoggingEnabled ? 'MOJE_ID' : 'PIN');
    window.scrollTo(0, 0);
  }, [myIdLoggingEnabled]);

  const onGoBackToPin = useCallback(() => {
    history.push('/auth');
    if (onEnter) onEnter();
  }, [history, onEnter]);

  return (
    <>
      <AuthMethodWrapper marginTop="XXL">
        <Grid>
          {isFloating && (
            <HeroSideImage>
              <HeroImageBackground />
              <HeroImageWrapper>
                <img src={HeroImage2} alt="Nationale-Nederlanden" />
              </HeroImageWrapper>
            </HeroSideImage>
          )}
          <AuthContentWrapper>
            <Route exact path="/auth">
              <Typo $type="H1" $color="orangeMedium">
                Wybierz metodę logowania
              </Typo>
              <MarginsWrapper marginBottom="LXL">
                <Typo $type="body" noMargin>
                  Aby zalogować się do formularza zgód i oświadczeń,
                  potwierdź swoją tożsamość. Wybierz jedną z poniższych metod:
                </Typo>
              </MarginsWrapper>
              <Grid>
                <AuthTickCardWrapper>
                  <TickCard
                    size="L"
                    active={authMethod === 'MOJE_ID'}
                    onClick={(): void => setAuthMethod('MOJE_ID')}
                    icon="institution"
                    text={(
                      <>
                        Użyj swojego
                        konta bankowego
                      </>
                    )}
                    isCheckbox={false}
                  />
                </AuthTickCardWrapper>
                <Spacer size="L" />
                <AuthTickCardWrapper>
                  <TickCard
                    size="L"
                    active={authMethod === 'PIN'}
                    onClick={(): void => setAuthMethod('PIN')}
                    icon="furniture4"
                    text={(
                      <>
                        Wykorzystaj kod PIN
                        otrzymany SMSem
                      </>
                    )}
                    isCheckbox={false}
                  />
                </AuthTickCardWrapper>
              </Grid>
              <Spacer size="LXL" />
            </Route>
            <Grid block>
              {authMethod === 'MOJE_ID'
                ? (
                  <>
                    <Typo $type="H3">
                      Potwierdź tożsamość przez MojeID
                    </Typo>
                    <Typo $type="body">
                      Skorzystaj z bezpiecznego narzędzia do potwierdzania tożsamości
                      mojeID nadzorowanego przez Krajową Izbę Rozliczeniową.
                      Weryfikacja następuje w oparciu o
                      dane z Twojej bankowości elektronicznej.
                    </Typo>
                    <Typo $type="body">
                      Lista banków, za pomocą których
                      przejdziesz proces potwierdzenia tożsamości:
                    </Typo>
                    <BanksLogos banksList={authenticationProviders} />
                    <LoginInfoText $type="body" noMargin>
                      Klikając w przycisk “Zaloguj”
                      zostaniesz przekierowany do strony mojeid.pl
                      gdzie za pomocą banku potwierdzisz swoją tożsamość.
                    </LoginInfoText>
                    <MarginsWrapper marginTop="L" marginBottom="LXL">
                      <AuthMethodInfo>
                        <Typo $type="body" inline>
                          Nie widzisz swojego banku?
                        </Typo>
                        <Spacer size="S" />
                        <ButtonWrapper type="button" onClick={(): void => setAuthMethod('PIN')}>
                          <Typo $type="body" $color="orangeMedium" inline>
                            Przejdź do weryfikacji za pomocą kodu PIN
                          </Typo>
                        </ButtonWrapper>
                      </AuthMethodInfo>
                    </MarginsWrapper>
                    <MarginsWrapper marginsHorizontal="L">
                      <LoginWrapper>
                        <Button
                          $type="primary"
                          onClick={myIdUrl
                            ? (): void => window.location.replace(myIdUrl)
                            : undefined}
                          disabled={myIdLoggingEnabled === false}
                        >
                          Zaloguj
                        </Button>
                        <MyIdImageWrapper vertical>
                          <MarginsWrapper marginBottom="S">
                            <Typo $type="caption" $color="greyMedium">
                              Bezpieczeństwo zapewnia:
                            </Typo>
                          </MarginsWrapper>
                          <MyIdImage src={MojeId} alt="MojeId" />
                        </MyIdImageWrapper>
                      </LoginWrapper>
                    </MarginsWrapper>
                  </>
                )
                : (
                  <PINAuth
                    isFloating={isFloating}
                    onFinish={onFinish}
                    onGoBackToPin={onGoBackToPin}
                  />
                )}
            </Grid>
          </AuthContentWrapper>
        </Grid>
      </AuthMethodWrapper>
      <FloatingFooterWrapper isFloating={isFloating} inView={inView}>
        <Footer
          regulationsUrl="/paperless/public/terms-of-use"
          privacyPolicyUrl="https://www.nn.pl/polityka-cookies.html"
          securityUrl="https://www.nn.pl/bezpieczenstwo.html"
        />
      </FloatingFooterWrapper>
      <div ref={ref} />
    </>
  );
};
