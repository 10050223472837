export const colors = {
  white: '#fff',
  black: '#000',
  sushi: '#a4b439',
  red: '#ff0000', // replacing error color
  goldDrop: '#ee7f00', // replacing nn color #1
  flushOrange: '#ff7800',
  silver: '#ccc', // replacing lightGrey color
  boulder: '#797979', // replacing mediumGrey color
  steelBlue: '#5389C2', // replacing lightViolet color
  curiousBlue: '#199ed8',
  westar: '#e5ded9',
  mineShaft: '#333333',
  tundora: '#414141',
  pomegranate: '#e64415', // #3
  sandybrown: '#F3A348',
  tahitiGold: '#EE830A',
  danube: '#7caad6',
  dustyGray: '#999', // replacing gray color
  lochmara: '#007fbb',
  doveGray: '#666666',
  alto: '#d6d6d6',
  regentStBlue: '#A5C3E1',
  clementine: '#EF7102',
  cerulean: '#039be5',
  christine: '#ea650d', // #2
  cinderella: '#fee0dd',
  athensGray: '#F0F1F5',
  pastelGreen: '#82DF73',
  monaLisa: '#FF9494',
  blueBell: '#9C91C6',
  goldenRod: '#fcd66f',
  emerald: '#33cc66',
  trinidad: '#EA4D0A',
  pictonBlue: '#48C2F9',
};
