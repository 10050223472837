import React from 'react';
import { Typo } from '@agent-zone/ui-library/esm/Typo';
import { ErrorMessageWrapper } from './ErrorMessageWrapper';

export const PinIsUsed = (): JSX.Element => (
  <ErrorMessageWrapper
    linkToNNSiteText="Przejdź do nn.pl"
  >
    <Typo $type="body" $color="orangeMedium">
      Ten kod
      {' '}
      <span>PIN został</span>
      {' '}
      <span>już wykorzystany</span>
    </Typo>
    <Typo $type="body">
      Podany PIN został już wykorzystany do wypełnienia zgód i oświadczeń do wniosku o
      zawarcie umowy ubezpieczeniowej.
    </Typo>
    <Typo $type="body">
      Sprawdź poprawność kodu i spróbuj ponownie lub skontaktuj się z przedstawicielem,
      z którym został sporządzony wniosek.
    </Typo>
  </ErrorMessageWrapper>
);
