import React, { useState, useLayoutEffect, ReactNode } from 'react';
import styled from 'styled-components';
import ReactDOM from 'react-dom';
import { colors } from '../Colors/colors';

const DialogWrapper = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0,0,0,0.5);
  z-index: 2;
`;

const DialogBody = styled.div`
  background: ${colors.white};
  border-radius: 5px;
  padding: 5px;
  width: 600px;
  max-width: 90%;
  padding: 15px;
  position: relative;
`;

const DialogClose = styled.button`
  position: absolute;
  right: 10px;
  top: 10px;
  border: 0;
  background: transparent;
  cursor: pointer;
  color: gray;
`;

interface Props {
  children: ReactNode;
  onClose?: () => void;
}

export function Dialog({ children, onClose }: Props): JSX.Element {
  const [el, setElement] = useState<Element | undefined>();


  useLayoutEffect(() => {
    const newEl = document.createElement('div');
    document.body.appendChild(newEl);
    document.body.style.overflow = 'hidden';
    setElement(newEl);

    return (): void => {
      document.body.style.overflow = 'auto';
      newEl.remove();
    };
  }, []);

  if (!el) {
    return <></>;
  }

  return ReactDOM.createPortal(
    (
      <DialogWrapper>
        <DialogBody>
          {onClose && <DialogClose onClick={onClose}>✖</DialogClose>}
          {children}
        </DialogBody>
      </DialogWrapper>
    ),
    el,
  );
}
