import React, { useEffect, ReactNode } from 'react';
import Axios, { AxiosError } from 'axios';

export function WAFInterceptor({ children }: {children: ReactNode}): JSX.Element {
  useEffect(() => {
    // bind

    const bindId = Axios.interceptors.response.use((response) => {
      if (
        response.headers // exception for photo page
        && response.headers['content-type'] !== 'application/json'
      ) {
        const [correlationId] = (response.data as string).match(/\d+/) || [];

        const err: AxiosError = {
          response: {
            ...response,
            status: 400,
            headers: {
              'content-type': 'application/json',
            },
            data: {
              errors: [{
                messageKey: 'REQUEST-REJECTED',
              }],
              correlationId,
            },
          },
          config: {},
          isAxiosError: true,
          name: '',
          message: '',
          toJSON: () => ({}),
        };

        return Promise.reject(err);
      }

      return response;
    });

    // unbind
    return (): void => {
      Axios.interceptors.response.eject(bindId);
    };
  });

  return <>{children}</>;
}
