import { useEffect, useState } from 'react';

interface UseDimensionsHook {
  viewportHeight: number;
  viewportWidth: number;
}

export const useDimensions = (): UseDimensionsHook => {
  const [viewportHeight, setViewportHeight] = useState<number>(window.innerHeight);
  const [viewportWidth, setViewportWidth] = useState<number>(window.innerWidth);

  const measureViewport = (): void => {
    setViewportHeight(window.innerHeight);
    setViewportWidth(window.innerWidth);
  };

  useEffect((): () => void => {
    window.addEventListener('resize', measureViewport, false);
    return (): void => {
      window.removeEventListener('resize', measureViewport, false);
    };
  }, []);

  return {
    viewportHeight,
    viewportWidth,
  };
};
