import React from 'react';
import styled from 'styled-components';
import ReCAPTCHA from 'react-google-recaptcha';

const RecaptchaWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 40px auto 0;
`;
interface Props {
  captchaKey: string;
  onChange: (token: string | null) => void;
}

export const Recaptcha = ({ captchaKey, onChange }: Props): JSX.Element => (
  <RecaptchaWrapper>
    <ReCAPTCHA
      sitekey={captchaKey}
      onChange={onChange}
    />
  </RecaptchaWrapper>
);
