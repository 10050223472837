import React from 'react';
import { Typo } from '@agent-zone/ui-library/esm/Typo';
import { ErrorMessageWrapper } from './ErrorMessageWrapper';

export const LacksOfIndexes = (): JSX.Element => (
  <ErrorMessageWrapper
    linkToNNSiteText="Przejdź do nn.pl"
  >
    <Typo $type="H1" $color="orangeMedium">
      Upss... coś poszło nie tak
    </Typo>
    <Typo $type="body">
      Niestety nie udało zalogować.
      Upewnij się czy nie jesteś zalogowany do oswiadczenia.nn.pl
      na innym urządzeniu lub przeglądarce.
      Prosimy o logowanie do aplikacji tylko w jednym oknie lub urządzeniu jednocześnie.
    </Typo>
    <Typo $type="body">
      Po zamknięciu aplikacji w innych urządzeniach spróbuj zalogować się ponownie.
      Jeśli problem wystąpi ponownie skontaktuj się z Przedstawicielem,
      z którym został sporządzony wniosek.
    </Typo>
  </ErrorMessageWrapper>
);
