export function mapToRequestConsent(consent: K2Paperless.ConsentWithAccept): K2.Request.Consent {
  return { id: consent.id, accepted: consent.accepted };
}

export function mapToRequestClient(client: K2Paperless.ClientWithAccept): K2.Request.Client {
  return {
    clientId: client.clientId,
    consents: client.consents.map(mapToRequestConsent),
  };
}
