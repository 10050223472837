import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { Switch, Route, Redirect, useHistory, useLocation } from 'react-router-dom';
import Axios from 'axios';
import queryString from 'query-string';
import { GlobalFonts } from '@agent-zone/ui-library/esm/globalFonts';
import { Navbar } from '@agent-zone/ui-library/esm/Navbar';
import { Grid } from '@agent-zone/ui-library/esm/Grid';
import { Typo } from '@agent-zone/ui-library/esm/Typo';
import styled, { createGlobalStyle, css, CSSProp } from 'styled-components';
import { ErrorsContextProvider } from './components/Errors/ErrorsContextProvider';
import { CookiesPolicy } from './components/CookiesPolicy/CookiesPolicy';
import { InactivityNotification } from './components/InactivityNotification/InactivityNotification';
import { WAFInterceptor } from './components/WAF/WAFInterceptor';
import { DemoInterceptor } from './components/Demo/DemoInterceptor';
import { HttpErrorPage } from './components/Errors/HttpErrorPage';
import { ResponseErrorPage } from './components/Errors/ResponseErrorPage';
import { WAFErrorPage } from './components/WAF/WAFErrorPage';
import { AuthMethod } from './components/AuthMethod/AuthMethod';
import { RouteChangeListener } from './components/RouteChangeListener/RouteChangeListener';
import { Proposals } from './components/Proposals/Proposals';
import { FullScreenLoader } from './ui/Loader/FullScreenLoader';
import { GradientBottom } from './ui/GradientBottom/GradientBottom';
import { useDimensions } from './hooks/useDimensions';
import { LANDSCAPE_TABLET, MOBILE } from './breakpoints';
import './interceptors';
import SymbolNN from './img/Symbol_NN.png';

const locationSearch = queryString.parse(window.location.search);

const GlobalStyles = createGlobalStyle`
    @media screen and (max-width: ${MOBILE}px) {
      body {
        padding: 0 20px;
      }
    }
`;

const NavbarWrapper = styled.div<{ isFloating: boolean }>`
  ${({ isFloating }): CSSProp => (isFloating
    ? css`margin-top: 144px;`
    : '')}
`;

const StyledNavbar = styled(Navbar)<{ isFloating: boolean }>`
  ${({ isFloating }): CSSProp => (isFloating
    ? css`
      background: transparent;
      border: none;
      position: fixed;
      top: 0;
      z-index: 1;
    `
    : '')}
  @media screen and (max-width: ${MOBILE}px) {
    padding: 0;
    display: none;
  }
`;

const SmallLogoWrapper = styled.div`
  display: none;
  @media screen and (max-width: ${MOBILE}px) {
    padding: 0;
    margin-top: 14px;
    margin-bottom: 14px;
    display: block;
  }
`;

export const App = (): JSX.Element => {
  const history = useHistory();
  const [token, setToken] = useState<string | undefined>();
  const [tempToken, setTempToken] = useState<string | null>(
    locationSearch && (locationSearch.token as string | null),
  );
  const { viewportWidth, viewportHeight } = useDimensions();
  const { pathname } = useLocation();
  const isFloating = (viewportWidth >= LANDSCAPE_TABLET && viewportHeight > 580)
    && (pathname === '/auth' || pathname === '/auth/social-security-numbers');
  const [myIdLoggingEnabled, setMyIdLoggingEnabled] = useState<
  boolean | undefined
  >(undefined);
  const [myIdUrl, setMyIdUrl] = useState<string | undefined>(undefined);
  const [authenticationProviders, setAuthenticationProviders] = useState<
  string[]
  >();

  const resetState = useCallback(
    (goBack?: boolean) => {
      setToken(undefined);
      if (goBack) history.push('/');
    },
    [history],
  );

  useEffect(() => {
    Axios.get<K2Response.AuthenticationInit>(
      '/public/authentication/init',
    ).then(({ data }) => {
      setMyIdLoggingEnabled(data.myIdLoggingEnabled);
      setMyIdUrl(data.myIdUrl);
      setAuthenticationProviders(data.authenticationProviders);
    });
  }, []);

  useEffect(() => {
    if (tempToken && myIdLoggingEnabled) {
      Axios.post<K2Response.Esac>(
        `/public/authentication/esac?token=${tempToken}`,
      )
        .then(({ data }) => setToken(data))
        .catch((err) => history.push({
          pathname: '/error/response/unknown-error',
          state: { correlationId: err.response.data.correlationId },
        }))
        .finally(() => {
          setTempToken(null);
          window.history.pushState('', '', '/');
        });
    }
  }, [history, myIdLoggingEnabled, setToken, tempToken]);

  if (tempToken && myIdLoggingEnabled !== false) {
    return <FullScreenLoader />;
  }
  Axios.defaults.headers.common.Authorization = token;

  return (
    <>
      <GlobalStyles />
      <GlobalFonts />
      <ErrorsContextProvider>
        <DemoInterceptor>
          {(isDemo, onCancelDemo, setDemoType): ReactNode => (
            <WAFInterceptor>
              <RouteChangeListener />
              <InactivityNotification resetGlobalStore={resetState} />
              <NavbarWrapper isFloating={isFloating}>
                <StyledNavbar isFloating={isFloating}>
                  {isDemo && (
                    <>
                      <Grid />
                      <Typo $type="bodyBold" noMargin>
                        DEMO
                      </Typo>
                    </>
                  )}
                </StyledNavbar>
                <SmallLogoWrapper>
                  <img src={SymbolNN} alt="Logo Nationale-Nederlanden" />
                </SmallLogoWrapper>
              </NavbarWrapper>
              <Switch>
                {!token && (
                  <Route path="/auth">
                    <AuthMethod
                      isFloating={isFloating}
                      onFinish={setToken}
                      onEnter={onCancelDemo}
                      myIdLoggingEnabled={myIdLoggingEnabled}
                      myIdUrl={myIdUrl}
                      authenticationProviders={authenticationProviders}
                    />
                  </Route>
                )}
                {token && (
                  <Route path="/proposals">
                    <Proposals isDemo={isDemo} setDemoType={setDemoType} />
                  </Route>
                )}
                <Route path="/error/response">
                  <ResponseErrorPage />
                </Route>
                <Route path="/error/http/:status">
                  <HttpErrorPage />
                </Route>
                <Route path="/error/waf">
                  <WAFErrorPage />
                </Route>
                <Redirect to={token ? '/proposals' : '/auth'} />
              </Switch>
              <CookiesPolicy />
              <GradientBottom />
            </WAFInterceptor>
          )}
        </DemoInterceptor>
      </ErrorsContextProvider>
    </>
  );
};
