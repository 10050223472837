import { Typo } from '@agent-zone/ui-library/esm/Typo';
import React from 'react';
import { Fader } from '../../../../../../../../ui/Fader/Fader';
import { ContentWrapper } from '../../../../../../../../ui/LayoutElements/LayoutElements.styled';
import { Recaptcha } from './Recaptcha';

interface Props {
  setCaptchaToken: (token: string | null) => void;
  captchaKey: string;
  setRenderCaptcha: (_: boolean) => void;
}

export const RecaptchaForm = ({
  setCaptchaToken,
  captchaKey,
  setRenderCaptcha,
}: Props): JSX.Element => {
  const onCaptchaSolve = (token: string | null): void => {
    setCaptchaToken(token);
    setRenderCaptcha(false);
  };

  return (
    <Fader key="1">
      <ContentWrapper>
        <Typo $type="H2" $color="orangeMedium">
          Upewniamy się...
        </Typo>
        <Typo $type="body">
          Ponieważ jest to Twoja kolejna próba, aby kontynuować
          prosimy o potwierdzenie klikając w poniższe pole &quot;Nie jestem robotem&quot;.
        </Typo>
        <Recaptcha
          captchaKey={captchaKey}
          onChange={onCaptchaSolve}
        />
      </ContentWrapper>
    </Fader>
  );
};
