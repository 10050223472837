import React, { useCallback, useState, useContext, Dispatch, SetStateAction, useRef } from 'react';
import styled from 'styled-components';
import { Typo } from '@agent-zone/ui-library/esm/Typo';
import { MarginsWrapper, Spacer } from '@agent-zone/ui-library/esm/Margins';
import { Select as AgentZoneSelect } from '@agent-zone/ui-library/esm/Select';
import { Button } from '@agent-zone/ui-library/esm/Button';
import { Elevation } from '@agent-zone/ui-library/esm/Elevation';
import { Grid } from '@agent-zone/ui-library/esm/Grid';
import { Pictogram } from '@agent-zone/ui-library/esm/Pictogram';
import { LineSeparator } from '../../../../../../ui/LayoutElements/LayoutElements.styled';
import { Fader } from '../../../../../../ui/Fader/Fader';
import { ErrorContext } from '../../../../../Errors/ErrorsContextProvider';
import { ButtonsWrapper } from '../../../../../../ui/Button/ButtonsWrapper';
import { useSubmit } from './hooks/useSubmit';
import { Photo } from './components/Photo';
import { FileWithCanvas } from './hooks/usePhoto';
import { useScreenScroll } from '../../../../../../hooks/useScreenScroll';
import { ListItem, Unorderedlist } from '../../../../../../ui/Unorderedlist/Unorderedlist';

const StyledContentWrapper = styled.article`
  display: flex;
  flex-direction: column;
  text-align: left;
  max-width: calc(100% - 30px);
  width: 760px;
  margin: 0 auto;
  min-height: 900px;
`;

interface Props {
  onGoBack: () => void;
  onFinish: () => void;
  mainClient: K2Paperless.MainClientWithAccept;
  clients?: K2Paperless.ClientWithAccept[];
  skipUpload: boolean;
  uploads: FileWithCanvas[];
  additionalUploads: FileWithCanvas[];
  setAdditionalUploads: Dispatch<SetStateAction<FileWithCanvas[]>>;
  skipAdditionalUpload: boolean;
  setSkipAdditionalUpload: (_: boolean) => void;
  additionalDocuments: K2Paperless.AdditionalDocument[];
  transferFromOwnedBankAccount: boolean | null;
}

function getRequiredUploadsCount(
  additionalDocuments: K2Paperless.AdditionalDocument[],
  additionalDocumentType?: string,
): number {
  if (!additionalDocumentType) {
    return Infinity;
  }

  const foundDocumentType = additionalDocuments.find((ad) => ad.id === additionalDocumentType);
  if (!foundDocumentType) {
    return Infinity;
  }

  return foundDocumentType.pages;
}

export const AdditionalPhoto = ({
  onGoBack, onFinish, mainClient, clients,
  uploads, skipUpload,
  additionalUploads, setAdditionalUploads, skipAdditionalUpload,
  additionalDocuments,
  transferFromOwnedBankAccount,
}: Props): JSX.Element => {
  useScreenScroll();
  const [submitting, setSubmitting] = useState(false);
  const [
    additionalDocumentType, setAdditionalDocumentType,
  ] = useState<string | undefined>(undefined);
  const { cleanError, setValidationError } = useContext(ErrorContext);
  const scrollToRef = useRef<HTMLDivElement>(null);

  const onSubmitAndFinish = useSubmit(
    setSubmitting,
    submitting,
    onFinish,
  );

  const onGoBackHandler = useCallback((): void => {
    cleanError();
    onGoBack();
  }, [onGoBack, cleanError]);

  const onChangeAdditionalDocumentType = useCallback((newDocumentType: string): void => {
    setAdditionalDocumentType(newDocumentType);
    setAdditionalUploads([{}]);
    if (!additionalDocumentType && scrollToRef.current) {
      scrollToRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [additionalDocumentType, setAdditionalUploads]);

  const uploadsCountCorrect = additionalUploads.filter((u) => !!u.originalFile).length
    === getRequiredUploadsCount(additionalDocuments, additionalDocumentType);

  const onPressSubmit = useCallback(() => {
    if (uploadsCountCorrect) {
      onSubmitAndFinish(
        {
          clients,
          mainClient,
          // base photos
          uploads,
          skipUpload,
          // additional photos
          additionalDocumentType,
          additionalUploads,
          skipAdditionalUpload,
          transferFromOwnedBankAccount,
        },
      );
    } else {
      setValidationError({ messageKey: 'INVALID-ADDITIONAL-DOCUMENT' });
    }
  }, [
    additionalDocumentType, additionalUploads, clients, mainClient, onSubmitAndFinish,
    setValidationError, skipAdditionalUpload, skipUpload, transferFromOwnedBankAccount,
    uploads, uploadsCountCorrect,
  ]);

  const selectedOption = additionalDocuments
    .find((option) => option.id === additionalDocumentType);

  useScreenScroll(true, !!additionalDocumentType && uploadsCountCorrect);
  return (
    <>
      <Fader>
        <StyledContentWrapper>
          <Spacer size="LXL" />
          <Typo $type="H1" $color="orangeMedium">
            Zdjęcie lub skan dodatkowego dokumentu potwierdzającego Twoją tożsamość
          </Typo>
          <Typo $type="body">
            Wybierz z poniższej listy odpowiedni rodzaj dokumentu, który chcesz dołączyć.
          </Typo>
          <div ref={scrollToRef} />
          <AgentZoneSelect
            value={additionalDocumentType}
            label="Wybierz rodzaj dokumentu tożsamości"
            options={additionalDocuments.map((documentOption) => ({
              option: documentOption.label,
              value: documentOption.id,
            }))}
            onChange={onChangeAdditionalDocumentType}
          />
          {
            selectedOption && (
              <>
                <Typo $type="body">
                  Załącz zdjęcie strony dokumentu, na której znajduje się
                  Twoje zdjęcie oraz dane osobowe. Zwróć uwagę, czy dokument jest ważny.
                </Typo>
                <Elevation paddingsHorizontal="M" paddingsVertical="M" color="snowWhite">
                  <Grid>
                    <Grid flex={0} paddingRight="M">
                      <Pictogram icon="infoRound" />
                    </Grid>
                    <Grid align block>
                      <MarginsWrapper marginBottom="S">
                        <Typo noMargin $type="body">Zwróć uwagę:</Typo>
                      </MarginsWrapper>
                      <Unorderedlist>
                        <ListItem>
                          <MarginsWrapper marginBottom="S">
                            <Typo noMargin inline $type="bodyBold">informacje</Typo>
                            <Typo noMargin inline $type="body"> na dokumencie muszą być </Typo>
                            <Typo noMargin inline $type="bodyBold">w pełni widoczne i czytelne</Typo>
                          </MarginsWrapper>
                        </ListItem>
                        <ListItem>
                          <MarginsWrapper marginBottom="S">
                            <Typo noMargin inline $type="body">na zdjęciu muszą być widoczne</Typo>
                            <Typo noMargin inline $type="bodyBold"> 4 rogi dokumentu</Typo>
                          </MarginsWrapper>
                        </ListItem>
                        <ListItem>
                          <MarginsWrapper marginBottom="S">
                            <Typo noMargin inline $type="body">właściwy format wgrywanego pliku </Typo>
                            <Typo noMargin inline $type="bodyBold">JPG, PNG, TIFF lub PDF, mniejszy niż 6 MB</Typo>
                          </MarginsWrapper>
                        </ListItem>
                      </Unorderedlist>
                    </Grid>
                  </Grid>
                </Elevation>
                <Spacer size="L" />
                <Typo $type="body">{selectedOption.description}</Typo>
                <Photo
                  documentType="OTHER"
                  skipUpload={skipAdditionalUpload}
                  uploads={additionalUploads}
                  setUploads={setAdditionalUploads}
                  submitting={submitting}
                  requiredPhotoCount={selectedOption.pages}
                />
              </>
            )
          }
          <LineSeparator />
          {
            selectedOption && (
              <Typo $type="body">
                Kliknij &quot;Wyślij&quot;, aby przesłać do nas cały formularz.
              </Typo>
            )
          }
          <ButtonsWrapper>
            <Button
              $type="secondary"
              onClick={onGoBackHandler}
            >
              Wróć
            </Button>
            {selectedOption && (
              <Button
                $type="primary"
                onClick={onPressSubmit}
                loading={submitting}
              >
                Wyślij
              </Button>
            )}
          </ButtonsWrapper>
        </StyledContentWrapper>
      </Fader>
    </>
  );
};
